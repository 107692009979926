<template>
  <div>
    <div class="nav-rights">

      <div class="box222">
            <HeaderNav></HeaderNav>
        </div>
  

      <div class="body">

          <div style="display: flex; flex-direction:column">
          <span style="margin-left:20px;color: #fff;font-size: 20px;">{{$t('uc.finance.xx83')}}</span>
          <span style="margin-left:20px;color: #fff;font-size: 20px;">{{$t('uc.finance.xx82')}}</span>
          </div>

        </div>



      <div style="margin-top:20px; " >
          <CellGroup  @on-click="gotoCS">
                <Cell title="telegram" :label="$t('uc.finance.xx92')"  class="boxCell"  name="th">
                    <img src="../assets/logo/tg.png" width="25" alt="" slot="icon" >
                </Cell>
                <Cell title="line" :label="$t('uc.finance.xx92')" class="boxCell"  name="chn">
                    <img src="../assets/logo/line2.png" width="30" alt="" slot="icon" >
                </Cell>
                <Cell title="whatsapp" :label="$t('uc.finance.xx92')" class="boxCell"  name="vie">
                    <img src="../assets/logo/line1.png" width="25" alt="" slot="icon" >
                </Cell>
                <Cell :title="$t('uc.finance.xx145')" :label="$t('uc.finance.xx92')" class="boxCell"  name="eng">
                    <img src="../assets/logo/cs.png" width="28" alt="" slot="icon" >
                    <a href="https://msgbt.btchat1o.cc?appId=Em7rlV0t&lang=zh_CN&userId=123123&redirectUrl=false"></a>
                </Cell>
               

            </CellGroup>

        </div>
        <Main-tab-bar></Main-tab-bar>
   </div>
 </div>
</template>

<script>
import MainTabBar from '../components/tabbar/MainTabBar'
import HeaderNav from "@/components/HeaderNav.vue";

export default {
   name:'TabCS',
    components: {
MainTabBar,
HeaderNav
  },
    data() {
    return {
      customerUrl:'',

    }
    },


    methods: {
      getLanguage() {
            if (this.$i18n.locale === 'zh-CN') return 'zh_CN'
            if (this.$i18n.locale === 'en-US') return 'en_US'
            if (this.$i18n.locale === 'vi-VN') return 'vi_VN'
        if (this.$i18n.locale === 'th-TH') return 'th_TH'  //泰文
        if (this.$i18n.locale === 'ja-JP') return  'ja_JP'  //日文
        if (this.$i18n.locale === 'ko-KR') return 'ko_KR'  //韩文
        if (this.$i18n.locale === 'in-IN') return 'id_ID'
        },
      gotoCS(data){
        console.log(data);
        let userNo = this.$store.getters.member?this.$store.getters.member.userNo:'nobody'

        if (data == 'eng') {
          let url = encodeURIComponent(window.location.href);
          // window.location.href = `https://msgbt.btchat1o.cc?appId=Em7rlV0t&lang=${this.getLanguage()}&userId=${userNo}&redirectUrl=${url}`
    //       const elem = document.getElementById("lancher");
    // if (elem) {
    //   elem.style.cssText = 'height: 100%';

    // }
    // let xx = 'https://chat.bytrack.com/core/v1.19.0/#/?appId=Em7rlV0t&amp;deviceId=49eadc38a2af469ca87567be48ffa917&amp;mode=inline&amp;groupId=&amp;isMobile=true&amp;lang='+this.getLanguage()+'&amp;svrlang='+this.getLanguage()+'&amp;userId='+userNo+'&amp;nowDate='+Date.now()+'&amp;redirectUrl=false'
        // https://chat.bytrack.com/core/v1.19.0/#/?appId=Em7rlV0t&amp;deviceId=49eadc38a2af469ca87567be48ffa917&amp;mode=inline&amp;groupId=&amp;isMobile=true&amp;lang=zh_CN&amp;svrlang=zh_CN&amp;userId=5708987&amp;nowDate=1728444172855&amp;redirectUrl=false
let xx = 'https://msgbt.btchat1o.cc?appId=Em7rlV0t&lang='+this.getLanguage()+'&userId='+userNo
//           // this.$router.push("/customservice");
           this.$router.push({name:"CustomService", params:{urlxx: xx, backurl:url}}); //添加红包判断
        }else if (data == 'chn') {
          // https://line.me/ti/p/-c4S8D_X7t
         window.open('https://line.me/ti/pffAZGmgRy5','_blank').focus();
        }else if (data == 'vie') {
          // https://wa.me/96825032
          window.open('https://wa.me/96825032', '_blank').focus();
        }else if (data == 'th') {
          window.open('https://t.me/Mago_chat', '_blank').focus();
          // https://t.me/JBYL7777
        }else if (data == 'in') {
          // this.$router.push({name:"CustomService", params:{urlxx: 'https://kefu.jb.bet/chatIndex?kefu_id=kefu-in&ent_id=1'}}); //添加红包判断

        }
      },

      gotoCam(data){
        console.log(data);

      },

      gotoChn(data){
        console.log(data);
        // https://kefu.jb.bet/chatIndex?kefu_id=kefu-cn&ent_id=1&visitor_id=用户ID

      },
      gotoVie(data){
        console.log(data);

        // https://kefu.jb.bet/chatIndex?kefu_id=kefu-vn&ent_id=1&visitor_id=用户ID
      },
      gotoEng(){
console.log();
      },
      gotoTh(){
console.log();
      },
      gotoIn(){
console.log();
      },
    }

}
</script>

<style lang="scss" scoped>

.nav-rights {
  background-color: #0E212E;
  height: 55rem;
  position: relative;
  overflow: hidden;
  .box222 {
        height: 60px;
        width: 100%;
    }
}

.body{
  margin-top: 25px;
  width: 100%;
  color: #fff;
  margin-left: 20px;
  display: flex;
  justify-content: flex-start;
  // align-items: center;
}

.boxCell{
  background-color: #213743;
  border-radius: 10px;
  margin: 10px;
  height: 50px;
  color: #fff;
    font-weight: 600;
}



</style>
