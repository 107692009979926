<template>
    <div class="banner-box">
        <slider animation="fade" style="height: 100%;" :control-btn=false>
            <slider-item v-for="(item, index) in picList" :key="index">
<!--                <img :src="i.coverImg" style="width:100%;height:100%">-->
                <div class="slide-box" :style="{backgroundImage : 'url(' +  item.bg + ')' }">
                    <div class="left">
                        <div class="tit1">{{item.t1}}</div>
                        <div class="tit2">{{item.t2}}</div>
                        <div class="tit3">{{item.t3}}</div>
                        <div class="tit4">{{item.t4}}</div>
                        <div v-if="item.t6" class="tit6">{{item.t6}}</div>
                        <div class="tit5">{{item.t5}}</div>
                    </div>
                    <div class="right">
<!--                        <img :src="item.path" alt="">-->
                    </div>
                </div>
            </slider-item>
        </slider>
    </div>
</template>
<script>
export default {
    name: "Banner",
    data() {
        return {}
    },
    props: {
        picList: {
            type: [Array, Array],
            default: () => {
            }
        }
    },
    watch: {
        // picList(newVal, old) {
        // }
    }
}
</script>


<style scoped lang="scss">
.banner-box{
    width: 100%;
    height: 100%;
    background-color: #1A2B37;
}
.slide-box{
    //background-color: #213843;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    background-size: 100% 100%;


    .left{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        color: #fff;
        width: 48%;
        padding-left: 2%;
        .tit1{
            font-size: 11px;
            width: 80px;
            background-color: #fff;
            border-radius: 4px;
            color: #1A2B37;
        }
        .tit2{
            line-height: 16px;
            font-size: 16px;
            font-weight: 600;
            text-align: left;
        }
        .tit3{
            line-height: 28px;
            text-align: left;
        }
        .tit4{
            line-height: 14px;
            font-weight: 600;
            text-align: left;
        }
        .tit5{
            border: 1px solid #fff;
            border-radius: 5px;
            padding: 5px;
            font-weight: 600;
        }
        .tit6{
            font-weight: 600;
            line-height: 16px;
            text-align: left;
        }
    }

    .right{
        width: 50%;
        img{
            height: 100%;

        }
    }
}
</style>
