<template>
    <div class="nav-rights">
        <div class="tuichu"
             @click="withdraw"
             @touchstart="startDrag"
             :style="{ top: positionY + 'px', left: positionX + 'px' }" >
            <img src="../assets/common/tuichu.png" alt="">
        </div>
        <div class="loading" v-if="loading">
            <Spin :color="'red'" :size="'large'"/>
        </div>

        <div v-if="fcUrl && !loading">
            <iframe name="iframe" :height="iframeH" width="100%"
                    allowfullscreen="allowfullscreen"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowtransparency="true"
                    auto="autoplay"
                    muted="muted"
                    frameborder="0" :src="fcUrl"/>
        </div>
        <div @touchmove='touchmove' v-else>
            <iframe :src="outurl" frameborder="0"
                    id="gameframe"></iframe>
        </div>
    </div>
</template>

<script>
// import { browser } from "../config/index.js";

export default {
    data() {
        return {
            isDragging: false, // 布尔参数改名
            positionX: 10,
            positionY: 10,
            offsetX: 0,
            offsetY: 0,
            isLandscape: false,  //是否横屏
            gameName: '',
            loadingTimer: null,
            loading: true,
            fcUrl: null,
            iframeH: 0,
            outurl: '',
            windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
            windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
            message: '',
            tryout: 0,
            eloading: false,
            modal2: false,
            isShow: true,
            isAfter: false,
            cmdParam: {}
        }
    },

    computed: {
        isLogin: function () {
            return this.$store.getters.isLogin;
        },
    },

    watch: {
        isLandscape(newVal, old) {
            console.log(newVal)
            let h = window.innerHeight
            this.iframeH = h    //内嵌页面的样式调整
        }
    },


    created() {
        this.gameName = this.$route.query.name
        this.loadingTimer = setTimeout(() => {
            this.loading = false
            clearTimeout(this.loadingTimer)
        }, 2000)

        let h = window.innerHeight
        this.iframeH = h    //内嵌页面的样式调整

        this.fcUrl = this.$route.query.fcUrl
        console.log('sdfsdfd  s    ' + this.fcUrl);

        // let id = this.$route.query.gid
        if (!this.isLogin) {
            // this.outurl = 'https://www.waliyouxi.com/trial/trial.html?game=' + id
            this.$router.push('/Home')

        } else {
            // if(this.$route.query.gpage == undefined) console.log("undefined") ;
            if (this.$route.query.gid == 'cmd') {
                this.cmdParam = this.$route.query.gpage

                let user = this.$store.getters.member.userNo + '-' + this.cmdParam.CurrencyCode
                let cyz = this.cmdParam.CurrencyCode
                if (cyz == 'VND') {
                    cyz = 'VD'
                }
                this.outurl =
                    'https://jbsmart.1win888.net/auth.aspx?lang=' + this.cmdParam.lang + '&user=' + user + '&token=' + localStorage.getItem('TOKEN') + '&currency=' + cyz + '&templatename=green&view=v1'


            } else
                this.outurl = this.$route.query.gpage;
            console.log("ssss " + this.outurl)
        }


        this.tryout = 0

    },
    mounted() {
        // const iggame = document.getElementById('gameframe');

        // iggame.height = document.documentElement.clientHeight;

        //   this.$nextTick(function () {
        //   // Code that will run only after the
        //   // entire view has been rendered
        //   if (browser().ios) {
        //     document.getElementById('outbody').style.overflow = 'hidden'
        //   }

        //   if (this.$route.query.gid == '-1') {
        //     document.getElementById('outbody').style.overflow = ''
        //   }
        // })

        window.addEventListener('resize', this.renderResize, false);
        window.addEventListener("resize", this.checkOrientation);


    },
    methods: {
        startDrag(event) {
            this.isDragging = true;
            this.offsetX = event.touches[0].clientX - this.positionX;
            this.offsetY = event.touches[0].clientY - this.positionY;

            // 添加 touch 事件监听
            window.addEventListener("touchmove", this.onDrag,{passive:false});
            window.addEventListener("touchend", this.endDrag);
        },
        onDrag(event) { // 改名为 onDrag 方法
            if (this.isDragging) {
                event.preventDefault(); // 阻止页面滚动
                this.positionX = event.touches[0].clientX - this.offsetX;
                this.positionY = event.touches[0].clientY - this.offsetY;

                // 边界检测，确保按钮在屏幕范围内
                if (this.positionX < 0) this.positionX = 0;
                if (this.positionY < 0) this.positionY = 0;
                if (this.positionX > this.windowWidth - 40) this.positionX = this.windowWidth - 40;
                if (this.positionY > this.windowHeight - 40) this.positionY = this.windowHeight - 40;
            }
        },
        endDrag() {
            this.isDragging = false;

            // 移除 touch 事件监听
            window.removeEventListener("touchmove", this.onDrag);
            window.removeEventListener("touchend", this.endDrag);
        },
        withdraw(){
            const confirmed = window.confirm("您确定要离开此界面吗?");
            if (confirmed) {
                // 执行退出逻辑
                this.$router.push({ path: '/' });
            }
            // this.$router.push({path:'/'})
        },
        checkOrientation() {
            // 判断屏幕方向
            this.isLandscape = window.innerWidth > window.innerHeight;
        },
        touchmove(e) {
            e.preventDefault()
        },

        hideTop() {
            this.isShow = false
        },

        onAfterLeave() {
            this.isAfter = true
        },
        showTop() {
            this.isShow = true
            this.isAfter = false
        },
        cancel() {
            this.modal2 = false
        },
        submitFist() {
            this.$router.go(-1)
            // this.modal2 = true
        },
        submitSecond() {
            this.goHome()
        },


        renderResize() {
            const iggame = document.getElementById('gameframe');
            iggame.height = document.documentElement.clientHeight;

        },
        getMoneyback() {

            let param = {};
            param["gameCode"] = this.$route.query.gid;
            let self = this;
            this.eloading = true
            this.$http
                .get(this.host + this.api.uc.mdboMakeTransfer, {'params': param})
                .then(response => {
                    let resp = response.body;
                    self.eloading = false
                    self.modal2 = false
                    if (resp.code == '0000') {
                        // this.getMember();
                        this.$router.replace({path: '/', query: {gid: param.gameCode}})
                        self.tryout = 0
                    } else if (resp.code == '5000') {

                        // self.$Notice.warning({
                        //         title: this.$t("common.tip"),
                        //         desc: resp.mesg
                        //       });

                        this.$router.replace({path: '/', query: {gid: param.gameCode}})
                    } else {
                        self.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: resp.mesg
                        });
                    }
                });
        },
        getMember() {
            //获取个人安全信息
            let self = this;

            this.$http
                .post(self.host + this.api.uc.personalWallet)
                .then(response => {
                    let resp = response.body;
                    if (resp.code == "0000") {

                        resp.data.forEach(element => {
                            if (element.ccy == 'USDT') {
                                self.moneybab = element

                            }
                        });

                    } else {
                        self.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: resp.mesg
                        });
                    }
                });
        },

        goHome() {
            if (this.$route.query.gid == '-1') {
                this.$router.replace({path: '/me'})
            } else

                // this.getMoneyback();
                this.$router.replace({path: '/'})
        }
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.checkOrientation);
        if (this.loadingTimer) clearTimeout(this.loadingTimer)
    }
}


</script>

<style lang="scss" scoped>
.nav-rights {
    background: transparent;
    height: $full-page;
    position: relative;
    //overflow: hidden;

    .tuichu {
        //top: 30px;
        //left: 10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #5E9EFD;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        //box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
        //transition: box-shadow 0.3s ease, transform 0.3s ease;
        box-shadow: 0 6px 12px rgba(0, 0, 0, .8); /* 阴影集中于下方 */
        transition: box-shadow 0.3s ease;

        & > img {
            width: 50%;
            //height: 80%;
        }
    }


    .box111 {
        position: fixed;
        top: 0;
        right: 25%;
        left: 25%;
        display: flex;
        justify-content: center;
        align-items: top;
        z-index: 200;
        height: 40px;
        width: 50%;


    }

    .slide-fade-enter-active {
        transition: all 0.3s ease-out;
    }

    .slide-fade-leave-active {
        transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .slide-fade-enter-from {
        transform: translateY(50px);
    }

    .slide-fade-leave-to {
        transform: translateY(-50px);
        // opacity: 0;
    }

}

.loading {
    height: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}


</style>

