<template>
    <div class="login_form">
        <div class="box222">
            <HeaderNav></HeaderNav>
        </div>
        <div class="active">
            <div class="title">活动</div>
            <div class="item-list">
                <div class="item" v-for="(item,i) in list" :key="i">
                    <div class="card" >
                        <div class="posi-tit">{{item.title}}</div>
                        <div class="posi-con" :style="{color: ['#1AE2CD','#C7B336','#48B243',''][i]}">{{item.title_c}}</div>
                        <img class="item-img" :src="item.path" alt="">
                        <p class="txt">{{item.txt}}</p>
                    </div>
                </div>
            </div>
        </div>

        <Main-tab-bar></Main-tab-bar>
    </div>
</template>
<script>
import MainTabBar from '../components/tabbar/MainTabBar'
import {numberToCurrencyNo} from '@/config/index'
import moment from 'moment-timezone'
import FundPwd from "@/components/FundPwd.vue";
import HeaderNav from "@/components/HeaderNav.vue";

export default {
    components: {
        MainTabBar,
        HeaderNav
    },
    data() {
        return {
            list:[
                {
                    title:'反水时时反',
                    title_c:'反水高达1.2%',
                    txt:'反水时时反 上不封顶 无需流水即可提现，反水详情请进VIP俱乐部查看和兑换',
                    path:require("../assets/banner/12.png")
                },
                {
                    title:'存款笔笔送',
                    title_c:'最高送1888',
                    txt:'存款就送3% 三倍流水,存款成功后游戏开始之前请及时联系客服赠送3% ,过期作废',
                    path:require("../assets/banner/2.png")
                },
                {
                    title:'加入FB视讯',
                    title_c:'获得额外10%的输反',
                    txt:'凡是在FB视讯输了的玩家10倍流水，可以在第二天联系客服领取10%的输反奖励过期作废',
                    path:require("../assets/banner/9.png")
                },
                {
                    title:'体育公告',
                    title_c:'反水时时反',
                    txt:'体育只允许购买甲级以上联赛，世界锦标赛，以及奥运赛事，禁止投注甲级以下联赛及虚拟联赛其他类型体育赛事仅限于投注大型国际赛事任何使用自动设备，' +
                        '软件，程序，抄水，料球以及团队投注行为，均视为异常投注，我司有权冻结其账号，不予下分',
                    path:require("../assets/banner/11.png"),
                },
            ]
        }
    },
}

</script>

<style scoped lang="scss">
.active {
    display: flex;
    flex-direction: column;
    padding: 70px 6px;
    width: 100%;
    min-height: 100vh;
    background-color: #0b1a24;

    .title{
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        padding: 6px 4px;
    }
    .item-list{
        display: flex;
        flex-direction: column;
        .item{
            .card{
                position: relative;
                .posi-tit{
                    position: absolute;
                    left: 20px;
                    top: 60px;
                    font-size: 17px;
                    color: #fff;
                    font-weight: bold;
                }

                .posi-con{
                    position: absolute;
                    left: 20px;
                    top: 86px;
                    font-size: 13px;
                    color: #fff;
                }
                .item-img{
                    width: 100%;
                    border-radius: 4px;
                }

                .txt{
                    color: #fff;
                    font-size: 12px;
                    padding: 10px 2px;
                }
            }
        }
    }
}
</style>
