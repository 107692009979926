<template>
    <div class="login_form">
        <HeaderNav></HeaderNav>
        <!--        <div class="head_1">-->
        <!--            <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">-->
        <!--            <span style="font-size:20px;">-->
        <!--        {{ $t('uc.forget.wallet') }}-->
        <!--      </span>-->
        <!--            <span>{{  }}</span>-->
        <!--        </div>-->


        <Card class="mc_card" shadow style="padding-top: 10px">
            <!--            <Row>-->
            <!--                <div style="display: flex;justify-content:space-between; width: 100%; ">-->

            <!--                    &lt;!&ndash; <img src="../assets/logo/sss1.png" alt="" width="80"> &ndash;&gt;-->

            <!--                    <div @click="eyeONOff">-->
            <!--                        <img v-if="eyeON == true" src="../assets/logo/eyeOn.png" alt="" width="25"-->
            <!--                             style="margin-right: 10px;">-->
            <!--                        <img v-else src="../assets/logo/eyeOff.png" alt="" width="25" style="margin-right: 10px;">-->
            <!--                    </div>-->
            <!--                    &lt;!&ndash; <div @click="getMember">-->
            <!--                      <img src="../assets/logo/refresh.png" alt="" width="20">-->
            <!--                    </div> &ndash;&gt;-->

            <!--                </div>-->
            <!--            </Row>-->

            <!--            <Row>-->

            <!--                <div style="display: flex; flex-direction: column; margin-left: 40%;margin-top: -5%;">-->
            <!--                    <div style=" display: flex; align-items: center;">-->
            <!--                        &lt;!&ndash; <img :src= coinImg alt="" width="30" height="30"> &ndash;&gt;-->
            <!--                        <span style="color: #fff;font-size: larger;">-->
            <!--        {{ $t('common.amount') }}-->
            <!--      </span>-->
            <!--                        <span style="font-size: small;font-weight: 800;margin-left: 5px; color: darkgray;">-->
            <!--        ({{ coinType }})-->
            <!--      </span>-->
            <!--                    </div>-->

            <!--                    <div style="font-size: medium; font-weight: bold;color: #fff;">-->
            <!--                        {{ eyeON == true ? coinAmount : '*********' }}-->
            <!--                    </div>-->

            <!--                </div>-->

            <!--            </Row>-->

            <Row style="margin-top:20px">
                <!--                <Col span="6">-->
                <!--                    <Card style="width:100%;height: 50px; background-color: transparent; border-style: none;" dis-hover>-->
                <!--                        <div style="text-align:center;display: flex;flex-direction: column;align-items: center;"-->
                <!--                             @click="addAddress">-->
                <!--                            <img src="../assets/logo/wallet.png" width="30">-->
                <!--                            <span-->
                <!--                                style="font-size: small;margin-top: 5px; color: #fff; white-space: nowrap;">{{ $t("uc.finance.addUaddress")-->
                <!--                                }}</span>-->
                <!--                        </div>-->
                <!--                    </Card>-->
                <!--                </Col>-->

                <Col span="6">
                    <Card style="width:100%;height: 50px;background-color: transparent; border-style: none;" dis-hover>
                        <div style="text-align:center;display: flex;flex-direction: column;align-items: center;"
                             @click="gotocharge">
                            <img src="../assets/logo/deposit.png" width="30">
                            <span
                                style="font-size: small;margin-top: 5px;color: #fff;white-space: nowrap;">{{ $t("uc.finance.charge")
                                }}</span>
                        </div>
                    </Card>
                </Col>
                <Col span="6">
                    <Card style="width:100%;height: 50px;background-color: transparent; border-style: none;" dis-hover>
                        <div style="text-align:center;display: flex;flex-direction: column;align-items: center;"
                             @click="gotoWithdraw">
                            <img src="../assets/logo/withdraw.png" width="30">
                            <span
                                style="font-size: small;margin-top: 5px;color: #fff;white-space: nowrap;">{{ $t("uc.finance.pickup")
                                }}</span>
                        </div>
                    </Card>
                </Col>
                <!--                <Col span="6">-->
                <!--                    <Card style="width:100%;height: 50px;background-color: transparent; border-style: none;" dis-hover>-->
                <!--                        <div style="text-align:center;display: flex;flex-direction: column;align-items: center;"-->
                <!--                             @click="transaction">-->
                <!--                            <img src="../assets/logo/exchange.png" width="30">-->
                <!--                            <span-->
                <!--                                style="font-size: small;margin-top: 5px;color: #fff;white-space: nowrap;">{{ $t("common.exchange")-->
                <!--                                }}</span>-->
                <!--                        </div>-->
                <!--                    </Card>-->
                <!--                </Col>-->

            </Row>
        </Card>
        <!--        <div style="color: #fff;margin-left:20px ;margin-top:20px;">-->
        <!--            <span>{{ $t("uc.finance.xx164") }}</span>-->
        <!--        </div>-->
        <!--        <div-->
        <!--            style="padding: 5px 10px;background-color: #1B2C38; border-radius: 20px;width: 95%;margin-left: 10px; color: #fff;">-->

        <!--            <section class="celllall">-->
        <!--                <div style="display: flex;">-->
        <!--                    <img src="../assets/logo/usdt.png" alt="" width="30" height="30">-->
        <!--                    <div style="display: flex;flex-direction: column;margin-left: 10px;">-->
        <!--            <span style="font-size: xx-small;">-->
        <!--        {{ 'USDT' }}-->
        <!--      </span>-->
        <!--                        <span style="font-size: xx-small;">-->
        <!--        {{ amountUsdt.amount }}-->
        <!--      </span>-->

        <!--                    </div>-->
        <!--                </div>-->

        <!--                <i-switch true-color="#13ce66" false-color="#ff4949" v-model="currencyUSDT" :loading="loading"-->
        <!--                          :before-change="handleBeforeUSDTChange" @on-change="changeUSDT"/>-->

        <!--            </section>-->
        <!--            <Divider/>-->
        <!--            <section class="celllall">-->
        <!--                <div style="display: flex;">-->
        <!--                    <img src="../assets/logo/usa.png" alt="" width="30" height="30">-->
        <!--                    <div style="display: flex;flex-direction: column;margin-left: 10px;">-->
        <!--            <span style="font-size: xx-small;">-->
        <!--        {{ 'USD' }}-->
        <!--      </span>-->
        <!--                        <span style="font-size: xx-small;">-->
        <!--        {{ amountUsd.amount }}-->
        <!--      </span>-->

        <!--                    </div>-->
        <!--                </div>-->

        <!--                <i-switch true-color="#13ce66" false-color="#ff4949" v-model="currencyUSD" :loading="loading"-->
        <!--                          :before-change="handleBeforeUSDChange" @on-change="changeUSD"/>-->
        <!--            </section>-->
        <!--            <Divider/>-->
        <!--            <section class="celllall">-->
        <!--                <div style="display: flex;">-->
        <!--                    <img src="../assets/logo/vietnam.png" alt="" width="30" height="30">-->
        <!--                    <div style="display: flex;flex-direction: column;margin-left: 10px;">-->
        <!--            <span style="font-size: xx-small;">-->
        <!--        {{ 'VND' }}-->
        <!--      </span>-->
        <!--                        <span style="font-size: xx-small;">-->
        <!--        {{ amountVnd.amount }}-->
        <!--      </span>-->

        <!--                    </div>-->
        <!--                </div>-->
        <!--                <i-switch true-color="#13ce66" false-color="#ff4949" v-model="currencyVND" :loading="loading"-->
        <!--                          :before-change="handleBeforeVNDChange" @on-change="changeVND"/>-->


        <!--            </section>-->
        <!--            <Divider/>-->
        <!--            <section class="celllall">-->
        <!--                <div style="display: flex;">-->
        <!--                    <img src="../assets/logo/china.png" alt="" width="30" height="30">-->
        <!--                    <div style="display: flex;flex-direction: column;margin-left: 10px;">-->
        <!--            <span style="font-size: xx-small;">-->
        <!--        {{ 'CNY' }}-->
        <!--      </span>-->
        <!--                        <span style="font-size: xx-small;">-->
        <!--        {{ amountCny.amount }}-->
        <!--      </span>-->

        <!--                    </div>-->
        <!--                </div>-->
        <!--                <i-switch true-color="#13ce66" false-color="#ff4949" v-model="currencyCNY" :loading="loading"-->
        <!--                          :before-change="handleBeforeCNYChange" @on-change="changeCNY"/>-->


        <!--            </section>-->
        <!--            <Divider/>-->
        <!--            <section class="celllall">-->
        <!--                <div style="display: flex;">-->
        <!--                    <img src="../assets/logo/indiaN.png" alt="" width="30" height="30">-->
        <!--                    <div style="display: flex;flex-direction: column;margin-left: 10px;">-->
        <!--            <span style="font-size: xx-small;">-->
        <!--        {{ 'IDR' }}-->
        <!--      </span>-->
        <!--                        <span style="font-size: xx-small;">-->
        <!--        {{ amountIDR.amount }}-->
        <!--      </span>-->

        <!--                    </div>-->
        <!--                </div>-->
        <!--                <i-switch true-color="#13ce66" false-color="#ff4949" v-model="currencyIDR" :loading="loading"-->
        <!--                          :before-change="handleBeforeIDRChange" @on-change="changeIDR"/>-->


        <!--            </section>-->
        <!--            <Divider/>-->
        <!--            <section class="celllall">-->
        <!--                <div style="display: flex;">-->
        <!--                    <img src="../assets/logo/japan.png" alt="" width="30" height="30">-->
        <!--                    <div style="display: flex;flex-direction: column;margin-left: 10px;">-->
        <!--            <span style="font-size: xx-small;">-->
        <!--        {{ 'JPY' }}-->
        <!--      </span>-->
        <!--                        <span style="font-size: xx-small;">-->
        <!--        {{ amountJPY.amount }}-->
        <!--      </span>-->

        <!--                    </div>-->
        <!--                </div>-->
        <!--                <i-switch true-color="#13ce66" false-color="#ff4949" v-model="currencyJPY" :loading="loading"-->
        <!--                          :before-change="handleBeforeJPYChange" @on-change="changeJPY"/>-->


        <!--            </section>-->
        <!--            <Divider/>-->
        <!--            <section class="celllall">-->
        <!--                <div style="display: flex;">-->
        <!--                    <img src="../assets/logo/korea.png" alt="" width="30" height="30">-->
        <!--                    <div style="display: flex;flex-direction: column;margin-left: 10px;">-->
        <!--            <span style="font-size: xx-small;">-->
        <!--        {{ 'KRW' }}-->
        <!--      </span>-->
        <!--                        <span style="font-size: xx-small;">-->
        <!--        {{ amountKRW.amount }}-->
        <!--      </span>-->

        <!--                    </div>-->
        <!--                </div>-->
        <!--                <i-switch true-color="#13ce66" false-color="#ff4949" v-model="currencyKRW" :loading="loading"-->
        <!--                          :before-change="handleBeforeKRWChange" @on-change="changeKRW"/>-->


        <!--            </section>-->
        <!--            <Divider/>-->
        <!--            <section class="celllall">-->
        <!--                <div style="display: flex;">-->
        <!--                    <img src="../assets/logo/thai.png" alt="" width="30" height="30">-->
        <!--                    <div style="display: flex;flex-direction: column;margin-left: 10px;">-->
        <!--            <span style="font-size: xx-small;">-->
        <!--        {{ 'THB' }}-->
        <!--      </span>-->
        <!--                        <span style="font-size: xx-small;">-->
        <!--        {{ amountThb.amount }}-->
        <!--      </span>-->

        <!--                    </div>-->
        <!--                </div>-->
        <!--                <i-switch true-color="#13ce66" false-color="#ff4949" v-model="currencyThb" :loading="loading"-->
        <!--                          :before-change="handleBeforeThbChange" @on-change="changeThb"/>-->


        <!--            </section>-->


        <!--        </div>-->

        <!--        <Spin fix v-show="eloading" style="background:transparent;">-->
        <!--            <div class="loader">-->
        <!--                <img src="../assets/logo/loading.svg" alt="">-->
        <!--            </div>-->
        <!--        </Spin>-->

    </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav.vue";

export default {
    name: 'Wallet',
    components:{
        HeaderNav,
    },
    data() {
        return {
            currencyUSDT: false,
            currencyUSD: false,
            currencyVND: false,
            currencyCNY: false,
            currencyIDR: false,
            currencyJPY: false,
            currencyKRW: false,
            currencyThb: false,
            amountUsdt: '',
            amountUsd: '',
            amountVnd: '',
            amountCny: '',
            amountIDR: '',
            amountJPY: '',
            amountKRW: '',
            amountThb: '',
            coinType: '',
            coinAmount: 0.00,
            coinImg: '',
            eyeON: false,
            loading: false,
            eloading: false
        }
    },
    created() {

        // this.getMember();
        // let current = localStorage.curr('currency') || 'USDT';
        // let cru = this.$route.params.curr
        this.getMemberInfo()

        let eye = localStorage.getItem('eyeON') || 'false'
        if (eye == 'true') {
            this.eyeON = true
        } else
            this.eyeON = false
    },
    methods: {


        getMemberInfo() {
            //获取个人安全信息
            let self = this;
            this.eloading = true
            this.$http
                .post(self.host + this.api.uc.memberInfo)
                .then(response => {
                    var resp = response.body;
                    self.eloading = false
                    if (resp.code == "0000") {

                        //   self.moneyList.forEach(element => {
                        //   if (element.ccy == resp.data.remark) {
                        //     self.moneybab = element
                        //   }
                        // });
                        let current = self.coinType = resp.data.remark
                        if (current == 'USDT') {
                            self.currencyUSDT = true
                            self.coinImg = require('@/assets/logo/usdt.png');
                        } else if (current == 'USD') {
                            self.currencyUSD = true
                            self.coinImg = require('@/assets/logo/usa.png')
                        } else if (current == 'VND') {
                            self.currencyVND = true
                            self.coinImg = require('@/assets/logo/vietnam.png')
                        } else if (current == 'CNY') {
                            self.currencyCNY = true
                            self.coinImg = require('@/assets/logo/china.png')
                        } else if (current == 'IDR') {
                            self.currencyIDR = true
                            self.coinImg = require('@/assets/logo/indiaN.png')
                        } else if (current == 'JPY') {
                            self.currencyJPY = true
                            self.coinImg = require('@/assets/logo/japan.png')
                        } else if (current == 'KRW') {
                            self.currencyKRW = true
                            self.coinImg = require('@/assets/logo/korea.png')
                        } else if (current == 'THB') {
                            self.currencyThb = true
                            self.coinImg = require('@/assets/logo/thai.png')
                        }

                        self.getMember()

                    } else {
                        self.$Message.error(resp.mesg);
                    }
                });
        },

        gotocharge() {

            this.$router.push("/Recharge");

        },
        transaction() {

            this.$router.push("/exchange");

        },

        addAddress() {

            this.$router.push("/cart");

        },

        handleBeforeUSDTChange() {
            return new Promise((resolve) => {
                // this.$Modal.confirm({
                //     title: '切换确认',
                //     content: '您确认要切换开关状态吗？',
                //     onOk: () => {
                if (this.currencyUSDT == true) {
                    return
                } else {
                    this.commitChange('USDT')
                    resolve();
                }


                //     }
                // });


            });
        },
        handleBeforeUSDChange() {
            return new Promise((resolve) => {
                // this.$Modal.confirm({
                //     title: '切换确认',
                //     content: '您确认要切换开关状态吗？',
                //     onOk: () => {
                if (this.currencyUSD == true) {
                    return
                } else {
                    this.commitChange('USD')
                    resolve();
                }

                //     }
                // });


            });
        },
        handleBeforeVNDChange() {
            return new Promise((resolve) => {
                // this.$Modal.confirm({
                //     title: '切换确认',
                //     content: '您确认要切换开关状态吗？',
                //     onOk: () => {
                if (this.currencyVND == true) {
                    return
                } else {
                    this.commitChange('VND')
                    resolve();
                }

                //     }
                // });


            });
        },

        handleBeforeCNYChange() {
            return new Promise((resolve) => {
                // this.$Modal.confirm({
                //     title: '切换确认',
                //     content: '您确认要切换开关状态吗？',
                //     onOk: () => {
                if (this.currencyCNY == true) {
                    return
                } else {
                    this.commitChange('CNY')
                    resolve();
                }

                //     }
                // });


            });
        },
        handleBeforeIDRChange() {
            return new Promise((resolve) => {
                // this.$Modal.confirm({
                //     title: '切换确认',
                //     content: '您确认要切换开关状态吗？',
                //     onOk: () => {
                if (this.currencyIDR == true) {
                    return
                } else {
                    this.commitChange('IDR')
                    resolve();
                }

                //     }
                // });


            });
        },
        handleBeforeJPYChange() {
            return new Promise((resolve) => {
                // this.$Modal.confirm({
                //     title: '切换确认',
                //     content: '您确认要切换开关状态吗？',
                //     onOk: () => {
                if (this.currencyJPY == true) {
                    return
                } else {
                    this.commitChange('JPY')
                    resolve();
                }

                //     }
                // });


            });
        },
        handleBeforeKRWChange() {
            return new Promise((resolve) => {
                // this.$Modal.confirm({
                //     title: '切换确认',
                //     content: '您确认要切换开关状态吗？',
                //     onOk: () => {
                if (this.currencyKRW == true) {
                    return
                } else {
                    this.commitChange('KRW')
                    resolve();
                }

                //     }
                // });


            });
        },
        handleBeforeThbChange() {
            return new Promise((resolve) => {
                // this.$Modal.confirm({
                //     title: '切换确认',
                //     content: '您确认要切换开关状态吗？',
                //     onOk: () => {
                if (this.currencyThb == true) {
                    return
                } else {
                    this.commitChange('THB')
                    resolve();
                }

                //     }
                // });


            });
        },

        eyeONOff() {
            this.eyeON = !this.eyeON
            localStorage.setItem('eyeON', this.eyeON)
        },

        commitChange(currency) {
            let self = this
            let param = {}
            param.remark = currency
            param.userNo = this.$store.getters.member.userNo
            this.loading = true
            this.$http
                .post(self.host + this.api.uc.updatePersonalInfo, param)
                .then(response => {
                    self.loading = false
                    let resp = response.body;
                    if (resp.code == "0000") {
                        self.$Message.success(resp.mesg);
                    } else {
                        self.$Message.error(resp.mesg);
                    }
                });
        },

        changeUSDT(status) {
            console.log(status);
            this.currencyUSD = false
            this.currencyVND = false
            this.currencyCNY = false
            this.currencyUSDT = true
            this.currencyIDR = false
            this.currencyJPY = false
            this.currencyKRW = false
            this.currencyThb = false
            this.coinAmount = this.amountUsdt.amount
            this.coinType = 'USDT'
            this.coinImg = require('@/assets/logo/usdt.png');
        },

        changeUSD(status) {
            console.log(status);
            this.currencyUSDT = false
            this.currencyVND = false
            this.currencyCNY = false
            this.currencyUSD = true
            this.currencyIDR = false
            this.currencyJPY = false
            this.currencyKRW = false
            this.currencyThb = false
            this.coinAmount = this.amountUsd.amount
            this.coinType = 'USD'
            this.coinImg = require('@/assets/logo/usa.png')
        },

        changeVND(status) {
            console.log(status);
            this.currencyUSDT = false
            this.currencyUSD = false
            this.currencyCNY = false
            this.currencyVND = true
            this.currencyIDR = false
            this.currencyJPY = false
            this.currencyKRW = false
            this.currencyThb = false
            this.coinAmount = this.amountVnd.amount
            this.coinType = 'VND'
            this.coinImg = require('@/assets/logo/vietnam.png')
        },
        changeCNY(status) {
            console.log(status);
            this.currencyUSDT = false
            this.currencyUSD = false
            this.currencyVND = false
            this.currencyCNY = true
            this.currencyIDR = false
            this.currencyJPY = false
            this.currencyKRW = false
            this.currencyThb = false
            this.coinAmount = this.amountCny.amount
            this.coinType = 'CNY'
            this.coinImg = require('@/assets/logo/china.png')
        },
        changeKRW(status) {
            console.log(status);
            this.currencyUSDT = false
            this.currencyUSD = false
            this.currencyVND = false
            this.currencyCNY = false
            this.currencyIDR = false
            this.currencyJPY = false
            this.currencyKRW = true
            this.currencyThb = false
            this.coinAmount = this.amountKRW.amount
            this.coinType = 'KRW'
            this.coinImg = require('@/assets/logo/korea.png')
        },
        changeIDR(status) {
            console.log(status);
            this.currencyUSDT = false
            this.currencyUSD = false
            this.currencyVND = false
            this.currencyCNY = false
            this.currencyIDR = true
            this.currencyJPY = false
            this.currencyKRW = false
            this.currencyThb = false
            this.coinAmount = this.amountIDR.amount
            this.coinType = 'IDR'
            this.coinImg = require('@/assets/logo/indiaN.png')
        },
        changeJPY(status) {
            console.log(status);
            this.currencyUSDT = false
            this.currencyUSD = false
            this.currencyVND = false
            this.currencyCNY = false
            this.currencyIDR = false
            this.currencyJPY = true
            this.currencyKRW = false
            this.currencyThb = false
            this.coinAmount = this.amountJPY.amount
            this.coinType = 'JPY'
            this.coinImg = require('@/assets/logo/japan.png')
        },
        changeThb(status) {
            console.log(status);
            this.currencyUSDT = false
            this.currencyUSD = false
            this.currencyVND = false
            this.currencyCNY = false
            this.currencyIDR = false
            this.currencyJPY = false
            this.currencyKRW = false
            this.currencyThb = true
            this.coinAmount = this.amountThb.amount
            this.coinType = 'THB'
            this.coinImg = require('@/assets/logo/thai.png')
        },


        gotoWithdraw() {

            this.$router.push("/Withdraw");

        },
        goback() {
            //获取个人安全信息
            this.$router.back(-1)

        },


        getMember() {
            //获取个人安全信息
            let self = this;

            this.$http
                .post(self.host + this.api.uc.personalWallet)
                .then(response => {
                    let resp = response.body;
                    if (resp.code == "0000") {

                        resp.data.forEach(element => {
                            if (element.ccy == 'USDT') {
                                self.amountUsdt = element
                            } else if (element.ccy == 'USD') {
                                self.amountUsd = element
                            } else if (element.ccy == 'VND') {
                                self.amountVnd = element
                            } else if (element.ccy == 'CNY') {
                                self.amountCny = element
                            } else if (element.ccy == 'IDR') {
                                self.amountIDR = element
                            } else if (element.ccy == 'JPY') {
                                self.amountJPY = element
                            } else if (element.ccy == 'KRW') {
                                self.amountKRW = element
                            } else if (element.ccy == 'THB') {
                                self.amountThb = element
                            }
                        })


                        if (self.currencyUSDT == true) {
                            self.coinAmount = self.amountUsdt.amount
                        } else if (self.currencyUSD == true) {
                            self.coinAmount = self.amountUsd.amount
                        } else if (self.currencyVND == true) {
                            self.coinAmount = self.amountVnd.amount
                        } else if (self.currencyCNY == true) {
                            self.coinAmount = self.amountCny.amount
                        } else if (self.currencyIDR == true) {
                            self.coinAmount = self.amountIDR.amount
                        } else if (self.currencyJPY == true) {
                            self.coinAmount = self.amountJPY.amount
                        } else if (self.currencyKRW == true) {
                            self.coinAmount = self.amountKRW.amount
                        } else if (self.currencyThb == true) {
                            self.coinAmount = self.amountThb.amount
                        }

                    } else {
                        self.$Message.error(resp.mesg);
                    }
                });
        },

    }

}


</script>
<style lang="scss" scoped>
.login_form {
    width: 100%;
    height: $full-page;
    overflow: hidden auto;
    background-color: $color-background;
}

.mc_card {
    height: 200px;
    //margin-top: 45px;
    //margin-left: 10px;
    width: 95%;
    margin: 45px auto 0;
    //background-image: url(../assets/logo/backcard.png);
    //background-size: 100% 100%;
    background-position: top;
    background-repeat: no-repeat;
    border-radius: 20px;
    color: $color-text-tag;
    border: none;
}

.celllall {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    color: #fff;
}

.box {
    position: fixed;
    top: 0;
    display: flex;
    height: 50px;
    padding: 10px;
    width: 100%;
    align-items: flex-start;
    align-items: baseline;
    //  background: url(../assets/netmage/xxyz.png);
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    z-index: 200;
}

/deep/ .ivu-card-body {
    padding: 5px;
}

/deep/ .ivu-card {
    background-color: transparent;
}


.head_1 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    height: $head;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    z-index: 200;
    width: 100%;
}

/deep/ .ivu-divider-horizontal {
    margin: 1px 0;
}

/deep/ .ivu-divider {
    background-color: #2f2f2f;
}
</style>
