<template>
    <div class="login_form ">
        <div class="headBox">
            <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">
            <span style="font-size:20px;">
                {{ $t('common.exchange') }}
              </span>
            <span>{{  }}</span>
        </div>
        <div class="body">
            <div style="display: flex;flex-direction: column;align-items: start;">
                <span class="p1">{{ $t('uc.finance.xx218') }} : {{ amount1 }}</span>
                <!-- <div style="display: flex; align-items: center;width: 100%; background-color: #293042;border-radius: 25px;height: 50px;padding: 10px;">
                  <Select v-model="model55"   style="width:120px;" @on-select="changeValue">
                    <img :src="currUrl" slot="prefix" width="20" size="small" />
                      <Option v-for="item in moneyList" :value="item.ccy" :key="item.id">{{ item.ccy }}</Option>
                  </Select>
                  <img src="../assets/logo/3333333.png" width="20" height="20" alt="">

                  <Input v-model="value12" clearable size="large"  style="width: 230px;" />

                </div> -->

                <div style="display: flex;align-items: flex-start;width: 100%;margin-top: 10px;align-items: center;">
                    <!-- <div style="display: flex;flex-direction: column;width: 30%;">
                      <span style="color: #293042;">{{ $t('common.amount') }}({{ currentCry }})</span>
                      <span style="color: #fff;font-size: medium;">{{amount2}}</span>
                    </div> -->
                    <!-- <div style="width: 30%;">
                      <img src="../assets/logo/exxx1.png" width="60" alt="" @click="changecccc">

                    </div> -->

                    <div
                        style="display: flex;flex-direction: column; align-items: flex-start; width: 100%;">
                        <div style="display: flex;flex-direction: row; width: 50%;justify-content: start;">
                            <span style="color: aliceblue;">{{ $t('uc.finance.xx192') }} </span>
                            <span style="color: #f0ac19;padding: 0 4px">{{ currentCry }}</span>
                            <span style="color: aliceblue;">{{ $t('uc.finance.xx219') }}</span>
                        </div>
                        <p style="color: #fff;font-size: medium;padding: 10px 0">1 {{ $t('uc.finance.xx220') }} =
                            {{ Number(currentRate) }} {{ currentCry }}</p>
                    </div>

                </div>


                <!-- <span style="color: #fff;font-size: small;margin-left:20px">{{ $t('uc.finance.xx191') }}</span> -->
                <div class="btn">
                    <div class="money" :class="{'active':value11 == 100}" @click="value11 = 100">100</div>
                    <div class="money" :class="{'active':value11 == 500}" @click="value11 = 500">500</div>
                    <div class="money" :class="{'active':value11 == 500}" @click="value11 = 1000">1000</div>
                    <div class="money" :class="{'active':value11 == 5000}" @click="value11 = 5000">5000</div>
                    <div class="money" :class="{'active':value11 == 10000}" @click="value11 = 10000">10000</div>
                    <div class="money" :class="{'active':value11 == 50000}" @click="value11 = 50000">50000</div>
                </div>
                <div
                    style="display: flex; align-items: center; width: 100%; background-color: #293042;border-radius: 5px;height: 50px;padding: 10px;">

                    <Select v-model="model5" style="width:120px;" @on-select="changeValue">
                        <!--                        <img src="../assets/logo/usdt.png" slot="prefix" width="20" size="small"/>-->
                        <Option v-for="item in moneyList" :value="item.ccy" :key="item.id">{{ item.ccy }}</Option>
                    </Select>
                    <img src="../assets/logo/3333333.png" width="20" height="20" alt="">
                    <!-- <InputNumber v-model="value11" controls-outside :min="0" :max="amount1" style="width: 50%;"
                    :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"></InputNumber> -->
                    <Input v-model="value11" clearable size="large" type="number" style="width: 230px;"
                           @on-blur="changecccc" @on-change="onKeyYHKNameInput1"/>

                </div>

                <div style="width: 100%;justify-content:flex-end ; display: flex;">
                    <span>{{ aboutMoney11 }}:{{ currentCry }}</span>
                </div>
            </div>


            <Button type="success" size="large" @click="checkPassword"
                    style="width: 40%;margin-top: 40px;background-color: #1375E1;border-radius: 20px;color: #fff;border: none">
                {{ $t('uc.finance.xx165') }}
            </Button>


        </div>
        <Modal v-model="modal2" width="450" :closable="false">
            <p slot="header" style="color:#f60;text-align:center">
                <span>{{ $t('uc.forget.emailcodetip1') }}</span>
            </p>
            <Form class="withdraw-form-inline1" ref="formInline" :model="formInline">
                <FormItem>
                    <Input type="password" password v-model="formInline.fundpwd"
                           :placeholder="$t('uc.forget.emailcodetip')"></Input>
                    <!-- <input type="password"  v-model="formInline.fundpwd" autofocus :placeholder="$t('uc.forget.emailcodetip')" style="width:100%"> -->
                </FormItem>
                <FormItem>
                    <Input type="password" password v-model="formInline.refundpwd"
                           :placeholder="$t('uc.forget.confirmpwd')"></Input>
                    <!-- <input type="password"  v-model="formInline.refundpwd" autofocus :placeholder="$t('uc.forget.emailcodetip')" style="width:100%"> -->

                </FormItem>
            </Form>
            <div slot="footer">
                <Button type="primary" size="large" long @click="submitCode">{{ $t('uc.forget.save') }}</Button>
            </div>
        </Modal>

        <Modal v-model="modal9" width="450" :closable="false" :mask-closable="false">

            <p slot="header">
                {{ $t("uc.forget.emailcodetip") }}
            </p>
            <Form class="withdraw-form-inline" ref="formInline" :model="formInline" inline>
                <FormItem>
                    <Input type="password" password v-model="formInline.fundpwd"
                           :placeholder="$t('uc.forget.emailcodetip')"></Input>
                    <!-- <input type="password"  v-model="formInline.fundpwd" autofocus :placeholder="$t('uc.forget.emailcodetip')" style="width:100%"> -->

                </FormItem>

            </Form>

            <div slot="footer">
                <span
                    style="background:#f0ac19;color:#fff;width:80px;border-radius:30px;display:inline-block;text-align:center;height:30px;line-height: 30px;"
                    @click="checkpp">{{ $t("common.ok") }}</span>
            </div>
        </Modal>


        <Spin fix v-show="eloading">
            <div class="loader">
                <img src="../assets/logo/loading.svg" alt="">
            </div>
        </Spin>

    </div>
</template>

<script>
export default {
    data() {
        return {
            moneyList: [],
            moneyList1: [],
            rateList: [],
            formInline: {
                code: "",
                fundpwd: "",
                refundpwd: ''
            },
            isusdt: false,
            model6: 'USDT',
            model5: 'USDT',
            model55: '',
            model66: 'USDT',
            value11: 0,
            value12: 0,
            amount1: 0,
            amount2: '0.00',
            aboutMoney11: 0,
            aboutMoney12: 0,
            eloading: false,
            modal2: false,
            modal9: false,
            tabname: 0,
            tempmember: '',
            currentCry: '',
            currentRate: 0,
            currUrl: '',
        }
    },


    watch: {
        value11: function () {

            let xxxy = ''
            this.rateList.forEach(element => {
                if (element.ccyTo.toUpperCase() == this.model5.toUpperCase()) {
                    xxxy = element.exchangeRate
                }
            });
            this.aboutMoney11 = Number(this.value11 * Number(xxxy)).toFixed(2)


        },

        value12: function () {
            let xxxy = ''
            this.rateList.forEach(element => {
                if (element.ccy == this.model66) {
                    xxxy = element.exchangeRate
                }
            });
            this.aboutMoney12 = Number(this.value12 * Number(xxxy)).toFixed(2)

        },


    },
    created() {
        this.getMemberInfo()
        this.getMember()
        this.getRate()
        this.getIntegral()

    },
    methods: {

        getIntegral() {
            let that = this
            this.$http.get(this.host + '/user-integral/getIntegralAccount').then(response => {
                let resp = response.body
                if (resp.data) {  //没数据  都为0
                    that.amount1 = resp.data.integral

                }
            });
        },

        changecccc() {
//       this.isusdt = !this.isusdt
// this.clearValues()
// if (this.isusdt == true) {
//   this.moneyList1.forEach(element => {
//         if (element.ccy == 'USDT') {
//           this.amount2 = element.amount
//         }
//       })
// }

            if (Number(this.value11) > Math.floor(Number(this.amount1))) {
                this.value11 = Math.floor(Number(this.amount1))
            }
            if (parseFloat(this.value11) != parseInt(this.value11)) {
                this.value11 = 0
            }
        },

        onKeyYHKNameInput1() {
            let value = '' + this.value11
            // console.log(this.body.ccyScale,"币种精度")
            let data = 0 //币种精度
            value = value.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
                .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
                .replace('.', '$#$')
                .replace(/\./g, '')
                .replace('$#$', '.')
                .replace(new RegExp(`^(\\-)*(\\d+)\\.(\\d{${data}}).*$`), '$1$2.$3') // 只能输入data个小数
            if (value.indexOf('.') < 0 && value != '') {
                // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                value = parseFloat(value);
            }
            this.$nextTick(function () {
                this.value11 = Number(value)

            })
        },

        checkPassword() {

            if (this.tempmember.jbPassword) {

                this.modal9 = true
            } else {
                this.modal2 = true
            }
        },

        inorout(name) {
            this.tabname = name
        },

        checkpp() {
            if (this.isusdt == true) {
                this.submitOut(this.formInline.fundpwd)
            } else
                this.submitIn(this.formInline.fundpwd)


            this.modal9 = false

        },

        getMemberInfo() {
            //获取个人安全信息
            var self = this;
            this.eloading = true
            this.$http
                .post(self.host + this.api.uc.memberInfo)
                .then(response => {
                    var resp = response.body;
                    self.eloading = false
                    if (resp.code == "0000") {
                        self.tempmember = resp.data

                    } else {
                        self.$Message.error(resp.mesg);
                    }
                });
        },

        submitCode() {
            let that = this
            if (this.formInline.fundpwd != this.formInline.refundpwd) {
                this.$Notice.error({
                    title: this.$t("common.tip"),
                    desc: this.$t("uc.forget.pwdvalidate2")
                });
                return
            }

            let params = {};

            params['jbPassword'] = this.formInline.fundpwd
            this.$http
                .post(this.host + this.api.uc.activitylist, params)
                .then(response => {
                    that.fundpwd = "";
                    var resp = response.body;
                    if (resp.code == "0000") {
                        that.modal2 = false;
                        that.getMemberInfo()
                        if (that.isusdt == true) {
                            that.submitOut(this.formInline.fundpwd)
                        } else
                            that.submitIn(this.formInline.fundpwd)
                    } else {
                        that.$Message.error(resp.mesg);
                    }
                });
        },

        goback() {
            //获取个人安全信息
            this.$router.back(-1)

        },
        takeAllUSDT() {
            this.value11 = Number(this.amount1)
        },
        takeAll() {
            this.value12 = Number(this.amount2)
        },
        getMember() {
            //获取个人安全信息
            var self = this;

            this.loading = true
            this.$http
                .post(self.host + this.api.uc.personalWallet)
                .then(response => {
                    var resp = response.body;
                    self.loading = false
                    if (resp.code == "0000") {
                        // self.user = resp.data;
                        // self.usernameS = this.user.username.slice(0,1);

                        self.moneyList = []

                        resp.data.forEach(element => {
                            // if (element.ccy != 'USDT') {
                            self.moneyList.push(element)
                            // }else{
                            //   self.moneyList1.push(element)
                            //   self.amount1 = element.amount
                            // }


                        });

                        self.currentCry = self.moneyList[0].ccy


                    } else {
                        // self.$Message.error(resp.mesg);
                        this.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: resp.mesg
                        });
                        // this.$Message.error(this.$t('common.logintip'));
                    }
                });
        },

        clearValues() {

            this.formInline.code = ""
            this.formInline.fundpwd = ""
            this.formInline.refundpwd = ""
            this.model5 = ''
            this.model55 = ''

            this.value11 = 0
            this.value12 = 0
            this.amount1 = 0
            this.amount2 = '0.00'
            this.aboutMoney11 = 0
            this.aboutMoney12 = 0
            this.currentCry = ''
            this.currentRate = 0
            this.currUrl = ''


        },
        changeValue(item) {
            // if (this.isusdt == false) {
            //   this.moneyList.forEach(element => {
            //   if (element.ccy == item.value) {
            //     this.amount2 = element.amount
            //   }
            // })
            // }
            this.value11 = 0
            this.currentCry = item.value
            switch (item.value) {
                case 'USD':
                    this.currUrl = require('@/assets/logo/usa.png')
                    break;
                case 'VND':
                    this.currUrl = require('@/assets/logo/vietnam.png')
                    break;
                case 'CNY':
                    this.currUrl = require('@/assets/logo/china.png')
                    break;
                case 'KRW':
                    this.currUrl = require('@/assets/logo/korea.png')
                    break;
                case 'IDR':
                    this.currUrl = require('@/assets/logo/indiaN.png')
                    break;
                case 'JPY':
                    this.currUrl = require('@/assets/logo/japan.png')
                    break;
                case 'THB':
                    this.currUrl = require('@/assets/logo/thai.png')
                    break;
                default:
                    break;
            }

            this.rateList.forEach(element => {
                // this.currentRate = element.exchangeRate
                // if (this.isusdt == false) {
                //   if (element.ccy == item.value) {
                //     this.currentRate = element.exchangeRate
                //   }
                // }else {
                if (element.ccyTo == item.value) {
                    this.currentRate = element.exchangeRate
                }
                // }
            });
        },
        setUsdt() {
            this.moneyList1.forEach(element => {
                if (element.ccy == 'USDT') {
                    this.amount2 = element.amount
                }
            })
            this.currentCry = 'USDT'


        },
        submitOut(pass) {
            //获取个人安全信息
            let self = this;
            let param = {};
            // BigDecimal amount;   String ccy;  String ccyTo;
            param.amount = Number(this.value11)
            if (this.aboutMoney11 < 1) {
                this.$Message.error(this.$t('uc.finance.xx199'));
                return
            }

            param.ccy = this.model6
            param.ccyTo = this.model5
            param.pass = pass
            this.$http
                .post(self.host + this.api.uc.submetExchange, param)
                .then(response => {
                    let resp = response.body;
                    if (resp.code == "0000") {
                        self.clearValues()
                        self.getMember()
                        self.setUsdt()
                        self.$Message.success(resp.mesg)
                    } else {
                        self.$Message.error(resp.mesg);
                    }
                });
        },

        submitIn(pass) {
            //获取个人安全信息
            let self = this;
            let param = {};
            // BigDecimal amount;   String ccy;  String ccyTo;
            param.amount = Number(this.value11)
            if (param.amount < 1) {
                this.$Message.error(this.$t('uc.finance.xx199'));
                return
            }
            param.ccy = 'integral'
            param.ccyTo = this.model5
            param.pass = pass
            this.$http
                .post(self.host + this.api.uc.submetExchange, param)
                .then(response => {
                    let resp = response.body;
                    if (resp.code == "0000") {
                        self.clearValues()
                        self.getMember()
                        self.getIntegral()
                        self.$Message.success(resp.mesg)
                    } else {
                        self.$Message.error(resp.mesg);
                    }
                });
        },

        // resetParam(){
        //             this.model6= ''
        //             this.model55 = ''
        //             this.value11 = 0
        //             this.amount = 0

        //             this.clearValues()
        // },

        getRate() {
            let that = this

            this.$http
                .post(this.host + this.api.uc.rateList)
                .then(response => {
                    var resp = response.body;
                    if (resp.code == '0000') {
                        that.rateList = resp.data.list
                        that.currentRate = that.rateList[0].exchangeRate
                    } else
                        that.$Message.error(resp.mesg);
                })
        },

    }
}
</script>

<style lang="scss" scoped>
.login_form {
    background: $color-background;
    height: $full-page;
    position: relative;
    overflow: hidden;
    text-align: center;

}


/deep/ .ivu-input {

    border-radius: 8px;
    border: 0px solid #3d56a2;
    background-color: #293042;
    color: #fff;
}


/deep/ .ivu-select-selection {
    background: transparent;
    border: none;
    color: aliceblue;
}

.body {
    margin-top: 60px;
    color: $color-text;
    padding: 10px;

    .p1 {
        width: 100%;
        color: #fff;
        font-size: small;
        border: 1px solid #ccc;
        padding: 10px 6px;
        text-align: left;
        border-radius: 4px;
    }
}

.box {
    position: fixed;
    top: 0;
    display: flex;
    height: 50px;
    padding: 10px;
    width: 100%;
    justify-content: space-between;
    z-index: 200;
    color: $color-text;
}

.btn {
    width: 90%;
    height: 100px;
    margin: 10px auto;
    //background-color: pink;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

    .money {
        border-radius: 4px;
        width: 30%;
        height: 36px;
        border: 1px solid #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ccc;
    }

    .active{
        border-color: #fff;
        color: #fff;
    }
}


</style>
