<template>
    <div class="login_form">
        <div class="headBox">
            <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">
            <span style="font-weight: 600;">
                {{ $t('uc.finance.recordetail') }}
              </span>
            <div style="display: flex;align-items: center;">
                <img src="../assets/logo/ma11.png" width="20" alt="" @click="otherpicke">
            </div>
        </div>
        <div class="content">
            <div style="width: 100%;display: flex;align-items: flex-start;margin-left: 10px;">
                <Dropdown trigger="click" @on-click="handleMenu"
                          style="height: 40px;border: 1px solid #b1bad3;line-height: 40px;border-radius: 4px;padding: 0 30px">
                    <a href="javascript:void(0)" style="color: aliceblue;">
                        {{ dropMenuName }}
                        <Icon type="ios-arrow-down"></Icon>
                    </a>
                    <DropdownMenu slot="list">
                        <DropdownItem name="0">{{ $t('uc.finance.xx124') }}</DropdownItem>
                        <DropdownItem name="1">{{ $t('uc.finance.xx125') }}</DropdownItem>
                        <DropdownItem name="2">{{ $t('uc.finance.xx126') }}</DropdownItem>
                        <DropdownItem name="3">{{ $t('uc.finance.xx148') }}</DropdownItem>
                        <DropdownItem name="4">{{ $t('uc.finance.xx206') }}</DropdownItem>
                        <DropdownItem name="5">{{ $t('uc.finance.xx174') }}</DropdownItem>
                        <DropdownItem name="6">{{ $t('uc.finance.xx223') }}</DropdownItem>
                        <DropdownItem name="7">{{ $t('uc.finance.xx51') }}</DropdownItem>
                        <DropdownItem name="8">{{ $t('uc.finance.xx26') }}</DropdownItem>
                        <DropdownItem name="9">{{ $t('uc.finance.xx27') }}</DropdownItem>
                        <DropdownItem name="10">{{ $t('uc.finance.xx227') }}</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
            <div v-if="selectItem==0">
                <div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;" v-if="showxxy == true">
                    {{ $t('uc.finance.xx205') }}
                </div>
                <Scroll v-throttle :on-reach-bottom="nextPage" :height="realHeight">
                    <Card dis-hover v-for="(item, index) in datalist" :key="index"
                          :class="index%2 == 0? 'active': 'notactive'">
                        <div style="display:flex; justify-content: space-between;">
                            <div style="font-size: 14px;color: #b1bad3;font-weight: 600;">{{ paserReceipt(item.tranCode)
                                }}
                            </div>
                            <div style="color: #b1bad3;font-size: 14px;">
                                <span v-if="Number( item.tradeAmount ) > 0" style="color: #45d91e;">
                                 {{ Number(item.tradeAmount).toFixed(2) }}</span>
                                <span v-else style="color: #f00;">{{ Number(item.tradeAmount).toFixed(2) }}</span>
                            </div>
                        </div>
                        <div class="body_box1">
                            <div
                                style="display: flex; justify-content: flex-start; font-size: 14px;color: #b1bad3;margin-top:5px">
                                <span> {{ item.tradeTime | datefomt('YYYY-MM-DD HH:mm:ss') }}</span>
                            </div>
                            <div style="font-size: 14px;color: #b1bad3;">
                                <span>{{ item.ccy }} : </span>
                                <span>{{ Number(item.accountBalance).toFixed(2) }}</span>
                            </div>
                        </div>
                    </Card>
                </Scroll>
            </div>
            <div v-else-if="selectItem==1">
                <div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;"
                     v-if="showxxy == true">
                    <span style="font-size: 14px;color: #b1bad3; ">{{ $t('uc.finance.xx205') }}</span>
                </div>
                <Scroll :on-reach-bottom="nextPage" :height="realHeight">
                    <Card dis-hover v-for="(item, index) in datalist" :key="index"
                          :class="index%2 == 0? 'active': 'notactive'">
                        <div style="width:100%;display:flex;justify-content:space-between;color: #b1bad3;">
                            <span
                                style="font-size: 14px;color: #b1bad3;font-weight: 600;">{{ handledep(item.depositType)
                                }}</span>
                            <span v-if="item.status == 1"
                                  style="color: #45d91e ;font-size: 14px;">{{ parseStatus(item.status) }}</span>
                            <span v-else style="color: red;font-size: 14px;">{{ parseStatus(item.status) }}</span>
                        </div>
                        <div class="body_box1">
                            <div style="display: flex; justify-content: flex-end; font-size: 14px;color: #b1bad3;">
                                <span> {{ item.createTime }}</span>
                            </div>
                            <div>
                                <span style="font-size:14px;color: #b1bad3;">{{ item.ccy }} : </span>
                                <span style="font-size:14px;color: #b1bad3">{{ Number(item.depositAmount).toFixed(2)
                                    }}</span>
                            </div>
                        </div>
                    </Card>
                </Scroll>
            </div>
            <div v-else-if="selectItem == 2">
                <div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;" v-if="showxxy == true">
                    {{ $t('uc.finance.xx205') }}
                </div>
                <Scroll :on-reach-bottom="nextPage" :height="realHeight">
                    <Card dis-hover v-for="(item, index) in datalist" :key="index"
                          :class="index%2 == 0? 'active': 'notactive'">
                        <div
                            style="width:100%;display:flex;justify-content:space-between;font-size: 14px; color: #b1bad3;">
                            <!-- <span style="font-weight: 600;" v-if="item.withdrawType == '0'">{{ $t('uc.finance.xx19') }}</span>
                            <span style="font-weight: 600;" v-else>{{ $t('uc.finance.xx22') }}</span>
                            <span style="font-weight: 600;" >{{ $t('uc.finance.invitingmining') }} : {{ Number(item.withdrawalAmount).toFixed(2)}}</span>
                            <span>{{ parseStatus(item.status) }}</span> -->
                            <span
                                style="font-size: 14px;color: #b1bad3;font-weight: 600;">{{ handlewith(item.withdrawType)
                                }}</span>
                            <span v-if="item.status == 1"
                                  style="color: #45d91e ;font-size: 14px;">{{ parseStatus(item.status) }}</span>
                            <span v-else style="color: red;font-size: 14px;">{{ parseStatus(item.status) }}</span>

                        </div>
                        <div class="body_box1">
                            <!-- <div style="display: flex;justify-content: space-between;width: 100%;">
                              <span>{{ $t('uc.finance.xx68') }} : {{ item.ccy }}
                            </span>
                             -->
                            <div style="display: flex; justify-content: flex-end; font-size: 14px;color: #b1bad3;">
                                <span> {{ item.createTime | datefomt('YYYY-MM-DD HH:mm:ss') }}</span>
                            </div>
                            <div>
                                <span style="font-size: 14px;color: #b1bad3;">{{ item.ccy }} : </span>
                                <span
                                    style="font-size: 14px;color: #b1bad3;">{{ Number(item.withdrawalAmount).toFixed(2)
                                    }}</span>

                            </div>

                            <!-- </div> -->
                            <!-- <span>{{ $t('uc.finance.tradetail') }} : {{ Number(item.balance).toFixed(2)}}</span> -->


                        </div>
                    </Card>
                </Scroll>
            </div>
            <div v-else-if="selectItem==3">
                <div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;"
                     v-if="showxxy == true">
                    {{ $t('uc.finance.xx205') }}
                </div>

                <Scroll :on-reach-bottom="nextPage" :height="realHeight">
                    <Card dis-hover v-for="(item, index) in datalist" :key="index"
                          :class="index%2 == 0? 'active': 'notactive'">
                        <div style="width:100%;display:flex;justify-content:space-between;color: #b1bad3;">

                            <span style="font-size: 14px;color: #b1bad3;font-weight: 600;">{{ $t('uc.finance.xx148')
                                }}</span>
                            <span v-if="item.status == 1"
                                  style="color: #45d91e ;font-size: 14px;">{{ parseStatus(item.status) }}</span>
                            <span v-else style="color: red;font-size: 14px;">{{ parseStatus(item.status) }}</span>
                            <div>
                                <span style="font-size:14px;color: #b1bad3;">{{ item.ccy }} : </span>
                                <span
                                    style="font-size:14px;color: #f00;"> {{ Number(item.tradeAmount).toFixed(2)
                                    }} </span>
                            </div>

                        </div>
                        <div class="body_box1">

                            <div style="display: flex; justify-content: flex-end; font-size: 14px;color: #b1bad3;">
                                <span> {{ item.createTime | datefomt('YYYY-MM-DD HH:mm:ss') }}</span>
                            </div>

                        </div>
                    </Card>
                </Scroll>
            </div>
            <!-- <div v-else-if="selectItem==4">
                <div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;"
                     v-if="showxxy == true">
                    {{ $t('uc.finance.xx205') }}
                </div>
                <Scroll :on-reach-bottom="nextPage" :height="realHeight">
                    <Card dis-hover v-for="(item, index) in datalist" :key="index"
                          :class="index%2 == 0? 'active': 'notactive'">
                        <div
                            style="width:100%;display:flex;justify-content:space-between;font-size:14px;color: #b1bad3;">

                            <div>
                                <span style="font-size: 14px;color: #b1bad3;font-weight: 600;">{{ $t('uc.finance.xx153') }}</span>
                            </div>
                            <span>{{ item.valueIntegral }}</span>

                        </div>
                        <div class="body_box1">

                            <div style="display: flex; justify-content: flex-end; font-size: 14px;color: #b1bad3;">
                                <span> {{ item.createTime.monthValue + ':' + item.createTime.dayOfMonth + ':' + item.createTime.dayOfMonth + ' ' + item.createTime.hour + ':' + item.createTime.minute
                                    }}</span>
                            </div>

                        </div>
                    </Card>
                </Scroll>
            </div> -->
            <div v-else>

                <div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;"
                     v-if="showxxy == true">
                    {{ $t('uc.finance.xx205') }}
                </div>
                <Scroll :on-reach-bottom="nextPage" :height="realHeight">
                    <Card dis-hover v-for="(item, index) in datalist" :key="index"
                          :class="index%2 == 0? 'active': 'notactive'">
                        <div
                            style="width:100%;display:flex;justify-content:space-between;font-size:14px;color: #b1bad3;">

                            <div style="font-size: 14px;color: #b1bad3;font-weight: 600;">{{ paserReceipt(item.tranCode)
                                }}
                            </div>
                            <div style="color: #b1bad3;font-size: 14px;">

              <span v-if="Number( item.tradeAmount ) > 0" style="color: #45d91e ;">
                {{ Number(item.tradeAmount).toFixed(2) }}</span>
                                <span v-else style="color: #f00;">{{ Number(item.tradeAmount).toFixed(2) }}</span>
                            </div>

                        </div>
                        <div class="body_box1">

                            <div
                                style="display: flex; justify-content: flex-start; font-size: 14px;color: #b1bad3;margin-top:5px">
                                <span> {{ item.tradeTime | datefomt('YYYY-MM-DD HH:mm:ss') }}</span>
                            </div>

                            <div style="font-size: 14px;color: #b1bad3;">
                                <span>{{ item.ccy }} : </span>
                                <span>{{ Number(item.accountBalance).toFixed(2) }}</span>
                            </div>
                        </div>
                    </Card>
                </Scroll>
            </div>
        </div>
        <calendar :show.sync="calendarShow" :defaultDate="defaultDate" mode="during" @change="onChange" style="color: #0073ee;"/>
        <Drawer :title="$t('uc.finance.xx3')" placement="bottom" v-model="value8" height="25">
            <RadioGroup v-model="rechargestatus" size="large" v-if="activeName == '1'">
                <Radio label="0">{{ $t('uc.finance.xx63') }}</Radio>
                <Radio label="1">{{ $t('uc.finance.xx64') }}</Radio>
                <Radio label="2">{{ $t('uc.finance.xx65') }}</Radio>
                <Radio label="3">{{ $t('common.exchangeAll') }}</Radio>
                <Radio label="4">{{ $t('uc.finance.xx137') }}</Radio>
                <Radio label="5">{{ $t('uc.finance.xx138') }}</Radio>
                <Radio label="6">{{ $t('uc.finance.xx139') }}</Radio>
                <Radio label="7">{{ $t('uc.finance.xx140') }}</Radio>
            </RadioGroup>
            <RadioGroup v-model="withdrawstatus" size="small" v-else-if="activeName == '2'">
                <Radio label="0">{{ $t('uc.finance.xx128') }}</Radio>
                <Radio label="1">{{ $t('uc.finance.xx129') }}</Radio>
                <Radio label="2">{{ $t('uc.finance.xx130') }}</Radio>
                <Radio label="3">{{ $t('uc.finance.xx65') }}</Radio>
                <Radio label="4">{{ $t('common.exchangeAll') }}</Radio>
                <Radio label="5">{{ $t('uc.finance.xx137') }}</Radio>
                <Radio label="6">{{ $t('uc.finance.xx138') }}</Radio>
                <Radio label="7">{{ $t('uc.finance.xx139') }}</Radio>
                <Radio label="8">{{ $t('uc.finance.xx140') }}</Radio>
            </RadioGroup>
            <RadioGroup v-model="commonstatus" size="large" v-else>
                <Radio label="1">{{ $t('uc.finance.xx137') }}</Radio>
                <Radio label="2">{{ $t('uc.finance.xx138') }}</Radio>
                <Radio label="3">{{ $t('uc.finance.xx139') }}</Radio>
                <Radio label="4">{{ $t('uc.finance.xx140') }}</Radio>
            </RadioGroup>
            <Button type="primary" long style="margin-top: 20px;" @click="closebtm">{{ $t('common.ok') }}</Button>
        </Drawer>
        <Spin fix v-show="eloading" style="background:transparent;">
            <div class="loader">
                <img src="../assets/logo/loading.svg" alt="">
            </div>
        </Spin>
    </div>
</template>


<script>

import dayjs from 'dayjs';

export default {

    data() {
        return {
            datalist: [],
            defaultDate: [dayjs(), dayjs().add(-7, 'day')],
            activeName: "",
            currentPage: 1,
            totalPage: 0,
            calendarShow: false,
            eloading: false,
            value8: false,
            rechargestatus: '3',
            withdrawstatus: '4',
            commonstatus: '3',
            isReachBottom: false,
            realHeight: 0,
            startDate: '',
            endDate: '',
            showxxy: '',
            selectItem: 0,
            dropMenuName: ""
        }
    },

    watch: {

        activeName: {
            handler(newVal, oldVal) {
                console.log(`Dog changed: ${oldVal}`);
                this.currentPage = 1
                this.isReachBottom = false
                if (newVal == '0') {
                    this.getTrans(null)

                } else if (newVal == '1') {
                    this.getDeposits()

                } else if (newVal == '2') {
                    this.getWithDraw()
                } else if (this.activeName == '3') {
                    this.getTrans('YXXZ')
                } else if (this.activeName == '4') {
                    // this.getTrans('CJCZ')
                    this.getTrans('JFDH')
                } else if (this.activeName == '5') {
                    this.getTrans('YXJS')
                } else if (this.activeName == '6') {
                    this.getTrans('CJTX')
                } else if (this.activeName == '7') {
                    this.getTrans('CJCZ')
                } else if (this.activeName == '8') {
                    this.getTrans('BFCZ')
                } else if (this.activeName == '9') {
                    this.getTrans('KSTX')
                } else if (this.activeName == '10') {
                    this.getTrans('ZSCZ')
                }
            },
            immediate: true
        }
    },

    mounted() {
        const windowHeight = document.documentElement.clientHeight;
        this.realHeight = (Number(windowHeight)) * (1 - (100 / windowHeight))

        // if (browser().ios) {
        //    console.log('xy')
        // }
        window.addEventListener('resize', this.renderResize, false);

    },

    created: function () {
        this.endDate = this.defaultDate[0].format('YYYY-MM-DD')
        this.startDate = this.defaultDate[1].format('YYYY-MM-DD')
        // this.getTrans();
        this.activeName = '0'
        this.dropMenuName = this.$t('uc.finance.xx124')

    },


    methods: {

        handleMenu(e) {
            console.log('sdf ' + e);
            this.selectItem = Number(e)
            this.activeName = e
            if (e == '0') {
                this.dropMenuName = this.$t('uc.finance.xx124')
            } else if (e == '1') {
                this.dropMenuName = this.$t('uc.finance.xx125')
            } else if (e == '2') {
                this.dropMenuName = this.$t('uc.finance.xx126')
            } else if (e == '3') {
                this.dropMenuName = this.$t('uc.finance.xx148')
            } else if (e == '4') {
                this.dropMenuName = this.$t('uc.finance.xx206')
            } else if (e == '5') {
                this.dropMenuName = this.$t('uc.finance.xx174')
            } else if (e == '6') {
                this.dropMenuName = this.$t('uc.finance.xx223')
            } else if (e == '7') {
                this.dropMenuName = this.$t('uc.finance.xx51')
            } else if (e == '8') {
                this.dropMenuName = this.$t('uc.finance.xx26')
            } else if (e == '9') {
                this.dropMenuName = this.$t('uc.finance.xx27')
            } else if (e == '10') {
                this.dropMenuName = this.$t('uc.finance.xx227')
            }

        },

        handlewith(xy) {
            let xx = ''
            switch (xy) {
                case 0:
                    xx = this.$t('uc.finance.xx188')
                    break;
                case 1:
                    xx = this.$t('uc.finance.xx187')
                    break;
                case 2:
                    xx = this.$t('uc.finance.xx185')
                    break;
                case 3:
                    xx = this.$t('uc.finance.xx178')
                    break;
                case 4:
                    xx = this.$t('uc.finance.xx181')
                    break;
                case 5:
                    xx = this.$t('uc.finance.xx179')
                    break;
                case 6:
                    xx = this.$t('uc.finance.xx182')
                    break;
                default:
                    break;
            }

            return xx
        },
        handledep(xy) {
            let xx = ''
            switch (xy) {
                case 0:
                    xx = this.$t('uc.finance.xx188')
                    break;
                case 1:
                    xx = this.$t('uc.finance.xx187')
                    break;
                case 2:
                    xx = this.$t('uc.finance.xx186')
                    break;
                case 3:
                    xx = this.$t('uc.finance.xx180')
                    break;
                case 4:
                    xx = this.$t('uc.finance.xx181')
                    break;
                case 5:
                    xx = this.$t('uc.finance.xx179')
                    break;
                case 6:
                    xx = this.$t('uc.finance.xx182')
                    break;
                default:
                    break;
            }

            return xx
        },
        closebtm() {
            this.value8 = false
            this.currentPage = 1
            this.isReachBottom = false
            if (this.activeName == '0') {
                this.getTrans()
            } else if (this.activeName == '1') {
                this.getDeposits()
            } else if (this.activeName == '2') {
                this.getWithDraw()
            } else if (this.activeName == '3') {
                this.getTrans('YXXZ')
            } else if (this.activeName == '4') {
                this.getTrans('JFDH')
            } else if (this.activeName == '5') {
                this.getTrans('YXJS')
            } else if (this.activeName == '6') {
                this.getTrans('CJTX')
            } else if (this.activeName == '7') {
                this.getTrans('CJCZ')
            } else if (this.activeName == '8') {
                this.getTrans('BFCZ')
            } else if (this.activeName == '9') {
                this.getTrans('KSTX')
            } else if (this.activeName == '10') {
                this.getTrans('ZSCZ')
            }
        },

        parseStatus(status) {
            let xyz = ''
            switch (status) {
                case 0:
                    xyz = this.$t('uc.finance.xx63')
                    break;
                case 1:
                    xyz = this.$t('uc.finance.xx5')
                    break;
                case 2:
                    xyz = this.$t('uc.finance.xx6')
                    break;
                default:
                    break;
            }

            return xyz
        },


        paserReceipt(code) {
            let xyz = ''
            switch (code) {
                case 'CS':
                    xyz = this.$t('uc.finance.xx147')
                    break;
                case 'CJTX':
                    xyz = this.$t('uc.finance.xx223')
                    break;
                case 'CGMY':
                    xyz = this.$t('uc.finance.xx165')
                    break
                case 'CAMTX':
                    xyz = this.$t('uc.finance.xx62')
                    break
                case 'ZCHB':
                    xyz = this.$t('uc.finance.xx166')
                    break
                case 'XTDZ':
                    xyz = this.$t('uc.finance.xx167')
                    break
                case 'YXMD':
                    xyz = this.$t('uc.finance.xx168')
                    break
                case 'YXMR':
                    xyz = this.$t('uc.finance.xx169')
                    break
                case 'LJJJ':
                    xyz = this.$t('uc.finance.xx170')
                    break
                case 'YXJJ':
                    xyz = this.$t('uc.finance.xx171')
                    break
                case 'QXTZ':
                    xyz = this.$t('uc.finance.xx172')
                    break
                case 'HGTZ':
                    xyz = this.$t('uc.finance.xx173')
                    break
                case 'YXJS':
                    xyz = this.$t('uc.finance.xx174')
                    break
                case 'YXXZ':
                    xyz = this.$t('uc.finance.xx175')
                    break
                case 'TXSB':
                    xyz = this.$t('uc.finance.xx176')
                    break
                case 'HYZZ':
                    xyz = this.$t('uc.finance.xx177')
                    break
                case 'KSTX':
                    xyz = this.$t('uc.finance.xx178')
                    break
                case 'FSCZ':
                    xyz = this.$t('uc.finance.xx179')
                    break
                case 'BFCZ':
                    xyz = this.$t('uc.finance.xx180')
                    break
                case 'CJCZ':
                    xyz = this.$t('uc.finance.xx181')
                    break
                case 'CAMCZ':
                    xyz = this.$t('uc.finance.xx182')
                    break
                case 'HOCZ':
                    xyz = this.$t('uc.finance.xx183')
                    break
                case 'UDCZ':
                case 'SDCZ':
                    xyz = this.$t('uc.finance.xx186')
                    break
                case 'SDTX':
                    xyz = this.$t('uc.finance.xx185')
                    break
                case 'HOTX':
                    xyz = this.$t('uc.finance.xx187')
                    break
                case 'UDTX':
                    xyz = this.$t('uc.finance.xx188')
                    break
                case 'JFDH':
                    xyz = this.$t('common.exchange')
                    break
                case 'FB_BET':
                    xyz = this.$t('uc.finance.fbbet')
                    break
                case 'JLYJTH':
                    xyz = this.$t('uc.finance.xx224')
                    break
                case 'JLYJ':
                    xyz = this.$t('uc.finance.xx225')
                    break
                case 'FB_WIN':
                    xyz = this.$t('uc.finance.xx226')
                    break
                case 'ZSCZ':
                    xyz = this.$t('uc.finance.xx227')
                    break
                default:
                    break;
            }

            return xyz
        },

        goback() {
            //获取个人安全信息
            this.$router.back(-1)

        },

        onChange(date) {

            if (date[0] != null && date[1] != null) {
                this.currentPage = 1
                this.isReachBottom = false
                this.startDate = date[0].format('YYYY-MM-DD')

                this.endDate = date[1].format('YYYY-MM-DD')

                if (this.activeName == '0') {
                    this.getTrans()
                } else if (this.activeName == '1') {
                    this.getDeposits()
                } else if (this.activeName == '2') {
                    this.getWithDraw()
                } else if (this.activeName == '3') {
                    this.getTrans('YXXZ')
                } else if (this.activeName == '4') {
                    this.getTrans('JFDH')
                } else if (this.activeName == '5') {
                    this.getTrans('YXJS')
                } else if (this.activeName == '6') {
                    this.getTrans('CJTX')
                } else if (this.activeName == '7') {
                    this.getTrans('CJCZ')
                } else if (this.activeName == '8') {
                    this.getTrans('BFCZ')
                } else if (this.activeName == '9') {
                    this.getTrans('KSTX')
                } else if (this.activeName == '10') {
                    this.getTrans('ZSCZ')
                }

            }

        },

        datepicke() {
            this.calendarShow = !this.calendarShow
        },

        otherpicke() {
            this.value8 = true
        },

        nextPage() {
            if (this.isReachBottom == true) {
                return
            }
            this.currentPage += 1
            if (this.activeName == '0') {
                this.getTrans(null)
            } else if (this.activeName == '1') {
                this.getDeposits()
            } else if (this.activeName == '2') {
                this.getWithDraw()
            } else if (this.activeName == '3') {
                this.getTrans('YXXZ')
            } else if (this.activeName == '4') {
                // this.getTrans('CJCZ')
                this.getTrans('JFDH')
            } else if (this.activeName == '5') {
                this.getTrans('YXJS')
            } else if (this.activeName == '6') {
                this.getTrans('CJTX')
            } else if (this.activeName == '7') {
                this.getTrans('CJCZ')
            } else if (this.activeName == '8') {
                this.getTrans('BFCZ')
            } else if (this.activeName == '9') {
                this.getTrans('KSTX')
            } else if (this.activeName == '10') {
                this.getTrans('ZSCZ')
            }
        },

        // pointsList1

        getPoints1() {
            let that = this
            this.eloading = true
            let params = {}
            params.pageNum = this.currentPage
            if (this.currentPage == 1) {
                this.datalist = []
            }
            params.pageSize = 20
            params.startTime = this.defaultDate[1].format('YYYY-MM-DD HH:mm:ss')
            params.endTime = this.defaultDate[0].format('YYYY-MM-DD HH:mm:ss')

            if (this.commonstatus == '1') {
                params.endTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
                params.startTime = dayjs().add(-1, 'day').format('YYYY-MM-DD HH:mm:ss')
            } else if (this.commonstatus == '2') {
                params.endTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
                params.startTime = dayjs().format('YYYY-MM-DD HH:mm:ss')

            } else if (this.commonstatus == '3') {
                params.endTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
                params.startTime = dayjs().add(-7, 'day').format('YYYY-MM-DD HH:mm:ss')

            } else if (this.commonstatus == '4') {
                params.endTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
                params.startTime = dayjs().add(-30, 'day').format('YYYY-MM-DD HH:mm:ss')

            }


            this.$http.post(this.host + this.api.uc.pointsList1, params).then(response => {
                let resp = response.body;
                that.eloading = false
                if (resp.code == "0000") {
                    let xyz = resp.data.list

                    if (xyz.length < 10) {
                        //   this.$Notice.info({
                        //   title: that.$t("uc.finance.xx14"),

                        // });

                        that.isReachBottom = true
                    }

                    xyz.forEach(element => {
                        that.datalist.push(element)
                    });

                    if (that.datalist.length <= 0) {
                        that.showxxy = true
                    } else
                        that.showxxy = false
                    that.total = resp.data.total
                } else
                    that.$Notice.error({
                        title: that.$t("common.tip"),
                        desc: resp.message
                    });
            });

        },


        getPoints() {
            let that = this
            this.eloading = true
            let params = {}
            params.pageNum = this.currentPage
            if (this.currentPage == 1) {
                this.datalist = []
            }
            params.pageSize = 20
            params.startTime = this.defaultDate[1].format('YYYY-MM-DD HH:mm:ss')
            params.endTime = this.defaultDate[0].format('YYYY-MM-DD HH:mm:ss')

            if (this.commonstatus == '1') {
                params.endTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
                params.startTime = dayjs().add(-1, 'day').format('YYYY-MM-DD HH:mm:ss')
            } else if (this.commonstatus == '2') {
                params.endTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
                params.startTime = dayjs().format('YYYY-MM-DD HH:mm:ss')

            } else if (this.commonstatus == '3') {
                params.endTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
                params.startTime = dayjs().add(-7, 'day').format('YYYY-MM-DD HH:mm:ss')

            } else if (this.commonstatus == '4') {
                params.endTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
                params.startTime = dayjs().add(-30, 'day').format('YYYY-MM-DD HH:mm:ss')

            }


            this.$http.post(this.host + this.api.uc.pointsList, params).then(response => {
                let resp = response.body;
                that.eloading = false
                if (resp.code == "0000") {
                    let xyz = resp.data.list

                    if (xyz.length < 10) {
                        //   this.$Notice.info({
                        //   title: that.$t("uc.finance.xx14"),

                        // });

                        that.isReachBottom = true
                    }

                    xyz.forEach(element => {
                        that.datalist.push(element)
                    });

                    if (that.datalist.length <= 0) {
                        that.showxxy = true
                    } else
                        that.showxxy = false
                    that.total = resp.data.total
                } else
                    that.$Notice.error({
                        title: that.$t("common.tip"),
                        desc: resp.message
                    });
            });

        },

        getTrans(code) {
            let that = this
            this.eloading = true
            let params = {}
            params.pageNum = this.currentPage
            if (this.currentPage == 1) {
                this.datalist = []
            }
            params.pageSize = 20
            params.startTime = this.startDate
            params.endTime = this.endDate
            params.tranCode = code


            if (this.commonstatus == '1') {
                params.endTime = dayjs().format('YYYY-MM-DD')
                params.startTime = dayjs().add(-1, 'day').format('YYYY-MM-DD')
            } else if (this.commonstatus == '2') {
                params.endTime = dayjs().format('YYYY-MM-DD')
                params.startTime = dayjs().format('YYYY-MM-DD')

            } else if (this.commonstatus == '3') {
                params.endTime = dayjs().format('YYYY-MM-DD')
                params.startTime = dayjs().add(-7, 'day').format('YYYY-MM-DD')

            } else if (this.commonstatus == '4') {
                params.endTime = dayjs().format('YYYY-MM-DD')
                params.startTime = dayjs().add(-30, 'day').format('YYYY-MM-DD')

            }

            this.$http.post(this.host + this.api.uc.memberactivity, params).then(response => {
                let resp = response.body;
                that.eloading = false
                if (resp.code == "0000") {
                    let xyz = resp.data.list

                    if (xyz.length < 10) {
                        //   this.$Notice.info({
                        //   title: that.$t("uc.finance.xx14"),

                        // });

                        that.isReachBottom = true
                    }

                    xyz.forEach(element => {
                        that.datalist.push(element)
                    });

                    if (that.datalist.length <= 0) {
                        that.showxxy = true
                    } else
                        that.showxxy = false
                    that.total = resp.data.total
                } else
                    that.$Notice.error({
                        title: that.$t("common.tip"),
                        desc: resp.message
                    });
            });
        },

        getDeposits() {
            let that = this
            let params = {}
            this.eloading = true
            params.pageNum = this.currentPage
            if (this.currentPage == 1) {
                this.datalist = []
            }
            params.pageSize = 20
            params.beginDateStr = this.startDate
            params.endDateStr = this.endDate
            //  0支付中；1完成支付；2支付失败
            if (this.rechargestatus == '4') {
                params.endDateStr = dayjs().format('YYYY-MM-DD')
                params.beginDateStr = dayjs().add(-1, 'day').format('YYYY-MM-DD')
            } else if (this.rechargestatus == '5') {
                params.endDateStr = dayjs().format('YYYY-MM-DD')
                params.beginDateStr = dayjs().format('YYYY-MM-DD')

            } else if (this.rechargestatus == '6') {
                params.endDateStr = dayjs().format('YYYY-MM-DD')
                params.beginDateStr = dayjs().add(-7, 'day').format('YYYY-MM-DD')

            } else if (this.rechargestatus == '7') {
                params.endDateStr = dayjs().format('YYYY-MM-DD')
                params.beginDateStr = dayjs().add(-30, 'day').format('YYYY-MM-DD')

            } else if (this.rechargestatus == '3') {
                console.log('');
            } else
                params.status = this.rechargestatus

            this.$http.post(this.host + this.api.uc.getDepositList, params).then(response => {
                let resp = response.body;
                that.eloading = false
                if (resp.code == "0000") {
                    let xyz = resp.data.list

                    if (xyz.length < 10) {
                        //   this.$Notice.info({
                        //   title: that.$t("uc.finance.xx14"),

                        // });

                        that.isReachBottom = true
                    }

                    xyz.forEach(element => {
                        that.datalist.push(element)
                    });
                    if (that.datalist.length <= 0) {
                        that.showxxy = true
                    } else
                        that.showxxy = ''
                    that.total = resp.data.total
                } else
                    that.$Notice.error({
                        title: that.$t("common.tip"),
                        desc: resp.message
                    });
            });
        },

        getWithDraw() {
            let that = this
            let params = {}
            this.eloading = true
            params.pageNum = this.currentPage
            if (this.currentPage == 1) {
                this.datalist = []
            }
            params.pageSize = 20
            params.beginDateStr = this.startDate
            params.endDateStr = this.endDate
            //  0-申请中,1-已确认,2-已驳回,3-支付失败


            if (this.withdrawstatus == '4') {
                params.endDateStr = dayjs().format('YYYY-MM-DD')
                params.beginDateStr = dayjs().add(-1, 'day').format('YYYY-MM-DD')
            } else if (this.withdrawstatus == '5') {
                params.endDateStr = dayjs().format('YYYY-MM-DD')
                params.beginDateStr = dayjs().format('YYYY-MM-DD')

            } else if (this.withdrawstatus == '6') {
                params.endDateStr = dayjs().format('YYYY-MM-DD')
                params.beginDateStr = dayjs().add(-7, 'day').format('YYYY-MM-DD')

            } else if (this.withdrawstatus == '7') {
                params.endDateStr = dayjs().format('YYYY-MM-DD')
                params.beginDateStr = dayjs().add(-30, 'day').format('YYYY-MM-DD')

            } else if (this.withdrawstatus == '4') {
                console.log('');
            } else
                params.status = this.withdrawstatus

            this.$http.post(this.host + this.api.uc.getWithdrawList, params).then(response => {
                let resp = response.body;
                that.eloading = false
                if (resp.code == "0000") {
                    let xyz = resp.data.list

                    if (xyz.length < 10) {
                        //   this.$Notice.info({
                        //   title: that.$t("uc.finance.xx14"),

                        // })

                        that.isReachBottom = true
                    }

                    xyz.forEach(element => {
                        that.datalist.push(element)
                    });
                    if (that.datalist.length <= 0) {
                        that.showxxy = true
                    } else
                        that.showxxy = ''
                    that.total = resp.data.total
                } else
                    that.$Notice.error({
                        title: that.$t("common.tip"),
                        desc: resp.message
                    });
            });
        },

    }


}
</script>

<style lang="scss" scoped>

// .login_form {
//   background: #0b1520;
//   height: 56rem;
//   position: relative;
//   overflow: hidden;
//   }

.login_form {
    background-color: $color-background;
    height: $full-page;
    position: relative;
    overflow: hidden;
    text-align: center;
}

.body_box1 {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    color: #b1bad3;
    height: auto;

}

/deep/ .ivu-card-bordered {
    border: none;
}

.box {
    position: fixed;
    top: 0;
    display: flex;
    height: 50px;
    padding: 10px;
    align-items: center;
    color: $color-text-tag;
    z-index: 200;
    width: 100%;
    justify-content: space-between;
}

.head_box {
    width: 100%;

}

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    z-index: 200;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    background: url(../assets/logo/barBottom.png);
    transform: rotate(0.5turn);
}

/deep/ .ivu-tabs-bar {
    border-bottom: 0px solid #dcdee2;
    margin-bottom: 10px;
    background-color: #1B2C38;
}


/deep/ .ivu-tabs-nav-container {
    font-size: 12px;
}

/deep/ .ivu-tabs-nav .ivu-tabs-tab-active {
    color: #d2b965;
}


/deep/ .ivu-tabs-tab {
    color: #b1bad3;
    padding: 12px 16px
}


/deep/ .ivu-tabs-ink-bar {
    background-color: #d2b965;
}

/deep/ .ivu-card-body {
    padding: 5px 10px;

}

.active,.notactive {
    background-color: #1A2B37;
    height: 69px;
    padding: 12px;
    border-radius: 6px;
    width: 96%;
    margin: 0 auto 10px;
    box-sizing: border-box;
}


.content{
    margin-top: 55px;
}


</style>



