<script>
export default {
    name: "walletSetup",
    data() {
        const validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('new.emailcodetip')));
            } else {
                if (this.formValidate.jbPassword !== '') {
                    // 对第二个密码框单独验证
                    this.$refs.formValidate.validateField('jbPasswordSure');
                }
                callback();
            }
        };
        const validatePassCheck = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('new.emailcodetip')));
            } else if (value !== this.formValidate.jbPassword) {
                callback(new Error(this.$t('new.pwdMismatch')));
            } else {
                callback();
            }
        };
        return {
            loading: false,
            userInfo: null,
            formValidate: {
                realName: '',
                birthday: '',
                jbPassword: '',
                jbPasswordSure: ''
            },
            ruleValidate: {
                // realName: [
                //     {required: true, message: '姓名不可为空', trigger: 'blur'}
                // ],
                // birthday: [
                //     {required: true, message: '姓名不可为空', trigger: 'blur'}
                // ],
                jbPassword: [
                    {validator: validatePass, trigger: 'blur'}
                ],
                jbPasswordSure: [
                    {validator: validatePassCheck, trigger: 'blur'}
                ],
            }
        }
    },
    mounted() {
        if (localStorage.getItem('MEMBER')) {
            this.userInfo = JSON.parse(localStorage.getItem('MEMBER'))
            console.log(this.userInfo)
        }
    },
    methods: {
        getMemberInfo() {
            let self = this;
            this.$http
                .post(this.host + this.api.uc.memberInfo)
                .then(response => {
                    var resp = response.body;
                    if (resp.code == "0000") {
                        localStorage.setItem("MEMBER", JSON.stringify(null));
                        self.$store.commit("setMember", resp.data);
                        self.$router.replace({path: '/'})
                    } else {
                        self.$Message.error(resp.mesg);
                    }
                });
        },
        setWalletPwd(name) {
            this.loading = true
            this.$refs[name].validate((valid) => {
                if (valid) {
                    // this.$Message.success('ok');
                } else {
                    this.$Message.error(this.$t('new.fillPrompt'));
                }
            })
            let param1 = {
                jbPassword: this.formValidate.jbPassword,
                // jbPasswordSure: ''
            }
            /*因接口调整  需要将界面模块拆分成两个模块提交*/
            let param = {
                realName: this.formValidate.realName,
                birthday: this.formValidate.birthday,
            }
            this.$http.post(this.host + this.api.uc.updatePersonalInfo, param).then(response => {
                const resp = response.body;
                if (resp.code == '0000') {
                    this.setJbPassword(param1)
                } else {
                    this.$Message.error(resp.mesg);
                    this.loading = false
                }
            });
        },
        setJbPassword(param) {
            this.$http.post(this.host + '/user/setJbPassword', param).then(response => {
                const resp = response.body;
                console.log(resp)
                if (resp.code == '0000') {
                    this.getMemberInfo()
                    this.loading = false
                } else {
                    this.$Message.error(resp.mesg);
                    this.loading = false
                }
            });
        },

        goBack() {
            this.$router.go(-1)
        }
    }
}
</script>

<template>
    <div class="walletSet">
        <div class="top">
            <!--            <div class="logo">-->
            <img src="../assets/logo/logojb1.png" alt="">
            <!--            </div>-->
            <div class="withdraw" @click="goBack">{{ $t('new.logout') }}</div>
        </div>
        <div class="line">
            <div class="left"></div>
            <div class="right"></div>
        </div>
        <div class="txt">
            <div class="txt1">{{ $t('new.confirmDetails') }}</div>
            <div class="txt2">{{ $t('new.fillDetails') }}</div>
        </div>
        <div class="con">
            <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
                <label class="txt-label" for="realName">{{ $t('new.realNameLabel') }}</label>
                <FormItem label="" prop="realName">
                    <Input :disabled="loading" v-model="formValidate.realName"
                           :placeholder="$t('new.realNameLabel')"></Input>
                </FormItem>
                <label class="txt-label" for="birthday">{{ $t('new.birthdayLabel') }}</label>
                <FormItem label="" prop="birthday">
                    <Input :disabled="loading" v-model="formValidate.birthday"
                           :placeholder="$t('new.birthdayLabel')"></Input>
                </FormItem>
                <label class="txt-label" for="jbPassword">{{ $t('new.pwdLabel') }}</label>
                <FormItem label="" prop="jbPassword">
                    <Input :disabled="loading" v-model="formValidate.jbPassword"
                           :placeholder="$t('new.pwdLabel')"></Input>
                </FormItem>
                <label class="txt-label" for="jbPasswordSure">{{ $t('new.confirmPwdLabel') }}</label>
                <FormItem label="" prop="jbPasswordSure">
                    <Input :disabled="loading" v-model="formValidate.jbPasswordSure"
                           :placeholder="$t('new.confirmPwdLabel')"></Input>
                </FormItem>
                <FormItem>
                    <!--                    <Button type="primary" @click="handleSubmit('formValidate')">Submit</Button>-->
                    <!--                    <Button @click="handleReset('formValidate')" style="margin-left: 8px">保存然后继续</Button>-->
                    <Button class="btn" type="primary" @click="setWalletPwd('formValidate')">
                        {{ $t('new.saveContinue') }}
                    </Button>
                </FormItem>
            </Form>

        </div>
        <!--        <div class="loading" v-if="loading">-->
        <!--            <Spin :color="'red'" :size="'large'"/>-->
        <!--        </div>-->
    </div>
</template>

<style scoped lang="scss">
.walletSet {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .top {
        background-color: #1A2C38;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        //.logo {
        //    display: flex;
        //    align-items: center;

        & > img {
            height: 70%;
        }

        //}

        .withdraw {
            display: flex;
            align-items: center;
            padding: 10px 20px;
            color: #fff;
            font-weight: bold;
        }
    }

    .txt {
        width: 92%;
        margin: 20px auto;

        .txt1 {
            color: #fff;
            font-weight: bold;
            font-size: 24px;
        }

        .txt2 {
            color: #b1bad3;
            font-size: 14px;
        }
    }
}

.txt-label {
    display: block;
    padding: 6px 12px;
    color: #fff;
    font-weight: bold;
}

.btn {
    width: 100%;
    margin: 40px auto;
    height: 45px;
}

.line {
    width: 96%;
    height: 3px;
    margin: 3px auto;
    border-radius: 3px;
    background-color: #00E700;
}

/deep/ .ivu-input {
    height: 40px;
}

/deep/ .ivu-form-item {
    margin-bottom: 18px;
}

/deep/ .ivu-form-item-content {
    width: 94%;
    margin: auto !important;
}
</style>
