<template>
    <div class="login_form">
        <div>
            <HeaderNav></HeaderNav>
        </div>
        <div class="browse-box">
            <div class="con">
                <div class="search-wrap" style="display: flex;align-items: center">
                    <img class="icon" src="@/assets/logo/search.svg" alt="Ting SVG"/>
                    <Input v-model="search" :placeholder="$t('new.searchGamePlaceholder')" clearable/>
                </div>
                <div class="box-1 box-1-top box">
                    <div class="collect bgl" @click="goPath(24,true)"> {{ $t('new.recharge') }}</div>
                    <div class="collect bgr" @click="goPath(25,true)"> {{ $t('new.withdraw') }}</div>
                </div>
                <template v-if="!showSearch">
                    <div class="box-1 box">
                        <div class="collect" @click="goPath(1,true)"><img class="icon" src="@/assets/logo/star.svg"
                                                                          alt="Ting SVG"/> {{ $t('new.favorites') }}
                        </div>
                        <div class="collect" @click="goPath(2,true)"><img class="icon" src="@/assets/logo/history.svg"
                                                                          alt="Ting SVG"/>
                            {{ $t('new.recentGames') }}
                        </div>
                        <div class="collect" @click="goPath(3)"><img class="icon" src="@/assets/logo/vip.svg"
                                                                     alt="Ting SVG"/> {{ $t('new.vipClub') }}
                        </div>
                        <div class="collect" @click="goPath(4)"><img class="icon" src="@/assets/logo/league.svg"
                                                                     alt="Ting SVG"/> {{ $t('new.alliancePlan') }}
                        </div>
                    </div>
                    <div class="box-2 box">
                        <div class="txt-title">{{ $t('new.gamesTitle') }}</div>
                        <div class="collect" @click="goPath(5)"><img class="icon" src="@/assets/logo/hot.svg"
                                                                     alt="Ting SVG"/> {{ $t('new.magoRecommendation') }}
                        </div>
                        <div class="collect" @click="goPath(6)"><img class="icon" src="@/assets/logo/slots.svg"
                                                                     alt="Ting SVG"/> {{ $t('new.slots') }}
                        </div>
                        <div class="collect" @click="goPath(7)"><img class="icon" src="@/assets/logo/live.svg"
                                                                     alt="Ting SVG"/> {{ $t('new.liveCasino') }}
                        </div>
                        <div class="collect" @click="goPath(22)"><img class="icon" style="height: 16px" src="@/assets/logo/fishing.png"
                                                                      alt="Ting SVG"/>
                            {{ $t('new.fishing') }}
                        </div>
                        <div class="collect" @click="goPath(21)"><img class="icon" src="@/assets/logo/table.svg"
                                                                      alt="Ting SVG"/>
                            {{ $t('new.tableGames') }}
                        </div>
                        <div class="collect" @click="goPath(8)"><img class="icon" src="@/assets/logo/sgame.svg"
                                                                     alt="Ting SVG"/>
                            {{ $t('new.gameShow') }}
                        </div>
                        <div class="collect" @click="goPath(23)"><img class="icon" src="@/assets/logo/bingo.svg"
                                                                      alt="Ting SVG"/>
                            {{ $t('new.bingo') }}
                        </div>
                        <div class="collect" @click="goPath(9)"><img class="icon" src="@/assets/logo/newRelease.svg"
                                                                     alt="Ting SVG"/>
                            {{ $t('new.newGame') }}
                        </div>
                    </div>
                    <div class="box-3 box">
                        <div class="collect" @click="goPath(10)"><img class="icon" src="@/assets/logo/sports.svg"
                                                                      alt="Ting SVG"/> {{ $t('new.sports') }}
                        </div>
                    </div>
                    <div class="box-4 box">
                        <div class="collect" @click="goPath(11)"><img class="icon" src="@/assets/logo/gameProvider.svg"
                                                                      alt="Ting SVG"/> {{ $t('new.gameProvider') }}
                        </div>
                    </div>
                    <div class="box-5 box">
                        <div class="collect" @click="goPath(12)"><img class="icon" src="@/assets/logo/language.svg"
                                                                      alt="Ting SVG"/>
                            {{ $t('new.languageSelection') }}-
                            <div>{{ lang }}</div>
                        </div>
                        <!-- <div class="collect" @click="goPath(13)"><img class="icon" src="@/assets/logo/sponsor.svg"
                                                                      alt="Ting SVG"/> {{ $t('new.timezone') }}
                        </div> -->
                        <div class="collect" @click="goPath(14)"><img class="icon" src="@/assets/logo/support.svg"
                                                                      alt="Ting SVG"/>
                            {{ $t('new.onlineSupport') }}
                        </div>
                        <div class="collect" @click="goPath(15)"><img class="icon" src="@/assets/logo/myBet.svg"
                                                                      alt="Ting SVG"/> {{ $t('new.aboutUs') }}
                        </div>
                        <!-- <div class="collect" @click="goPath(16)"><img class="icon" src="@/assets/logo/blog.svg"
                                                                      alt="Ting SVG"/> {{ $t('new.antiMoneyLaundering') }}
                        </div>
                        <div class="collect" @click="goPath(17)"><img class="icon" src="@/assets/logo/rtp.svg"
                                                                      alt="Ting SVG"/> {{ $t('new.license') }}
                        </div>
                        <div class="collect" @click="goPath(18)"><img class="icon" src="@/assets/logo/responsible.svg"
                                                                      alt="Ting SVG"/> {{ $t('new.responsibleGambling') }}
                        </div>
                        <div class="collect" @click="goPath(19)"><img class="icon" src="@/assets/logo/serve.svg"
                                                                      alt="Ting SVG"/>
                            {{ $t('new.termsOfService') }}
                        </div> -->


                    </div>

                    <div class="box-6 box">
                        <div class="collect" @click="goPath(20)"><img class="icon" src="@/assets/logo/out.svg"
                            alt="Ting SVG"/> {{ $t('new.logout') }}
                        </div>
                    </div>
                </template>
                <!--                <router-view ref="search"></router-view>-->
                <Search v-else ref="search"></Search>
            </div>
        </div>
        <FundPwd :fundPwdPopup="fundPwdPopup"></FundPwd>
        <LanguagePopup ref="language"></LanguagePopup>
        <Main-tab-bar></Main-tab-bar>
    </div>
</template>

<script>
import MainTabBar from '../components/tabbar/MainTabBar'
// import {numberToCurrencyNo} from '@/config/index'
import moment from 'moment-timezone'
import Search from "@/views/search.vue";
import HeaderNav from "@/components/HeaderNav.vue";
import LanguagePopup from "@/components/LanguagePopup.vue";
import {debounce} from "@/config";
import FundPwd from "@/components/FundPwd.vue";

export default {
    components: {
        FundPwd,
        MainTabBar,
        HeaderNav,
        LanguagePopup,
        Search
    },

    data() {
        return {
            lang:'',
            fundPwdPopup: false,
            showSearch: false,
            search: null,
            tabList: [
                {type: 1, path: '', title: '收藏夹'},
            ]
        }
    },

    computed: {
        isLogin: function () {
            return this.$store.getters.isLogin;
        },
        userInfo: function () {
            return this.$store.getters.member || localStorage.getItem("MEMBER");
        },
        currentTime() {
            return moment.tz.guess();
        },
    },
    watch: {
        search(newVal, old) {
            console.log(newVal)
            if (newVal && newVal.trim() !== '') {
                this.showSearch = true
                this.onInputChange()
            } else {
                this.showSearch = false
            }
        },
        '$i18n.locale':{
            immediate: true, // 界面加载时立即执行一次
            handler(newVal,old){
                console.log( newVal )
                // if (newVal === 'en-US') this.lang = '英文'
                // if (newVal === 'zh-CN') this.lang = '中文'
                // if (newVal === 'vi-VN') this.lang = '越南文'
                // if (newVal === 'th-TH') this.lang = '泰文'
                // if (newVal === 'ja-JP') this.lang = '日文'
                // if (newVal === 'ko-KR') this.lang = '韩文'
                this.lang = this.$t('common.lang')
            }
        }
    },
    methods: {
        onInputChange: debounce(function () {
            console.log('触发搜索，输入内容为:', this.inputValue);
            this.searchFn();
        }, 2000),
        searchFn() {
            if (!this.search || this.search.trim() === '') return
            this.$refs.search.searchFn(this.search)
        },
        goPath(type, needLogin) {
            if (needLogin) {  //需要登陆判断
                if (!this.isLogin) {
                    this.$store.commit('showLoginFn', true)
                    return
                }
            }
            if (type === 1) {  //收藏
                this.$router.push({path: '/gameAll', query: {other: 1}});
            }
            if (type === 2) {  //历史记录
                this.$router.push({path: '/gameAll', query: {other: 2}});
            }
            if (type === 3) {
                this.$router.push({path: "/vip"})
            }
            if (type === 4) {
                this.$router.push({path: "/lianmeng"})
            }
            if (type === 5) this.$router.push({path: '/gameAll', query: {gameCategory: 1}});   //去全部界面
            if (type === 6) this.$router.push({path: '/gameAll', query: {gameCategory: 2}});
            if (type === 7) this.$router.push({path: '/gameAll', query: {gameCategory: 3}});
            if (type === 8) this.$router.push({path: '/gameAll', query: {gameCategory: 4}});
            if (type === 9) this.$router.push({path: '/gameAll', query: {gameCategory: 5}});
            if (type === 10) this.$router.push({path: '/sports'});
            if (type === 11) this.$router.push({path: '/provider'});
            if (type === 12) {
                this.$refs.language.modal1 = true
            }
            if (type === 14) {
                this.$router.push({path: '/tabCS'});
            }
            if([15,16,17,18,19].includes(type)){
                let text
                if(type === 15) text = '关于我们'
                if(type === 16) text = '反洗钱政策'
                if(type === 17) text = '许可证'
                if(type === 18) text = '负责任博彩'
                if(type === 19) text = '服务条款'
                this.$router.push({path:'/read',query:{text:text}})
            }
            if (type === 20) this.logout()
            if (type === 22) this.$router.push({path: '/gameAll', query: {gameCategory: 6}});  //捕鱼
            if (type === 21) this.$router.push({path: '/gameAll', query: {gameCategory: 7}});  //桌面
            if (type === 23) this.$router.push({path: '/gameAll', query: {gameCategory: 8}});  //bingo
            if (type === 24 || type === 25) {
                if (this.userInfo && !this.userInfo.jbPassword) {
                    // console.log( this.userInfo , this.userInfo.jbPassword )
                    this.fundPwdPopup = true
                } else {
                    if (type === 24) this.$router.push({path: '/Recharge'});  //存款
                    if (type === 25) this.$router.push({path: '/Withdraw'});  //提款
                }
            }
        },
        gotoLogin() {
            this.$router.push("/login");
        },
        language() {
            this.modal1 = true
        },
        logout() {
            if(!this.isLogin) return
            let self = this;
            this.$http
                .post(self.host + this.api.uc.toppromotionmonth)
                .then(response => {
                    var resp = response.body;
                    if (resp.code == "0000") {
                        self.$Message.success(resp.mesg);
                        this.$store.commit("setMember", null);
                        localStorage.setItem("MEMBER", JSON.stringify(null));

                        localStorage.setItem("TOKEN", null);
                        localStorage.removeItem("USERKEY", null);

                        this.$router.replace("/");
                    } else {
                        self.$Message.error(resp.mesg);
                        // this.$Message.error(this.$t('common.logintip'));
                    }
                });
        },

        beforeunloadHandler() {
            if (!this.isLogin) {
                console.log('temp');
            } else {
                // this.getMemberInfo();
                this.getMember();

            }

            if (this.$store.getters.lang == 'zh-CN') {
                this.currentLang = this.$t('uc.regist.china')
            } else if (this.$store.getters.lang == 'zh-HK') {
                this.currentLang = this.$t('uc.regist.hk')
            } else if (this.$store.getters.lang == 'en-US') {
                this.currentLang = this.$t('uc.regist.uk')
            } else if (this.$store.getters.lang == 'ca-CB') {
                this.currentLang = this.$t('uc.regist.cambodia')
            } else if (this.$store.getters.lang == 'vi-VN') {
                this.currentLang = this.$t('uc.regist.vietnam')
            } else if (this.$store.getters.lang == 'th-TH') {
                this.currentLang = this.$t('uc.regist.thailand')
            } else if (this.$store.getters.lang == 'ja-JP') {
                this.currentLang = this.$t('uc.regist.japan')
            } else if (this.$store.getters.lang == 'ma-MA') {
                this.currentLang = this.$t('uc.regist.malaysia')
            } else if (this.$store.getters.lang == 'in-IN') {
                this.currentLang = this.$t('uc.regist.india')
            } else if (this.$store.getters.lang == 'ko-KR') {
                this.currentLang = this.$t('uc.regist.korea')
            }


            this.vertical = this.$store.getters.lang

        },
        cancel() {
            this.modal1 = false
        },
        aboutus() {
            this.$router.push('/aboutUS')
        },

        gotoReg() {
            this.$router.push("/MobileRegister");
        },
        changeItem(name) {
            if (name == '1') {
                this.$router.push("/Recharge");
            } else {
                this.$router.push('/Withdraw')
            }
        },

    }
}

</script>

<style scoped lang="scss">
.login_form {
    background: #0b1a24;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    min-height: calc(100vh - 68px);

    .browse-box {
        height: 100%;
        margin: 60px 3% 68px 3%;
        width: 94%;
        display: flex;
        flex-direction: column;


        .con {
            flex: 1;

            .search-wrap {
                margin: 16px 0 10px 0;
                width: 100%;
                border: 2px solid #304553;
                border-radius: 1000px;
                height: 37px;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 2px 0 rgba(0, 0, 0, .12);

                .icon {
                    width: 18px;
                    display: flex;
                    margin: 4px 18px;
                    align-items: center
                }

                .inp {
                    text-indent: 10px;
                    color: #cccccc;
                    min-width: 10%;
                    background-color: transparent;
                    border: none;
                    outline: none;
                }
            }

            .box {
                margin-top: 4px;
                border-radius: 5px;
                background-color: #1A2C38;
                color: #fff;
                font-weight: bold;
                font-size: 14px;

                .txt-title {
                    width: 96%;
                    margin-right: auto;
                    padding: 10px 0;
                    text-indent: 10px;
                    text-align: left;
                    font-size: 18px;
                    border-bottom: 1px solid #0E212E;
                }

                .collect {
                    display: flex;
                    justify-content: start;
                    padding: 10px 16px;
                    display: flex;
                    align-items: center;

                    .icon {
                        height: 14px;
                        margin-right: 10px;
                    }
                }
            }

            .box-1-top {
                margin: 14px 0;
                height: 60px;
                display: flex;
                justify-content: space-around;

                .bgl, .bgr {
                    border-radius: 2px;
                    width: 48%;
                    background-image: url("../assets/common/bgl.png");
                    background-repeat: no-repeat;
                    background-size: 100% 160%;
                    background-position: left center;
                    display: flex;
                    justify-content: end;
                }

                .bgr {
                    background-image: url("../assets/common/bgr.png");
                }
            }
        }

    }
}

/deep/ .ivu-input {
    background-color: transparent;
    border: none;
    //outline: none;
    color: #fff;
}

/deep/ .ivu-input:focus {
    //border-color: transparent;  /* 移除边框颜色 */
    box-shadow: none; /* 移除阴影 */
    //outline: none;              /* 移除默认的 `outline` 样式 */
}


</style>
