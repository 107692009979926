<template>
  <div class="withdraw">
    <div class="headBox">
      <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">
      <span style="font-size:20px; margin-left: 10%;">
        {{ $t('uc.finance.pickup') }}
      </span>
      <div style="display: flex;align-items: center;" @click="popUP">
        <!--                {{ $t('common.currencyType') }}-->
        <img :src='currenyImg' alt="" width="16" style="margin-right: 4px;">
        <span style="padding: 0 6px;font-size: 12px">{{ vertical }}</span>
        <Icon type="ios-arrow-down"/>
      </div>
    </div>
    <div class="body">
      <div
          style="display: flex;align-items: center;width: 98%;margin-left: 1%;padding: 5px 10px;justify-content: space-evenly;"
          v-if="vertical == 'USD'">
        <div v-if="ishui == true" class="butt0" @click="touchHun">
          <span>HUIONE</span>
        </div>
        <div v-else class="butt1" @click="touchHun">
          <span>HUIONE</span>
        </div>
        <div v-if="iscam == true" class="butt0" @click="touchCam">
          <span>CAMPAY</span>
        </div>
        <div v-else class="butt1" @click="touchCam">
          <span>CAMPAY</span>
        </div>

      </div>
      <div style="width: 95%;margin-left: 3%;padding: 5px 10px;" v-else-if="vertical == 'USDT'">
        <div style="display: flex;justify-content: space-between">
          <p style="font-size: 14px;color: #fff;text-align: left">{{ $t('uc.finance.personalassets') }}: TRC20</p>
          <div class="celllall12">
            <span style=" margin-left: 10px;color: #fff">{{ $t('uc.finance.tradetail') }}</span>
            <span style=" margin-left: 10px; color: #fff;">{{ selfamount || '0.00' }}</span>
          </div>
        </div>
      </div>
      <div v-else-if="vertical == 'VND'">
        <div class="bill_box">
          <div class="notice">
            <Icon type="ios-alert-outline"/>
            暂无该货币的提款渠道
          </div>
        </div>
      </div>
      <div v-else-if="vertical == 'CNY'">
        <div class="bill_box">
          <div class="notice">
            <Icon type="ios-alert-outline"/>
            暂无该货币的提款渠道
          </div>
        </div>
      </div>
      <div v-else-if="vertical == 'IDR'">
        <div class="bill_box">
          <div class="notice">
            <Icon type="ios-alert-outline"/>
            暂无该货币的提款渠道
          </div>
        </div>
      </div>
      <div v-else-if="vertical == 'JPY'">
        <div class="bill_box">
          <div class="notice">
            <Icon type="ios-alert-outline"/>
            暂无该货币的提款渠道
          </div>
        </div>
      </div>
      <div v-else-if="vertical == 'KRW'">
        <div class="bill_box">
          <div class="notice">
            <Icon type="ios-alert-outline"/>
            暂无该货币的提款渠道
          </div>
        </div>
      </div>
      <div v-else-if="vertical == 'THB'">
        <div class="bill_box">
          <div class="notice">
            <Icon type="ios-alert-outline"/>
            暂无该货币的提款渠道
          </div>
        </div>
      </div>
    </div>
    <div class="bill_box">
      <section style="margin-top:20px;" v-if="totalRadio == 'USDT'">
        <div
            style="
                    border: 1px solid #fff;
                    margin-left: 3%; align-items: center;color: #fff; width: 95%;
                    height: auto;background-color:#1B2C38;padding: 10px 0;
                    border-radius: 4px;display: flex;justify-content: center;"
            @click="gotoUSDT">
          <div style="display: flex;justify-items: center;">
            {{ '+' }}{{ $t('uc.finance.xx94') }}
          </div>
        </div>
        <div class="celllall4">
          <Input v-model="withdrawAmount" type="text" @on-change="onKeyYHKNameInput"
                 style="width:95%;margin-left: 3%;border: 1px solid #fff;border-radius: 4px" size="large" clearable
                 :placeholder="$t('uc.finance.invitingmining')"/>
        </div>
        <Scroll style="width: 95%;" height="200">
          <!--          <div dis-hover v-for="(item, index) in USDTCards" :key="index" style="margin-bottom: 12px"-->
          <!--               @click="getActiveUSDTBank(item)">-->
          <!--            <div class="bankcardActive" v-if="item.active == true">-->
          <!--              <img src="../assets/logo/usdt.png" width="30" alt="">-->
          <!--              <span>{{ item.walletAddress }}</span>-->

          <!--            </div>-->
          <!--            <div class="bankcard" v-else>-->
          <!--              <img src="../assets/logo/usdt.png" width="30" alt="">-->
          <!--              <span>{{ item.walletAddress }}</span>-->
          <!--            </div>-->
          <!--          </div>-->
          <Select v-model="inputAddress" style="width:100%;height: 40px">
            <Option v-for="item in USDTCards" :value="item.walletAddress" :key="item.walletAddress">
              {{ item.walletAddress }}
            </Option>
          </Select>
        </Scroll>


        <div style="margin-top:40px;">
          <Button
              style="width:60%; height: 40px; background-color: #1574E2; font-size: 16px; border: none;  color: #fff; border-radius: 100px;"
              @click="firstModel">{{ $t('uc.forget.save') }}
          </Button>
        </div>

      </section>
<!--      <section v-else-if="totalRadio == 'HUIONE'">-->
<!--        <div class="celllall4">-->
<!--          <Input v-model="inputAddress1" type="text" style="width:96%;margin-left: 2%;" size="large" clearable-->
<!--                 :placeholder="$t('uc.forget.addresstip221')"/>-->
<!--        </div>-->
<!--        <div class="celllall4">-->
<!--          <Input v-model="payname" type="text" style="width:96%;margin-left: 2%;" size="large" clearable-->
<!--                 :placeholder="$t('uc.forget.addresstip321')"/>-->
<!--        </div>-->

<!--        <div class="celllall4">-->

<!--          &lt;!&ndash; <input type="text" @change="onKeyYHKNameInput" v-model="withdrawAmount" style="background: #000;color: #898989; width:80%;padding-left: 10px; border-radius: 25px; border-color: #eae8df;border-inline-width: 1px;border-style:ridge;margin-left: 20px;" clearable  :placeholder="$t('uc.finance.invitingmining')"> &ndash;&gt;-->
<!--          <Input v-model="withdrawAmount1" type="text" @on-change="onKeyYHKNameInput1"-->
<!--                 style="width:96%;margin-left: 2%;" size="large" clearable-->
<!--                 :placeholder="$t('uc.finance.invitingmining')"/>-->

<!--        </div>-->
<!--        <div style="margin-top:80px;">-->
<!--          <Button-->
<!--              style="width:40%; height: 40px; background-color: #ff5722; font-size: large; border: none;  color: #fff; border-radius: 10px;"-->
<!--              @click="firstModel">{{ $t('uc.forget.save') }}-->
<!--          </Button>-->
<!--        </div>-->

<!--        &lt;!&ndash; <div style="margin-top: 20px;margin-bottom: 20px;">-->
<!--              <span style="font-size: 12px;color:#f60;">{{$t('uc.finance.notice11')}}</span>-->

<!--            </div> &ndash;&gt;-->
<!--      </section>-->
<!--      <section v-else-if="totalRadio == 'CAMPAY'">-->
<!--        <div-->
<!--            style="margin-left: 2%; align-items: center;color: #fff; width: 96%;height: auto;padding: 5px 10px;background-color:#1B2C38 ;margin-top:20px;border-radius: 10px;display: flex;justify-content: space-between;"-->
<!--            @click="gotoBankCards">-->
<!--          <div style="display: flex;justify-items: center;">-->
<!--            <img src="../assets/logo/hhh1.png" width="20" style="margin-right: 5px;" alt="">-->
<!--            {{ $t('uc.finance.xx93') }}-->
<!--          </div>-->
<!--          <div style="font-size: x-large;">-->
<!--            {{ '+' }}-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="celllall2">-->
<!--          <Input v-model="withdrawAmount1" type="text" @on-change="onKeyYHKNameInput1"-->
<!--                 style="width:96%;margin-left: 2%;" size="large" clearable-->
<!--                 :placeholder="$t('uc.finance.invitingmining')"/>-->
<!--        </div>-->
<!--        <div>-->
<!--          <Scroll style="width: 95%;" height="200">-->
<!--            <div dis-hover v-for="(item, index) in bankCards" :key="index" style="margin-bottom: 12px"-->
<!--                 @click="getActiveBank(item)">-->
<!--              <div class="bankcardActive" v-if="item.active == true">-->
<!--                <div style="display: flex;width: 50%;align-items: center;">-->
<!--                  <img :src="item.img" width="30" height="30" alt="">-->
<!--                  <div style="display: flex;flex-direction: column;margin-left: 5px;">-->
<!--                    <span> {{ item.bankName }}</span>-->
<!--                    <span>{{ item.walletAddress }}</span>-->
<!--                  </div>-->
<!--                </div>-->


<!--                <span>{{ item.walletName }}</span>-->
<!--              </div>-->
<!--              <div class="bankcard" v-else>-->
<!--                <div style="display: flex;width: 50%;align-items: center;">-->
<!--                  <img :src="item.img" width="30" height="30" alt="">-->
<!--                  <div style="display: flex;flex-direction: column;margin-left: 5px;">-->
<!--                    <span> {{ item.bankName }}</span>-->
<!--                    <span>{{ item.walletAddress }}</span>-->
<!--                  </div>-->

<!--                </div>-->

<!--                <span>{{ item.walletName }}</span>-->
<!--                &lt;!&ndash; <span>{{ $t('uc.finance.xx68') }} : {{ item.ccy }}</span>-->
<!--                <span>{{ $t('uc.finance.xx91') }} : {{ item.bankName }}</span> &ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->
<!--          </Scroll>-->
<!--        </div>-->
<!--        <div style="margin-top:40px;">-->
<!--          <Button-->
<!--              style="width:40%; height: 40px; background-color: #1574E2; font-size: large; border: none;  color: #fff; border-radius: 4px;"-->
<!--              @click="firstModel">{{ $t('uc.forget.save') }}-->
<!--          </Button>-->
<!--        </div>-->
<!--      </section>-->
    </div>
    <Modal v-model="modal2" width="450" :closable="false">
      <p slot="header" style="color:#f60;text-align:center">

        <span>{{ $t('uc.forget.emailcodetip1') }}</span>
      </p>
      <Form class="withdraw-form-inline1" ref="formInline" :model="formInline">
        <FormItem>
          <Input type="password" password v-model="formInline.fundpwd"
                 :placeholder="$t('uc.forget.emailcodetip')"></Input>
        </FormItem>
        <FormItem>
          <Input type="password" password v-model="formInline.refundpwd"
                 :placeholder="$t('uc.forget.confirmpwd')"></Input>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="primary" size="large" long @click="submitCode">{{ $t('uc.forget.save') }}</Button>
      </div>
    </Modal>
    <Modal v-model="modal22" width="450" :closable="false">
      <p slot="header" style="color:#f60;text-align:center">

        <span>{{ $t('uc.forget.emailcodetip11') }}</span>
      </p>
      <Form class="withdraw-form-inline1" inline>
        <FormItem>
          <Input type="text" clearable v-model="inputAddress"
                 :placeholder="$t('uc.forget.emailcodetip11')"></Input>
        </FormItem>
        <div style="margin-top: 20px;">
          <span style="font-size: 12px;color:#f60;">{{ $t('uc.finance.notice12') }}</span>
        </div>
      </Form>
      <div slot="footer">
        <Button type="primary" size="large" long @click="addAddress">{{ $t('uc.forget.save') }}</Button>
      </div>
    </Modal>
    <Modal v-model="modal9" width="450" :closable="false" :mask-closable="false">
      <p slot="header">
        {{ $t("uc.finance.xx201") }}
      </p>
      <Form class="withdraw-form-inline" ref="formInline" :model="formInline" inline>
        <FormItem>
          <Input type="password" password v-model="formInline.fundpwd"
                 :placeholder="$t('uc.forget.emailcodetip')"></Input>
        </FormItem>
      </Form>
      <div slot="footer">
                <span
                    v-throttle
                    style="background:#f0ac19;color:#fff;width:80px;border-radius:30px;display:inline-block;text-align:center;height:30px;line-height: 30px;"
                    @click="checkpp">{{ $t("common.ok") }}</span>
      </div>
    </Modal>
    <Modal v-model="modal10" width="450" :closable="false" :mask-closable="false">
      <p slot="header">
        {{ $t("common.tibifirst") }}
      </p>
      <p>
        {{ $t('uc.forget.walletaddress1') }} : {{ inputAddress }}
      </p>
      <br>
      <p>
        {{ $t('uc.forget.walletaddress2') }} : {{ withdrawOutAmount - 1 }}
      </p>
      <div slot="footer">
        <span style="margin-right:50px" @click="cancel">{{ $t("common.close") }}</span>
        <span
            style="background:#f0ac19;color:#fff;width:80px;border-radius:30px;display:inline-block;text-align:center;height:30px;line-height: 30px;"
            @click="okfirst">{{ $t("common.ok") }}</span>
      </div>
    </Modal>
    <Modal v-model="modal11" width="450" :closable="false" :mask-closable="false">

      <p slot="header">
        {{ $t("common.tibifirst") }}
      </p>
      <p>
        {{ $t('uc.forget.walletaddress1') }} : {{ inputAddress1 }}
      </p>
      <br>
      <p>
        {{ $t('uc.forget.walletaddress2') }} : {{ withdrawAmount1 }}
      </p>
      <div slot="footer">
        <span style="margin-right:50px" @click="cancel">{{ $t("common.close") }}</span>
        <span
            style="background:#f0ac19;color:#fff;width:80px;border-radius:30px;display:inline-block;text-align:center;height:30px;line-height: 30px;"
            @click="okfirst">{{ $t("common.ok") }}</span>
      </div>
    </Modal>
    <!--        <Modal-->
    <!--            :title="$t('common.pleaseselectCurrency')"-->
    <!--            v-model="modal8"-->
    <!--            :closable="false"-->
    <!--            :mask-closable="false"-->
    <!--        >-->
    <!--            <div slot="footer">-->
    <!--                <Button type="primary" @click="confirmCurrency">{{ $t("common.ok") }}</Button>-->
    <!--            </div>-->
    <!--            <RadioGroup v-model="vertical">-->
    <!--                <Radio v-for="item in currencyList" :label="item.ccy" :key="item.id"-->
    <!--                       style="margin-top: 10px;display: flex; align-items: center;">-->
    <!--                    <img :src=item.coinImg alt="" width="25">-->
    <!--                    <span>{{ item.ccy }}</span>-->
    <!--                </Radio>-->

    <!--            </RadioGroup>-->
    <!--        </Modal>-->
    <Modal
        :title="$t('common.pleaseselectCurrency')"
        v-model="modal8"
        :closable="false"
        :mask-closable="false">
      <div slot="footer">
        <Button type="primary" @click="confirmCurrency">{{ $t("common.ok") }}</Button>
      </div>
      <RadioGroup v-model="vertical" vertical>
        <Radio v-for="item in currencyList" :label="item.ccy" :key="item.id"
               style="margin-top: 10px;display: flex; align-items: center;">
          <img :src=item.coinImg alt="" width="25">
          <span>{{ item.ccy }}</span>
        </Radio>

      </RadioGroup>
    </Modal>
    <!-- <Modal
        :title="$t('uc.login.toSetting')"
        v-model="modal88"
        @on-ok="gotoSetting"
        @on-cancel="cancel"
        :closable="false"
        :mask-closable="false"
        class-name="vertical-center-modal">

        <p v-if="totalRadio == 'CAMPAY'">{{ $t('uc.login.settingbank') }}</p>
        <p v-else>{{ $t('uc.login.settinghuione') }}</p>
    </Modal> -->
    <Spin fix v-show="eloading">
      <div class="loader">
        <img src="../assets/logo/loading.svg" alt="">
      </div>
    </Spin>
  </div>
</template>


<script>

// import { scrollTab, tabItem } from "vue-scrollable-tabs";

export default {
  // components: {

  //   scrollTab,
  //   tabItem
  // },
  data() {
    return {
      user: {},
      codeIsSending: false,
      sendcodeValue: this.$t("uc.regist.sendcode"),
      countdown: 60,
      modal8: false,
      formInline: {
        code: "",
        fundpwd: "",
        refundpwd: ''
      },
      bankCode: '',
      bankList: [],
      bankCards: [],
      USDTCards: [],
      modal11: false,
      inputAddress1: '',
      withdrawAmount1: 0,
      value14: '',
      vertical: '',
      modal9: false,
      modal10: false,
      modal: false,
      modal2: false,
      modal22: false,
      modal88: false,
      fundpwd: "",
      currenyImg: '',
      currentCoin: {},
      transaction: {
        page: 0,
        pageSize: 10,
        total: 0
      },
      eloading: false,
      loading: false,
      withdrawAdress: "",
      inputAddress: "", //用户输入的地址
      withdrawAmount: 0,
      withdrawFee: 0,
      withdrawOutAmount: 0,
      coinType: "",
      coinList: [],
      tableWithdraw: [],
      allTableWithdraw: [],
      model1: 'USDT',
      moneybab: '',
      model21: '',
      selfamount: '',
      tempmember: '',
      payname: '',
      currencyList: [],
      totalRadio: '',
      vndRadio: '',
      usdtRadio: '',
      usdRadio: '',
      bankCCY: '',
      iscam: false,
      ishui: false
    };
  },
  watch: {
    currentCoin: function () {
      this.withdrawFee =
          this.currentCoin.minTxFee +
          (this.currentCoin.maxTxFee - this.currentCoin.minTxFee) / 2;
    }
  },
  methods: {

    gotoBankCards() {
      this.$router.push({name: "CardMaker"});

    },

    gotoUSDT() {
      this.$router.push({name: "UsdtMaker"});
    },

    getActiveUSDTBank(data) {
      console.log('xxxxx' + data);
      this.inputAddress = data.walletAddress
      this.bankCCY = data.ccy

      this.USDTCards.forEach(element => {
        element.active = false
      });

      data.active = true
    },

    getActiveBank(data) {
      console.log('xxxxx' + data);
      this.bankCode = data.bankName
      this.inputAddress1 = data.walletAddress
      this.payname = data.walletName
      this.bankCCY = data.ccy

      this.bankCards.forEach(element => {
        element.active = false
      });

      data.active = true
    },


//     {
//     "id": "52",
//     "userId": "155",
//     "userNo": "245750",
//     "addressChannel": 2,
//     "walletName": "hggj ",
//     "walletAddress": "123123",
//     "createTime": "2023-10-03 16:44:12",
//     "updateTime": "2023-10-03 16:44:12",
//     "ccy": "VND",
//     "ccySymbol": null,
//     "bankName": "TECHCOMBANK",
//     "bankAddress": null
// }
    gotoSetting() {
      this.$router.push('/profile')
    },
    // switchBank(data){
    //   this.clearValues()
    //   this.bankList.forEach(element => {
    //     if (element.bankName == data) {
    //       this.backCode = element.bankCode
    //     }
    //   });
    // },
    switchCurrency(data) {
      this.totalRadio = data
      if (data == 'HUIONE') {
        this.payname = this.tempmember.huioneName
      } else if (data == 'CAMPAY') {
        //   this.payname = this.tempmember.realName
        //  this.getBankList()

        this.getBankCards()

      }

      if (this.payname == '') {
        this.modal88 = true
      }
    },

    touchHun() {
      this.clearValues()

      this.iscam = false
      this.ishui = true
      this.totalRadio = 'HUIONE'
      if (this.payname == '') {
        this.modal88 = true
      }
    },
    touchCam() {
      this.clearValues()

      this.iscam = true
      this.ishui = false

      this.totalRadio = 'CAMPAY'
      this.getBankCards()
      if (this.payname == '') {
        this.modal88 = true
      }
    },

    getBankCards() {
      let self = this
      this.$http
          .post(self.host + this.api.uc.wallet)
          .then(response => {
            let resp = response.body
            if (response.status == 200) {
              console.log('' + resp.data);
              self.bankCards = []
              self.USDTCards = []
              resp.forEach(element => {
                if (element.ccy == 'USDT') {
                  // self.inputAddress = element.walletAddress
                  // self.isdisable = true
                  element.active = false
                  self.USDTCards.push(element)

                } else if (element.ccy == self.vertical) {
                  // element.active = false
                  // if (element.bankName == 'EMNYKHPPXXX') {
                  //     element.img = require('@/assets/logo/zz6.png')
                  // } else if (element.bankName == 'WINGKHPPXXX') {
                  //     element.img = require('@/assets/logo/wing11.png')
                  // } else if (element.bankName == 'ABAAKHPPXXX') {
                  //     element.img = require('@/assets/logo/zz7.png')
                  // } else if (element.bankName == 'ACLBKHPPXXX') {
                  //     element.img = require('@/assets/logo/zz2.png')

                  // } else if (element.bankName == 'VIETCOMBANK') {
                  //     element.img = require('@/assets/logo/zz1.png')
                  // } else if (element.bankName == 'MBBANK') {
                  //     element.img = require('@/assets/logo/zz4.png')
                  // } else if (element.bankName == 'TECHCOMBANK') {
                  //     element.img = require('@/assets/logo/zz3.png')
                  // }
                  // self.bankCards.push(element)
                }
              });

              self.$nextTick(() => {
                if (self.bankCards.length > 0 && self.$refs.scrollTab4) {
                  self.$refs.scrollTab4.calcWidth()
                } else if (self.USDTCards.length > 0 && self.$refs.scrollTab5) {
                  self.$refs.scrollTab5.calcWidth()
                }

              });

            }

          })
    },
    addAddress() {
      //  /user/addWithdrawalAddress    入参：Integer addressChannel（0：区块链，1：汇旺，2：银行卡），
      //   String walletName(账号姓名)（选择区块链隐藏此选项），String walletAddress(提现账号)，String ccy（币种）
      var self = this;
      var params = {};

      params['addressChannel'] = 0
      params['walletAddress'] = this.inputAddress
      params['ccy'] = 'USDT'
      params['ccySymbol'] = 'TRC20'

      this.$http
          .post(self.host + this.api.uc.identification, params)
          .then(response => {
            var resp = response.body;
            if (resp.code == "0000") {
              self.modal22 = false
              self.$Message.success(resp.mesg);
            } else {
              self.inputAddress = ''
              self.$Message.error(resp.mesg);
            }
          });
    },

    confirmCurrency() {

      this.clearValues()
      this.modal8 = false
      if (this.vertical == 'USDT') {
        this.currenyImg = require('@/assets/logo/usdt.png');
        this.usdtRadio = this.totalRadio = 'USDT'
// this.inputAddress = this.tempmember.bindAddress || ''
// if (this.inputAddress == '') {
//   this.modal22 = true
// }

        this.getBankCards()
      } else if (this.vertical == 'USD') {
        this.currenyImg = require('@/assets/logo/usa.png')
        this.usdRadio = this.totalRadio = 'HUIONE'
        this.ishui = true
// this.getBankList()

      } else if (this.vertical == 'VND') {
        this.currenyImg = require('@/assets/logo/vietnam.png')
        this.vndRadio = this.totalRadio = 'CAMPAY'
// this.getBankList()
        this.getBankCards()

      } else if (this.vertical == 'CNY') {
        this.totalRadio = 'CNY'
        this.currenyImg = require('@/assets/logo/china.png')
      } else if (this.vertical == 'IDR') {
        this.totalRadio = 'IDR'
        this.currenyImg = require('@/assets/logo/indiaN.png')
      } else if (this.vertical == 'JPY') {
        this.totalRadio = 'JPY'
        this.currenyImg = require('@/assets/logo/japan.png')
      } else if (this.vertical == 'KRW') {
        this.totalRadio = 'KRW'
        this.currenyImg = require('@/assets/logo/korea.png')
      } else if (this.vertical == 'THB') {
        this.totalRadio = 'THB'
        this.currenyImg = require('@/assets/logo/thai.png')
      }

      this.currencyList.forEach(element => {
        if (element.ccy == this.vertical) {
          this.selfamount = element.amount
        }
      });


    },
    cancelCurrency() {

      this.modal8 = false
    },


    popUP() {
      this.modal8 = true
    },

    goback() {
      //获取个人安全信息
      this.$router.back(-1)

    },
    okfirst() {

      // this.getMemberInfo()

      if (this.tempmember.jbPassword) {
        this.getAddrList()
      } else {
        // this.$Message.error(this.$t('uc.forget.emailcodetip1'));
        this.modal2 = true
      }

    },
    onKeyYHKNameInput() {
      let value = '' + this.withdrawAmount
      // console.log(this.body.ccyScale,"币种精度")
      let data = 2 //币种精度
      value = value.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
          .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
          .replace('.', '$#$')
          .replace(/\./g, '')
          .replace('$#$', '.')
          .replace(new RegExp(`^(\\-)*(\\d+)\\.(\\d{${data}}).*$`), '$1$2.$3') // 只能输入data个小数
      if (value.indexOf('.') < 0 && value != '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        value = parseFloat(value);
      }
      this.$nextTick(function () {
        this.withdrawOutAmount = value;
        this.withdrawAmount = Number(value).toLocaleString()

      })
    },
    onKeyYHKNameInput1() {
      let value = '' + this.withdrawAmount1
      // console.log(this.body.ccyScale,"币种精度")
      let data = 2 //币种精度
      value = value.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
          .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
          .replace('.', '$#$')
          .replace(/\./g, '')
          .replace('$#$', '.')
          .replace(new RegExp(`^(\\-)*(\\d+)\\.(\\d{${data}}).*$`), '$1$2.$3') // 只能输入data个小数
      if (value.indexOf('.') < 0 && value != '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        value = parseFloat(value);
      }
      this.$nextTick(function () {
        this.withdrawAmount1 = Number(value).toLocaleString()

      })
    },
    cancel() {
      this.modal9 = false;
      this.modal10 = false;
      this.formInline.code = "";
      this.formInline.fundpwd = "";
    },


    clearValues() {
      if (this.$refs.address) {
        this.$refs.address.setQuery(" ");
      }
      this.withdrawAdress = "";
      this.inputAddress = "";
      this.inputAddress1 = "";
      this.withdrawAmount = 0;
      this.withdrawAmount1 = 0
      // this.withdrawFee= 0;
      this.withdrawOutAmount = 0;
      this.iscam = false
      this.payname = ''

    },


    getMemberInfo() {
      //获取个人安全信息
      var self = this;

      this.$http
          .post(self.host + this.api.uc.memberInfo)
          .then(response => {
            var resp = response.body;
            if (resp.code == "0000") {
              self.tempmember = resp.data

              let current = resp.data.remark
              self.coinType = current
              if (current == 'USDT') {
                self.vertical = 'USDT'
                self.currenyImg = require('@/assets/logo/usdt.png');
                self.usdtRadio = self.totalRadio = 'USDT'
                // self.inputAddress = self.tempmember.bindAddress || ''
// if (self.inputAddress == '') {
//   self.modal22 = true
// }

              } else if (current == 'USD') {
                self.vertical = 'USD'
                self.currenyImg = require('@/assets/logo/usa.png')
                self.usdRadio = self.totalRadio = 'HUIONE'
                this.ishui = true
              } else if (current == 'VND') {
                self.vertical = 'VND'
                self.currenyImg = require('@/assets/logo/vietnam.png')
                self.vndRadio = self.totalRadio = 'CAMPAY'

              } else if (current == 'CNY') {
                self.vertical = 'CNY'
                self.currenyImg = require('@/assets/logo/china.png')
              } else if (current == 'IDR') {
                self.vertical = 'IDR'
                self.currenyImg = require('@/assets/logo/indiaN.png')
              } else if (current == 'JPY') {
                self.vertical = 'JPY'
                self.currenyImg = require('@/assets/logo/japan.png')
              } else if (current == 'KRW') {
                self.vertical = 'KRW'
                self.currenyImg = require('@/assets/logo/korea.png')
              } else if (current == 'THB') {
                self.vertical = 'THB'
                self.currenyImg = require('@/assets/logo/thai.png')
              }


              self.currencyList.forEach(element => {
                if (element.ccy == self.coinType) {
                  self.selfamount = element.amount
                }
              });

              self.getBankCards()

            } else {
              self.$Message.error(resp.mesg);
            }
          });
    },
    getMemberInfo1() {

      if (this.tempmember.jbPassword) {
        // if (this.vertical ==  'USDT') {
        //   this.getAddrList()
        // }
        // else
        this.modal9 = true
      } else {
        this.modal2 = true
      }
    },
    getMember() {
      //获取个人安全信息
      var self = this;
      this.eloading = true
      this.$http
          .post(self.host + this.api.uc.personalWallet)
          .then(response => {
            var resp = response.body;
            self.eloading = false
            if (resp.code == "0000") {

              self.currencyList = resp.data
              self.getMemberInfo();
              self.currencyList.forEach(element => {
                if (element.ccy == 'USDT') {
                  element.coinImg = require('@/assets/logo/usdt.png');
                } else if (element.ccy == 'USD') {
                  element.coinImg = require('@/assets/logo/usa.png')
                } else if (element.ccy == 'VND') {
                  element.coinImg = require('@/assets/logo/vietnam.png')
                } else if (element.ccy == 'CNY') {
                  element.coinImg = require('@/assets/logo/china.png')
                } else if (element.ccy == 'IDR') {
                  element.coinImg = require('@/assets/logo/indiaN.png')
                } else if (element.ccy == 'JPY') {
                  element.coinImg = require('@/assets/logo/japan.png')
                } else if (element.ccy == 'KRW') {
                  element.coinImg = require('@/assets/logo/korea.png')
                } else if (element.ccy == 'THB') {
                  element.coinImg = require('@/assets/logo/thai.png')
                }
              });


            } else {
              self.$Message.error(resp.mesg);
            }
          });
    },

    checkpp() {
        this.eloading = true
        this.modal9 = false

      if (this.totalRadio == 'HUIONE') {
        this.huioneSubmit(this.formInline.fundpwd)
      } else if (this.totalRadio == 'CAMPAY') {
        this.campaySubmit(this.formInline.fundpwd)
      } else
        this.getAddrList()

    },

    submitCode() {
      let that = this
      if (this.formInline.fundpwd != this.formInline.refundpwd) {
        this.$Notice.error({
          title: this.$t("common.tip"),
          desc: this.$t("uc.forget.pwdvalidate2")
        });
        return
      }

      let params = {};

      params['jbPassword'] = this.formInline.fundpwd
      this.$http
          .post(this.host + this.api.uc.activitylist, params)
          .then(response => {
            that.fundpwd = "";
            var resp = response.body;
            if (resp.code == "0000") {
              that.modal2 = false;
              that.getMember()
              // that.formInline.fundpwd = ''
              if (that.totalRadio == 'HUIONE') {

                that.huioneSubmit()
              } else if (that.totalRadio == 'CAMPAY') {
                that.campaySubmit(that.formInline.fundpwd)
              } else {
                // that.getAddrList()
                that.getMemberInfo()
              }
            } else {
              that.$Message.error(resp.mesg);
            }
          });
    },


    //withdraw
    ok(password) {

      let that = this
      let params = {};


      params["transferPass"] = password;

      this.$http
          .post(this.host + this.api.uc.getfreecard, params)
          .then(response => {
            that.formInline.fundpwd = "";
            var resp = response.body;
            if (resp.code == "0000") {
              that.modal9 = false;
              that.formInline.code = "";
              // that.formInline.fundpwd = "";
              that.selfamount = resp.data.accountBalance
              // this.transaction.page = 0;
              // this.getList();
              that.clearValues();
              that.$Message.success(resp.mesg);
            } else {
              that.$Message.error(resp.mesg);
            }
          });
    },

    getAddress() {
      //获取个人安全信息
      var self = this;
      // var params = {
      //     };

      //     params['accountNo'] = this.member.userNo
      //     params['showHide'] = 0
      this.loading = true
      this.$http
          .post(self.host + this.api.uc.wallet)
          .then(response => {
            var resp = response.body;
            self.loading = false
            if (resp.length > 0) {
              self.inputAddress = resp[0].walletAddress
            } else {
              if (resp.mesg) {
                self.$Message.error(resp.mesg);
              }
              // this.$Message.error(this.$t('common.logintip'));
            }
          });
    },

    campaySubmit() {
      if (!this.inputAddress1 || !this.withdrawAmount1) {
        this.$Message.error(this.$t('uc.finance.notice123'))
        this.eloading = false
        return
      }

      var self = this;
      let param = {}
      // param.orderType = 2
      var num = this.withdrawAmount1.replace(/\D/g, '');

      var num2 = parseInt(num);
      console.log('numeric', num2);
      param.amount = num2
      param.account = this.inputAddress1
      param.bankCode = this.bankCode
      param.withdrawalName = this.payname
      param.ccy = this.bankCCY
      param.pass = this.formInline.fundpwd
      param.payChannal = 'campay'

      this.$http
          .post(self.host + this.api.uc.cambodiaWithdrawInit, param)
          .then(response => {
              this.eloading = false
            var resp = response.body;
            if (resp.code == "0000") {
              this.modal9 = false;
              this.formInline.fundpwd = ''
              self.$Message.success(this.$t('uc.finance.notice1234'))
              self.clearValues()
            } else {
              this.modal9 = false;
              self.$Message.error(resp.mesg);
            }
          });
    },

    huioneSubmit(password) {

      if (!this.inputAddress1 || !this.withdrawAmount1 || !this.payname) {
        this.$Message.error(this.$t('uc.finance.notice123'))
          this.eloading = false
        return
      }

      var self = this;
      let param = {}
      param.ccy = 'USD'
      var num = this.withdrawAmount1.replace(/\D/g, '');

      var num2 = parseInt(num);
      console.log('numeric', num2);
      param.amount = num2
      param.account = this.inputAddress1
      param.withdrawalName = this.payname
      param.payChannal = 'huione'
      param.pass = password;
      param.bankCode = '0'


      this.$http
          .post(self.host + this.api.uc.cambodiaWithdrawInit, param)
          .then(response => {
              this.modal9 = false
            var resp = response.body;
            if (resp.code == "0000") {
              this.eloading = false;
              this.formInline.fundpwd = ''
              self.$Message.success(this.$t('uc.finance.notice1234'))
              self.clearValues()
            } else {
              this.modal9 = false;
              self.$Message.error(resp.mesg);
            }
          });
    },


    // initwithdraw
    getAddrList() {

      if (this.withdrawAmount < 0) {
        this.$Message.error(this.$t('uc.forget.walletaddress21'));
        this.eloading = false
        return
      }
      let params = {};
      params["account"] = this.inputAddress
      params["amount"] = this.withdrawOutAmount
      params["ccy"] = "USDT"
      params["payChannal"] = "udun"
      // params["withdrawalName"] = this.$store.getters.member.id
      params["withdrawalName"] = this.$store.getters.member.userNo
      params['pass'] = this.formInline.fundpwd
      params.bankCode = '0'

      this.$http
          .post(this.host + this.api.uc.cambodiaWithdrawInit, params)
          .then(response => {
            var resp = response.body;
            this.eloading = false
            if (resp.code == "0000") {
              this.model21 = resp.data
              //  this.modal9 = true
              this.$Message.success(resp.mesg)
            } else {
              this.$Message.error(resp.mesg);
            }
          });
    },


    round(v, e) {
      var t = 1;
      for (; e > 0; t *= 10, e--) ;
      for (; e < 0; t /= 10, e++) ;
      return Math.round(v * t) / t;
    },

    getBankList() {
      let that = this
      let param = {
        'transCurrency': this.vertical,
        'language': this.$i18n.locale
      }

      this.$http.get(this.host + this.api.uc.cambodiaWithdrawBanks, {params: param}).then(response => {
        var resp = response.body;
        this.loading = false;

        if (resp.code == "SUCCESS") {
          this.bankList = resp.banks
          //           {
          //   "bankId": "EMONEY",
          //   "bankCode": "EMNYKHPPXXX",
          //   "bankName": "eMoney",
          //   "referId": "emnykhppxxxx@emny",
          //   "bankTransName": "eMoney",
          //   "enabled": true,
          //   "icon": "https://xxx"
          // },
        } else {
          that.$Message.error(resp.message);
        }
      });
    },


    firstModel() {
      //  this.modal10 = true;
      this.getMemberInfo1();
    },


    getMoneyback() {
      var self = this;
      this.$http
          .post(this.host + this.api.uc.apply)
          .then(response => {
            var resp = response.body;
            if (resp.code == "0000") {
              this.getMember();
            } else {
              self.$Message.error(resp.mesg);
            }
          });
    },
  },
  created() {
    this.getMember();
    // this.getList();
    // this.getAddress()
    // this.getMoneyback();
    if (this.$route.params && this.$route.params.from === 'set') {
      this.modal2 = true
    }

    //  this.getBankList();

  },
  computed: {
    member: function () {
      console.log(this.$store.getters.member);
      return this.$store.getters.member;
    },

  }
};
</script>


<style lang="scss" scoped>
/deep/ .ivu-modal-body{
  padding: 4px 10px;
  .ivu-form-item{
    margin-bottom: 0;
  }
}
.withdraw-form-inline {
  //padding: 20px 40px 0 40px;

  /deep/ .ivu-form-item{
    width: 100%;
  }

  /deep/ .ivu-input {
    width: 100%;
    color: #000;
    height: 35px;
    line-height: 35px;
    background-color: transparent;
  }
}

.withdraw-form-inline1 {
  display: flex;
  flex-direction: column;
  padding: 0px 40px 0 40px;

  .ivu-input {
    height: 35px;
    line-height: 35px;
  }
}


.box_21 {
  position: fixed;
  top: 0;
  display: flex;
  height: 50px;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  color: $color-text-tag;
  z-index: 200;
  width: 100%;
}

.celllall {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #a2a2a1;
  font-size: 18px;
  margin-top: 20px;
  margin-left: 20px;
}

.celllall2 {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
  margin-top: 40px;
}

.celllall4 {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
  margin-top: 20px;
}


.bankcard {
  display: flex;
  color: #fff;
  justify-content: space-between;
  background-image: url(../assets/logo/bb2.png);
  background-position: center;
  align-items: center;

  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 10px 20px;
  font-size: small;
  width: 100%;
}

.bankcardActive {
  display: flex;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  background-image: url(../assets/logo/bb1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 10px 20px;
  font-size: small;
  width: 100%;
}


.celllall1 {
  width: 100%;
  height: 45px;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: flex-start;
  color: #fff;
  padding: 10px;
  margin-top: 45px;
  background-color: #1B2C38;
}


.withdraw {
  background-color: $color-background;
  height: $full-page;
  position: relative;
  overflow: hidden;
  text-align: center;
}

#sendCode {
  position: absolute;
  border: none;
  background: none;
  top: 10px;
  outline: none;
  right: 0;
  width: 30%;
  color: #f0ac19;
  cursor: pointer;
  height: 20px;
  line-height: 20px;
  border-left: 1px solid #dddee1;
}


/deep/ .ivu-input {
  border-radius: 8px;
  border: 1px solid #2f2f2f;
  background-color: #1B2C38;
  color: #fff;
}

.bill_box {
  width: 96%;
  height: auto;
  // background: url(../assets/netmage/hhss.png);
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: 100% 100% ;
  margin-left: 2%;
  // overflow: hidden;
  .notice {
    color: #ccc;
    width: 100%;
    line-height: 200px;
    height: 200px;
    text-align: center;
    align-items: center;
  }

}

#withdrawAddressList {
  position: absolute;
  height: 0;
  transition: height 0.3s;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
  max-height: 245px;
  overflow: auto;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  height: auto;
  background: #fff;
}

.body {
  margin-top: 60px;
}

.butt0 {
  background-image: url(../assets/logo/xx22.png);
  background-position: center;
  background-size: 100% 100%;
  height: 40px;
  width: 175px;
  background-repeat: no-repeat;
  color: #fff;
  padding-top: 10px;
}

.butt1 {
  background-image: url(../assets/logo/xx234.png);
  background-position: center;
  background-size: 100% 100%;
  height: 40px;
  width: 175px;
  background-repeat: no-repeat;
  color: #fff;
  padding-top: 10px;
}

/deep/ .ivu-modal-content {
  background-color: #fff;
  color: #000;
}

/deep/ .ivu-select-selection {
  height: 40px;
  background-color: transparent;
}

/deep/ .ivu-select-placeholder {
  display: block;
  height: 40px !important;
  line-height: 40px !important;
  color: #fff !important;
}

/deep/ .ivu-select-selected-value {
  color: #fff;
  height: 40px !important;
  line-height: 40px !important;
}

</style>


<!-- <style scoped lang="less">

// class="ivu-input ivu-input-default"
  /deep/ .ivu-input{
  background: transparent;
  border: 0;
  border-radius: 25px;

  color: #898989
}



</style> -->
