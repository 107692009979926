<template>
    <div class="login_form">
        <div class="headBox">
            <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">
            <span>
               {{ $t('uc.finance.gamedetail') }}
            </span>
            <div style="display: flex;align-items: center;">
                <img src="../assets/logo/ma11.png" width="20" alt="" @click="otherpicke">
            </div>
        </div>
        <div class="top" style="display: flex;justify-content: space-between;padding: 55px 10px 10px 10px">
            <div style="display: flex;flex-direction: column;">
                <span>{{ $t('common.date3') }}</span>
                <span>{{ totalbet }}</span>
            </div>
            <div style="display: flex;flex-direction: column;">
                <span>{{ $t('common.date7') }}</span>
                <span>{{ totalValidBet }}</span>
            </div>
            <div style="display: flex;flex-direction: column;">
                <span>{{ $t('common.date2') }}</span>
                <span>{{ totalwin }}</span>
            </div>
        </div>
        <div style="width: 100%;height: 60px;;padding-left: 10px">
            <Select v-model="sel" style="width:50%;height: 40px;float: left">
                <Option v-for="item in selList" :value="item.val" :key="item.val">
                    {{ item.val }}
                </Option>
            </Select>
        </div>
        <div class="sports">
            <Tabs :value="currentab" @on-click="handletabs">
                <TabPane :label="$t('common.exchangeAll')" name="ALL" style="padding-left: 10px;padding-right: 10px;">
                    <div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;" v-if="showxxy==true">
                        <span style="font-size: 14px;;color: #b1bad3; ">暂无记录</span>
                    </div>
                    <Scroll :on-reach-bottom="nextPage" :height=realHeight>
                        <div dis-hover v-for="(item, index) in datalist2" :key="index" :class="index%2 == 0? 'active': 'notactive'">
                            <div style="width: 100%;height:100%;display:flex;flex-direction: column; justify-content: space-around;"
                                 v-if="['PP','JILI','JDB','','FB-LIVE'].includes(item.thirdPlatform)">
                                <div class="xxx1">
                                        <span style="font-size: 14px;;color: #b1bad3;padding-right: 4px">{{ item.thirdNo }} </span>
                                        <img src="../assets/logo/copy.png" v-clipboard:copy="item.thirdNo" v-clipboard:success="onCopy" v-clipboard:error="onError" width="10" height="10" alt="">
                                </div>
                                <div class="xxx2">
                                    <span style="font-size: 14px;;color: #b1bad3;">{{ item.endTime }}</span>
                                    <div>投注</div>
                                    <span style="color: #b1bad3;font-size: 14px;;">{{ Number(item.bet).toFixed(2) }}</span>
                                </div>
                                <div class="xxx3">
                                    <span v-if="item.thirdPlatform === 'FB-LIVE'" style="font-size: 14px;;color: #b1bad3;margin-right: 1px;">{{ parseGame(item) }}</span>
                                    <div v-else>
                                        <span v-if="lang == 'cn'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_cn }}</span>
                                        <span v-else-if="lang == 'vn'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_vie }}</span>
                                        <span v-else-if="lang == 'th'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_th }}</span>
                                        <span v-else-if="lang == 'jp'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_jp }}</span>
                                        <span v-else-if="lang == 'kr'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_kr }}</span>
                                        <span v-else-if="lang == 'in'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_in }}</span>
                                        <span v-else style="font-size: 14px;;color: #b1bad3">{{ item.name }}</span>
                                    </div>
                                    <div>输赢</div>
                                    <div style="display: flex;justify-content: end;align-items: baseline;">
                                        <div v-if="Number(item.payout) - Number(item.bet) < 0" style="text-align: right">
                                            <span style="color: #45d91e ;font-size: 14px;;">{{ (Number(item.payout) - Number(item.bet)).toFixed(2) }}</span>
                                        </div>
                                        <div v-else  style="text-align: right">
                                            <span style="color: #f00;font-size: 14px;;"> {{ (Number(item.payout) - Number(item.bet)).toFixed(2) }}</span>
                                        </div>
                                        <!--隐藏币种-->
<!--                                        <span style="color: #b1bad3;font-size: 14px;;margin-left: 5px;">-->
<!--                                            {{ item.ccy }}-->
<!--                                        </span>-->
                                    </div>

                                </div>
                            </div>
                            <div style="width: 100%;display: flex;flex-direction: column;" v-else-if="item.thirdPlatform == 'FB'">
                                <div class="xxx1" style="display: flex;width: 100%;justify-content: space-between;">
                                    <div>
                                        <span style="font-size: 14px;;color: #b1bad3;"> {{ item.thirdNo }} </span>
                                        <img src="../assets/logo/copy.png" width="10" height="10"
                                             v-clipboard:copy="item.thirdNo" v-clipboard:success="onCopy"
                                             v-clipboard:error="onError" alt="">
                                    </div>
                                </div>
                                <div class="xxx2">
                                    <span style="font-size: 14px;;color: #b1bad3;">{{ item.getTime }}</span>
                                    <div>投注</div>
                                    <span style="color: #b1bad3;font-size: 14px;;">{{ item.bet }}</span>
                                </div>
                                <div class="xxx3">
                                    <span style="font-size: 14px;;color: #b1bad3;margin-right: 1px;text-align: left;">{{ $t('new.Sports') }}</span>
<!--                                    <div>-->
<!--                                        <span style="font-size: 14px;;color: #b1bad3;">{{ $t('uc.finance.xx211') }}</span>-->
<!--                                        <span v-if="Number(item.payout) < 0" style="color: #45d91e ;font-size: 14px;;"> {{ Number(item.maxWinAmount).toFixed(2) }} {{ item.ccy }}</span>-->
<!--                                        <span v-else style="color: #f00;font-size: 14px;;">{{ Number(item.maxWinAmount).toFixed(2)}}</span>-->
<!--                                    </div>-->
                                    <div>输赢</div>
                                    <div v-if="Number(item.status) == 5">
                                        <span v-if="handleStatus(item) < 0" style="color: #45d91e ;font-size: 14px;"> {{ handleStatus(item) }}</span>
                                        <span v-else style="color: #f00;font-size: 14px;;">{{ handleStatus(item) }}</span>
                                    </div>
                                    <div v-else-if="Number(item.status) == 4">
<!--                                         <span style="color: transparent;font-size: 14px;;">{{ handleStatus(item) }}</span>-->
                                        <span style="font-size: 14px;color: #b1bad3">{{ handOU(item.status) }} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Scroll>
                </TabPane>
                <TabPane label="PragmaticPlay" name="PP" style="padding-left: 10px;padding-right: 10px;">
                    <div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;" v-if="showxxy==true">
                        <span style="font-size: 14px;;color: #b1bad3; ">暂无记录</span>
                    </div>

                    <Scroll :on-reach-bottom="nextPage" :height=realHeight>
                        <div dis-hover v-for="(item, index) in datalist1" :key="index"
                             :class="index%2 == 0? 'active': 'notactive'">
                            <div>
                                <div class="xxx1">
                                    <span style="font-size: 14px;;color: #b1bad3;padding-right: 4px">{{ item.thirdNo }} </span>
                                    <img src="../assets/logo/copy.png" v-clipboard:copy="item.thirdNo" v-clipboard:success="onCopy" v-clipboard:error="onError" width="10" height="10" alt="">
                                </div>
                                <div class="xxx2">
                                    <span style="font-size: 14px;;color: #b1bad3;">{{ item.endTime }}</span>
                                    <div>投注</div>
                                    <span style="color: #b1bad3;font-size: 14px;;">{{ Number(item.bet).toFixed(2) }}</span>
                                </div>
                                <div class="xxx3">
                                    <span v-if="item.thirdPlatform === 'FB-LIVE'" style="font-size: 14px;;color: #b1bad3;margin-right: 1px;">{{ parseGame(item) }}</span>
                                    <div v-else>
                                        <span v-if="lang == 'cn'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_cn }}</span>
                                        <span v-else-if="lang == 'vn'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_vie }}</span>
                                        <span v-else-if="lang == 'th'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_th }}</span>
                                        <span v-else-if="lang == 'jp'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_jp }}</span>
                                        <span v-else-if="lang == 'kr'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_kr }}</span>
                                        <span v-else-if="lang == 'in'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_in }}</span>
                                        <span v-else style="font-size: 14px;;color: #b1bad3">{{ item.name }}</span>
                                    </div>
                                    <div>输赢</div>
                                    <div style="display: flex;justify-content: end;align-items: baseline;">
                                        <div v-if="Number(item.payout) - Number(item.bet) < 0" style="text-align: right">
                                            <span style="color: #45d91e ;font-size: 14px;;">{{ (Number(item.payout) - Number(item.bet)).toFixed(2) }}</span>
                                        </div>
                                        <div v-else  style="text-align: right">
                                            <span style="color: #f00;font-size: 14px;;"> {{ (Number(item.payout) - Number(item.bet)).toFixed(2) }}</span>
                                        </div>
                                        <!--隐藏币种-->
                                        <!--                                        <span style="color: #b1bad3;font-size: 14px;;margin-left: 5px;">-->
                                        <!--                                            {{ item.ccy }}-->
                                        <!--                                        </span>-->
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Scroll>
                </TabPane>
                <TabPane label="JILI" name="jili" style="padding-left: 10px;padding-right: 10px;">
                    <div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;" v-if="showxxy==true">
                        <span style="font-size: 14px;;color: #b1bad3; ">暂无记录</span>
                    </div>
                    <Scroll :on-reach-bottom="nextPage" :height=realHeight>
                        <div dis-hover v-for="(item, index) in datalist1" :key="index" :class="index%2 == 0? 'active': 'notactive'">
                            <div>
                                <div class="xxx1">
                                    <span style="font-size: 14px;;color: #b1bad3;padding-right: 4px">{{ item.thirdNo }} </span>
                                    <img src="../assets/logo/copy.png" v-clipboard:copy="item.thirdNo" v-clipboard:success="onCopy" v-clipboard:error="onError" width="10" height="10" alt="">
                                </div>
                                <div class="xxx2">
                                    <span style="font-size: 14px;;color: #b1bad3;">{{ item.endTime }}</span>
                                    <div>投注</div>
                                    <span style="color: #b1bad3;font-size: 14px;;">{{ Number(item.bet).toFixed(2) }}</span>
                                </div>
                                <div class="xxx3">
                                    <span v-if="item.thirdPlatform === 'FB-LIVE'" style="font-size: 14px;;color: #b1bad3;margin-right: 1px;">{{ parseGame(item) }}</span>
                                    <div v-else>
                                        <span v-if="lang == 'cn'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_cn }}</span>
                                        <span v-else-if="lang == 'vn'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_vie }}</span>
                                        <span v-else-if="lang == 'th'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_th }}</span>
                                        <span v-else-if="lang == 'jp'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_jp }}</span>
                                        <span v-else-if="lang == 'kr'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_kr }}</span>
                                        <span v-else-if="lang == 'in'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_in }}</span>
                                        <span v-else style="font-size: 14px;;color: #b1bad3">{{ item.name }}</span>
                                    </div>
                                    <div>输赢</div>
                                    <div style="display: flex;justify-content: end;align-items: baseline;">
                                        <div v-if="Number(item.payout) - Number(item.bet) < 0" style="text-align: right">
                                            <span style="color: #45d91e ;font-size: 14px;;">{{ (Number(item.payout) - Number(item.bet)).toFixed(2) }}</span>
                                        </div>
                                        <div v-else  style="text-align: right">
                                            <span style="color: #f00;font-size: 14px;;"> {{ (Number(item.payout) - Number(item.bet)).toFixed(2) }}</span>
                                        </div>
                                        <!--隐藏币种-->
                                        <!--                                        <span style="color: #b1bad3;font-size: 14px;;margin-left: 5px;">-->
                                        <!--                                            {{ item.ccy }}-->
                                        <!--                                        </span>-->
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Scroll>
                </TabPane>
                <TabPane label="JDB" name="jdb" style="padding-left: 10px;padding-right: 10px;">
                    <div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;"
                         v-if="showxxy==true">
                        <span style="font-size: 14px;;color: #b1bad3; ">暂无记录</span>
                    </div>
                    <Scroll :on-reach-bottom="nextPage" :height=realHeight>
                        <div dis-hover v-for="(item, index) in datalist1" :key="index"
                             :class="index%2 == 0? 'active': 'notactive'">
                            <div>
                                <div class="xxx1">
                                    <span style="font-size: 14px;;color: #b1bad3;padding-right: 4px">{{ item.thirdNo }} </span>
                                    <img src="../assets/logo/copy.png" v-clipboard:copy="item.thirdNo" v-clipboard:success="onCopy" v-clipboard:error="onError" width="10" height="10" alt="">
                                </div>
                                <div class="xxx2">
                                    <span style="font-size: 14px;;color: #b1bad3;">{{ item.endTime }}</span>
                                    <div>投注</div>
                                    <span style="color: #b1bad3;font-size: 14px;;">{{ Number(item.bet).toFixed(2) }}</span>
                                </div>
                                <div class="xxx3">
                                    <span v-if="item.thirdPlatform === 'FB-LIVE'" style="font-size: 14px;;color: #b1bad3;margin-right: 1px;">{{ parseGame(item) }}</span>
                                    <div v-else>
                                        <span v-if="lang == 'cn'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_cn }}</span>
                                        <span v-else-if="lang == 'vn'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_vie }}</span>
                                        <span v-else-if="lang == 'th'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_th }}</span>
                                        <span v-else-if="lang == 'jp'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_jp }}</span>
                                        <span v-else-if="lang == 'kr'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_kr }}</span>
                                        <span v-else-if="lang == 'in'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_in }}</span>
                                        <span v-else style="font-size: 14px;;color: #b1bad3">{{ item.name }}</span>
                                    </div>
                                    <div>输赢</div>
                                    <div style="display: flex;justify-content: end;align-items: baseline;">
                                        <div v-if="Number(item.payout) - Number(item.bet) < 0" style="text-align: right">
                                            <span style="color: #45d91e ;font-size: 14px;;">{{ (Number(item.payout) - Number(item.bet)).toFixed(2) }}</span>
                                        </div>
                                        <div v-else  style="text-align: right">
                                            <span style="color: #f00;font-size: 14px;;"> {{ (Number(item.payout) - Number(item.bet)).toFixed(2) }}</span>
                                        </div>
                                        <!--隐藏币种-->
                                        <!--                                        <span style="color: #b1bad3;font-size: 14px;;margin-left: 5px;">-->
                                        <!--                                            {{ item.ccy }}-->
                                        <!--                                        </span>-->
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Scroll>
                </TabPane>
                <TabPane :label="$t('uc.finance.xx131')" name="FB" style="padding-left: 10px;padding-right: 10px;">
                    <div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;"
                         v-if="showxxy==true">
                        <!-- <img src="../assets/logo/norec.png" width="100"  alt=""> -->
                        <span style="font-size: 14px;;color: #b1bad3; ">暂无记录</span>
                    </div>
                    <Scroll :on-reach-bottom="nextPage" :height=realHeight>
                        <div dis-hover v-for="(item, index) in datalist" :key="index" :class="index%2 == 0? 'active': 'notactive'">
                            <div style="width: 100%;display: flex;flex-direction: column;">
                                <div class="xxx1" style="display: flex;width: 100%;justify-content: space-between;">
                                    <div>
                                        <span style="font-size: 14px;;color: #b1bad3;"> {{ item.thirdNo }} </span>
                                        <img src="../assets/logo/copy.png" width="10" height="10"
                                             v-clipboard:copy="item.thirdNo" v-clipboard:success="onCopy"
                                             v-clipboard:error="onError" alt="">
                                    </div>
                                </div>
                                <div class="xxx2">
                                    <span style="font-size: 14px;;color: #b1bad3;">{{ item.getTime }}</span>
                                    <div>投注</div>
                                    <span style="color: #b1bad3;font-size: 14px;;">{{ item.bet }}</span>
                                </div>
                                <div class="xxx3">
                                    <span style="font-size: 14px;;color: #b1bad3;margin-right: 1px;text-align: left;">{{ $t('new.Sports') }}</span>
                                    <!--                                    <div>-->
                                    <!--                                        <span style="font-size: 14px;;color: #b1bad3;">{{ $t('uc.finance.xx211') }}</span>-->
                                    <!--                                        <span v-if="Number(item.payout) < 0" style="color: #45d91e ;font-size: 14px;;"> {{ Number(item.maxWinAmount).toFixed(2) }} {{ item.ccy }}</span>-->
                                    <!--                                        <span v-else style="color: #f00;font-size: 14px;;">{{ Number(item.maxWinAmount).toFixed(2)}}</span>-->
                                    <!--                                    </div>-->
                                    <div>输赢</div>
                                    <div v-if="Number(item.status) == 5">
                                        <span v-if="handleStatus(item) < 0" style="color: #45d91e ;font-size: 14px;"> {{ handleStatus(item) }}</span>
                                        <span v-else style="color: #f00;font-size: 14px;;">{{ handleStatus(item) }}</span>
                                    </div>
                                    <div v-else-if="Number(item.status) == 4">
                                        <!--                                        <span style="color: transparent;font-size: 14px;;">{{ handleStatus(item) }}</span>-->
                                        <span style="font-size: 14px;color: #b1bad3">{{ handOU(item.status) }} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Scroll>
                </TabPane>
                <TabPane :label="$t('uc.finance.xx1311')" name="FB-LIVE"
                         style="padding-left: 10px;padding-right: 10px;">

                    <div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;"
                         v-if="showxxy==true">
                        <!-- <img src="../assets/logo/norec.png" width="100"  alt=""> -->
                        <span style="font-size: 14px;;color: #b1bad3; ">暂无记录</span>
                    </div>

                    <Scroll :on-reach-bottom="nextPage" :height=realHeight>
                        <div dis-hover v-for="(item, index) in datalist" :key="index"
                             :class="index%2 == 0? 'active': 'notactive'">
                            <div style="width: 100%;display: flex;flex-direction: column;">
                                <div>
                                    <div class="xxx1">
                                        <span style="font-size: 14px;;color: #b1bad3;padding-right: 4px">{{ item.thirdNo }} </span>
                                        <img src="../assets/logo/copy.png" v-clipboard:copy="item.thirdNo" v-clipboard:success="onCopy" v-clipboard:error="onError" width="10" height="10" alt="">
                                    </div>
                                    <div class="xxx2">
                                        <span style="font-size: 14px;;color: #b1bad3;">{{ item.endTime }}</span>
                                        <div>投注</div>
                                        <span style="color: #b1bad3;font-size: 14px;;">{{ Number(item.bet).toFixed(2) }}</span>
                                    </div>
                                    <div class="xxx3">
                                        <span v-if="item.thirdPlatform === 'FB-LIVE'" style="font-size: 14px;;color: #b1bad3;margin-right: 1px;">{{ parseGame(item) }}</span>
                                        <div v-else>
                                        <span v-if="lang == 'cn'"
                                              style="font-size: 14px;;color: #b1bad3">{{ item.name_cn }}</span>
                                            <span v-else-if="lang == 'vn'"
                                                  style="font-size: 14px;;color: #b1bad3">{{ item.name_vie }}</span>
                                            <span v-else-if="lang == 'th'"
                                                  style="font-size: 14px;;color: #b1bad3">{{ item.name_th }}</span>
                                            <span v-else-if="lang == 'jp'"
                                                  style="font-size: 14px;;color: #b1bad3">{{ item.name_jp }}</span>
                                            <span v-else-if="lang == 'kr'"
                                                  style="font-size: 14px;;color: #b1bad3">{{ item.name_kr }}</span>
                                            <span v-else-if="lang == 'in'"
                                                  style="font-size: 14px;;color: #b1bad3">{{ item.name_in }}</span>
                                            <span v-else style="font-size: 14px;;color: #b1bad3">{{ item.name }}</span>
                                        </div>
                                        <div>输赢</div>
                                        <div style="display: flex;justify-content: end;align-items: baseline;">
                                            <div v-if="Number(item.payout) - Number(item.bet) < 0" style="text-align: right">
                                                <span style="color: #45d91e ;font-size: 14px;;">{{ (Number(item.payout) - Number(item.bet)).toFixed(2) }}</span>
                                            </div>
                                            <div v-else  style="text-align: right">
                                                <span style="color: #f00;font-size: 14px;;"> {{ (Number(item.payout) - Number(item.bet)).toFixed(2) }}</span>
                                            </div>
                                            <!--隐藏币种-->
                                            <!--                                        <span style="color: #b1bad3;font-size: 14px;;margin-left: 5px;">-->
                                            <!--                                            {{ item.ccy }}-->
                                            <!--                                        </span>-->
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Scroll>

                </TabPane>
            </Tabs>
        </div>
        <Drawer :title="$t('uc.finance.xx3')" placement="bottom" v-model="value8" height="30">
            <RadioGroup v-model="commonstatus">
                <Radio label="0">{{ $t('common.exchangeAll') }}</Radio>
                <Radio label="1">{{ $t('uc.finance.xx137') }}</Radio>
                <Radio label="2">{{ $t('uc.finance.xx138') }}</Radio>
                <Radio label="3">{{ $t('uc.finance.xx139') }}</Radio>
                <Radio label="4">{{ $t('uc.finance.xx140') }}</Radio>
            </RadioGroup>
            <Button type="primary" long style="margin-top: 20px;" @click="closebtm">{{ $t('common.ok') }}</Button>
        </Drawer>
        <calendar :show.sync="calendarShow" :defaultDate="defaultDate" mode="during" @change="onChange"
                  style="color: #0073ee;"/>
    </div>
</template>

<script>

// import TodoList from '@/components/TodoList'
import dayjs from 'dayjs';
import {checkIsJSON} from '@/config/index'
import JDB from '../../public/jdb.json'
// import PG from '../../public/PG.json'
import PP from '../../public/PP.json'
import jili from '../../public/jili.json'
// import evo from '../../public/evoL.json'
// import PPl from '../../public/PPl.json'

// import inobounce from 'inobounce'

export default {

    data() {
        return {
            sel: 'ALL',
            selList: [
                {val: 'ALL', con: 'ALL'},
                {val: 'PragmaticPlay', con: 'PragmaticPlay'},
                {val: 'JILI', con: 'jili'},
                {val: 'JDB', con: 'jdb'},
                {val: 'FB', con: 'FB'},
                {val: 'FB-LIVE', con: 'FB-LIVE'},
            ],
            datalist: [],
            datalist1: [],
            startDate: '',
            endDate: '',
            defaultDate: [dayjs(), dayjs().add(-7, 'day')],
            totalregist: '',
            totalwin: 0,
            totalbet: 0,
            totalValidBet: 0,
            calendarShow: false,
            currentPage: 1,
            totalPage: 0,
            isActive: 0,
            iSportTouched: false,
            isGameTouched: false,
            iSportSubActive: 0,
            isGameSubActive: 2,
            realHeight: 0,
            isReachBottom: false,
            sportTab: 'sp1',
            gameTab: 'g1',
            isSport: false,
            windowHeight: 0,
            currentName: '',
            value8: false,
            rechargestatus: '',
            withdrawstatus: '',
            commonstatus: '3',
            currentab: '',
            showxxy: '',
            lang: ''
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.member
        }
    },
    mounted() {

        this.windowHeight = document.documentElement.clientHeight;
        console.log('yyyyyy ' + this.windowHeight);

        this.realHeight = Number(this.windowHeight) - 100
        console.log('xcxcxx ' + this.realHeight);
        // if (browser().ios) {
        document.getElementById('outbody').style.overflow = 'hidden'
        // }

        if (this.$i18n.locale === 'en-US') this.lang = 'en'
        if (this.$i18n.locale === 'zh-CN') this.lang = 'cn'
        if (this.$i18n.locale === 'vi-VN') this.lang = 'vn'
    },
    watch: {
        sel(newVal, old) {
            // if (data == 'FB') {
            //     this.getsubData('sp1')
            // } else if (data == 'PP' || data === 'PragmaticPlay') {
            //     this.getsubData('g1')
            // } else if (data == 'jili' || data === 'JILI') {
            //     this.getsubData('g2')
            // } else if (data == 'jdb' || data === 'JDB') {
            //     this.getsubData('g3')
            // }
            // if (data == 'FB-LIVE') {
            //     this.getsubData('sp11')
            // }
            if (newVal === 'FB') newVal = 'FB'
            if (newVal === 'PragmaticPlay') newVal = 'PP'
            if (newVal === 'JILI') newVal = 'jili'
            if (newVal === 'JDB') newVal = 'jdb'
            if (newVal === 'FB-LIVE') newVal = 'FB-LIVE'
            this.currentab = newVal
            this.handletabs(newVal)
        }
    },

    created() {


        // if (browser().ios) {
        //   document.getElementById('outbody').style.overflow = 'hidden'
        // }
        // this.endDate = this.defaultDate[0].format('YYYY-MM-DD')
        // this.startDate = this.defaultDate[1].format('YYYY-MM-DD')
        // this.currentPage = 1
        // this.initGtCaptcha();
        // this.getPokeGameList()

        this.endDate = this.defaultDate[0].format('YYYY-MM-DD')
        this.startDate = this.defaultDate[1].format('YYYY-MM-DD')
        this.currentPage = 1
        this.currentName = 'ALL'
        this.currentab = 'ALL'
        this.initGtCaptcha('ALL')
        this.iSportSubActive = 0


    },

    beforeDestroy() {
        // inobounce.disable()
        document.getElementById('outbody').style.overflow = ''
    },

    methods: {

        parseGame(item) {
            if (item.thirdPlatform != 'FB-LIVE') return
            let xx = ''
            let game = item.gameId
            let zz = game.split('-')
            if (zz[1].includes('BAC')) {
                xx += this.$t("uc.finance.game92")
            } else if (zz[1].includes('DTB')) {
                xx += this.$t("uc.finance.game93")
            } else if (zz[1].includes('SIC')) {
                xx += this.$t("uc.finance.game94")
            } else if (zz[1].includes('NIU')) {
                xx += this.$t("uc.finance.game96")
            } else if (zz[1].includes('ROU')) {
                xx += this.$t("uc.finance.game97")
            } else if (zz[1].includes('GFLW')) {
                xx += this.$t("uc.finance.game98")
            } else if (zz[1].includes('SD')) {
                xx += this.$t("uc.finance.game99")
            } else if (zz[1].includes('BJ')) {
                xx += this.$t("uc.finance.game100")
            } else if (zz[1].includes('SNIU')) {
                xx += this.$t("uc.finance.game101")
            } else if (zz[1].includes('ANB')) {
                xx += this.$t("uc.finance.game102")
            } else if (zz[1].includes('FT')) {
                xx += this.$t("uc.finance.game103")
            } else if (zz[1].includes('T')) {
                xx += this.$t("uc.finance.game104")
            }

            return xx
        },

        closebtm() {
            this.value8 = false
            this.currentPage = 1
            this.isReachBottom = false
            this.initGtCaptcha(this.currentName)

            // if (this.currentName == 'CMD体育') {
            //     this.initGtCaptcha('CMD体育')
            // }
        },

        handletabs(data) {

            // {val: 'PragmaticPlay',con:'PragmaticPlay'},
            // {val: 'JILI',con:'jili'},
            // {val: 'JDB',con:'jdb'},
            // {val: 'FB',con:'FB'},
            // {val: 'FB-LIVE',con:'FB-LIVE'},
            this.currentab = data
            console.log('--------------------')
            console.log(this.currentab)
            if (data == 'FB') {
                this.getsubData('sp1')
            } else if (data == 'PP' || data === 'PragmaticPlay') {
                this.getsubData('g1')
            } else if (data == 'jili' || data === 'JILI') {
                this.getsubData('g2')
            } else if (data == 'jdb' || data === 'JDB') {
                this.getsubData('g3')
            }
            if (data == 'FB-LIVE') {
                this.getsubData('sp11')
            }
            if (data == 'ALL') {
                this.getsubData('all')
            }
        },

        otherpicke() {
            this.value8 = true
        },
        selfchoose() {
            this.isGameSubActive = 4
            this.popdata()
        },

        mixTextOverflow(text) {
            if (text.replace(/[\u4e00-\u9fa5]/g, 'aa').length <= 20) {
                return text
            } else {
                var _length = 0
                var outputText = ''
                for (var i = 0; i < text.length; i++) {
                    if (/[\u4e00-\u9fa5]/.test(text[i])) {
                        _length += 2
                    } else {
                        _length += 1
                    }
                    if (_length > 20) {
                        break
                    } else {
                        outputText += text[i]
                    }
                }
                return outputText + '...'
            }
        },

        limitWords(txt) {
            var str = txt;

            if (str.length > 20) {
                str = str.substr(0, 20) + '...';

            }
            return str;
        },

        gotoDetail(item) {
            // window.location.href = item.betDetail
            // window.open(item.betDetail,"self")

            this.getHomeName(item)


        },

        onCopy(e) {
            console.log('' + e)
            this.$Message.success(
                this.$t("uc.finance.copysuccess")
            );
        },
        onError() {
            this.$Message.error(this.$t("uc.finance.copyfailed"));
        },

        gameOddType(item) {
            let xx = ''
            switch (item) {
                case 1:
                    xx = this.$t("uc.finance.xx207")
                    break;
                case 2:
                    xx = this.$t("uc.finance.xx208")
                    break;
                case 3:
                    xx = this.$t("uc.finance.xx209")
                    break;
                case 4:
                    xx = this.$t("uc.finance.xx210")
                    break;
                default:
                    break;
            }

            return xx
        },

// handlePPL(game){
//   let xx = ''


//   if (PPl[game]) {
//             xx += '/'+PPl[game].GameName_cn

//           }
//   return xx
// },
// handleEVO(game){
// let xx = ''


// if (evo[game]) {
//             xx += '/'+evo[game].GameName_cn

//           }
// return xx
// },


        handleStatus(item) {
            let xx = ''
            xx = Number(item.payout) - Number(item.bet)

            return Number(xx).toFixed(2)
        },

// xx212:'已创建',
//               xx213:'确认中',
//               xx214: '已拒绝',
//               xx215: '已取消',
//               xx216: '已确认',
//               xx217: '已结算'


        handFBLive(ou) {
            let xxy = ''
            switch (Number(ou)) {
                case 0:
                    xxy = this.$t('uc.finance.xx198')
                    break;
                case 1:
                    xxy = this.$t('uc.finance.xx217')
                    break;
                case -8:
                    xxy = this.$t('uc.finance.xx215')
                    break;

                default:
                    break;
            }
            return xxy
        },

        handOU(ou) {
            let xxy = ''
            switch (Number(ou)) {
                case 0:
                    xxy = this.$t('uc.finance.xx212')
                    break;
                case 1:
                    xxy = this.$t('uc.finance.xx213')
                    break;
                case 2:
                    xxy = this.$t('uc.finance.xx214')
                    break;
                case 3:
                    xxy = this.$t('uc.finance.xx215')
                    break;
                case 4:
                    xxy = this.$t('uc.finance.xx216')
                    break;
                case 5:
                    xxy = this.$t('uc.finance.xx217')
                    break;
                default:
                    break;
            }
            return xxy
        },


        subGame(name_1) {
            this.endDate = this.defaultDate[0].format('YYYY-MM-DD')
            this.startDate = this.defaultDate[1].format('YYYY-MM-DD')
            this.currentPage = 1
            this.initGtCaptcha(name_1)
            this.isGameSubActive = 0
        },


        subAll() {
            this.iSportSubActive = 0
        },
        subSingle() {
            this.iSportSubActive = 1
        },
        subMore() {
            this.iSportSubActive = 2
        },
        subChamp() {
            this.iSportSubActive = 3
        },


        touchSportBut() {
            this.iSportTouched = !this.iSportTouched
        },

        touchGameBut() {
            this.isGameTouched = !this.isGameTouched
        },
        sportRecord() {
            this.isActive = 0
            this.iSportTouched = false
            this.isGameTouched = false
            this.sportTab = 'sp1'
            this.currentName = 'CMD体育'
            this.rechargestatus = ''
            this.withdrawstatus = ''
            this.commonstatus = ''
            this.subFB()
        },
        gameRecord() {
            this.isActive = 1
            this.iSportTouched = false
            this.isGameTouched = false
            this.rechargestatus = ''
            this.withdrawstatus = ''
            this.commonstatus = ''
            this.gameTab = 'g1'
            this.currentName = '乐游'

            this.subPP()
        },
        results() {
            this.isActive = 2
            this.iSportTouched = false
            this.isGameTouched = false
        },

        nextPage() {

            if (this.isReachBottom == true) {
                return
            }
            let that = this
            let params = {}
            params.beginDateStr = this.startDate
            params.endDateStr = this.endDate
            this.currentPage += 1
            params.pageNum = this.currentPage
            params.pageSize = 20
            params.thirdPlatform = this.currentName
            params.ccy = this.userInfo.remark


            this.$http.post(this.host + this.api.uc.mdboGameRecordList, params).then(response => {
                var resp = response.body;

                if (resp.code == "0000") {
                    if (this.currentName == 'FB' || this.currentName == 'FB-LIVE') {
                        // that.datalist = resp.data.list
                        resp.data.list.forEach(element => {
                            if (element.betDetail) {
                                element.maxWinAmount = JSON.parse(element.betDetail).maxWinAmount
                            }
                            that.datalist.push(element)
                        });


                    } else if (this.currentName == 'PP' || this.currentName == 'jdb' || this.currentName == 'jili') {

                        resp.data.list.forEach(element => {
                            if (element.thirdPlatform == 'PP') {
                                Object.keys(PP).forEach(element1 => {
                                    if (element.gameId.includes(element1)) {
                                        element.name = that.limitWords(PP[element1].gameName)
                                        element.name_cn = that.mixTextOverflow(PP[element1].gameName_cn)
                                        element.name_th = that.mixTextOverflow(PP[element1].gameName_th)
                                        element.name_vie = that.mixTextOverflow(PP[element1].gameName_vie)
                                        element.name_jp = that.mixTextOverflow(PP[element1].gameName_jp)
                                        element.name_kr = that.mixTextOverflow(PP[element1].gameName_kr)
                                        element.name_in = that.mixTextOverflow(PP[element1].gameName_id)
                                        element.gameType = this.ParseType(PP[element1].type)
                                    }
                                });

                            } else if (element.thirdPlatform == 'JDB') {
                                element.name = that.limitWords(JDB[element.gameId].gameName)
                                element.name_cn = that.mixTextOverflow(JDB[element.gameId].gameName_cn)
                                element.name_th = that.mixTextOverflow(JDB[element.gameId].gameName_th)
                                element.name_vie = that.mixTextOverflow(JDB[element.gameId].gameName_vie)
                                element.name_jp = that.mixTextOverflow(JDB[element.gameId].gameName_jp)
                                element.name_kr = that.mixTextOverflow(JDB[element.gameId].gameName_kr)
                                element.name_in = that.mixTextOverflow(JDB[element.gameId].gameName_id)

                            } else if (element.thirdPlatform == 'JILI') {
                                element.name = that.limitWords(jili[element.gameId].gameName)
                                element.name_cn = that.mixTextOverflow(jili[element.gameId].gameName_cn)
                                element.name_th = that.mixTextOverflow(jili[element.gameId].gameName_th)
                                element.name_vie = that.mixTextOverflow(jili[element.gameId].gameName_vie)
                                element.name_jp = that.mixTextOverflow(jili[element.gameId].gameName_jp)
                                element.name_kr = that.mixTextOverflow(jili[element.gameId].gameName_kr)
                                element.name_in = that.mixTextOverflow(jili[element.gameId].gameName_id)


                            }
                            that.datalist1.push(element)
                        });

                    } else {

                        resp.data.list.forEach(element => {
                            if (element.thirdPlatform == 'FB' && element.betDetail) {
                                element.maxWinAmount = JSON.parse(element.betDetail).maxWinAmount
                            } else if (element.thirdPlatform == 'PP') {
                                Object.keys(PP).forEach(element1 => {
                                    if (element.gameId.includes(element1)) {
                                        element.name = that.limitWords(PP[element1].gameName)
                                        element.name_cn = that.mixTextOverflow(PP[element1].gameName_cn)
                                        element.name_th = that.mixTextOverflow(PP[element1].gameName_th)
                                        element.name_vie = that.mixTextOverflow(PP[element1].gameName_vie)
                                        element.name_jp = that.mixTextOverflow(PP[element1].gameName_jp)
                                        element.name_kr = that.mixTextOverflow(PP[element1].gameName_kr)
                                        element.name_in = that.mixTextOverflow(PP[element1].gameName_id)
                                        element.gameType = this.ParseType(PP[element1].type)
                                    }
                                });

                            } else if (element.thirdPlatform == 'JDB') {
                                element.name = that.limitWords(JDB[element.gameId].gameName)
                                element.name_cn = that.mixTextOverflow(JDB[element.gameId].gameName_cn)
                                element.name_th = that.mixTextOverflow(JDB[element.gameId].gameName_th)
                                element.name_vie = that.mixTextOverflow(JDB[element.gameId].gameName_vie)
                                element.name_jp = that.mixTextOverflow(JDB[element.gameId].gameName_jp)
                                element.name_kr = that.mixTextOverflow(JDB[element.gameId].gameName_kr)
                                element.name_in = that.mixTextOverflow(JDB[element.gameId].gameName_id)

                            } else if (element.thirdPlatform == 'JILI') {
                                element.name = that.limitWords(jili[element.gameId].gameName)
                                element.name_cn = that.mixTextOverflow(jili[element.gameId].gameName_cn)
                                element.name_th = that.mixTextOverflow(jili[element.gameId].gameName_th)
                                element.name_vie = that.mixTextOverflow(jili[element.gameId].gameName_vie)
                                element.name_jp = that.mixTextOverflow(jili[element.gameId].gameName_jp)
                                element.name_kr = that.mixTextOverflow(jili[element.gameId].gameName_kr)
                                element.name_in = that.mixTextOverflow(jili[element.gameId].gameName_id)


                            }
                            that.datalist2.push(element)
                        });

                    }

                    that.totalPage = Number(resp.data.total)
                } else
                    this.$Notice.error({
                        title: this.$t("common.tip"),
                        desc: resp.message
                    });
            });
        },


        goback() {
            //获取个人安全信息
            document.getElementById('outbody').style.overflow = ''
            this.$router.back(-1)

        },
        subFB() {
            this.endDate = this.defaultDate[0].format('YYYY-MM-DD')
            this.startDate = this.defaultDate[1].format('YYYY-MM-DD')
            this.currentPage = 1
            this.initGtCaptcha('FB')
            this.iSportSubActive = 0
        },

        subFBlive() {
            this.endDate = this.defaultDate[0].format('YYYY-MM-DD')
            this.startDate = this.defaultDate[1].format('YYYY-MM-DD')
            this.currentPage = 1
            this.initGtCaptcha('FB-LIVE')
            this.iSportSubActive = 0
        },

        getsubData(name) {

            switch (name) {
                case 'sp1':

                    this.rechargestatus = ''
                    this.withdrawstatus = ''
                    this.commonstatus = ''
                    this.currentName = 'FB'
                    this.subFB()
                    break;
                case 'sp11':

                    this.rechargestatus = ''
                    this.withdrawstatus = ''
                    this.commonstatus = ''
                    this.currentName = 'FB-LIVE'
                    this.subFBlive()
                    break;
                case 'sp2':
                    this.rechargestatus = ''
                    this.withdrawstatus = ''
                    this.commonstatus = ''
                    this.currentName = 'saba体育'
                    this.subSaba()
                    break;
                case 'g1':
                    this.rechargestatus = ''
                    this.withdrawstatus = ''
                    this.commonstatus = ''
                    this.currentName = 'PP'
                    this.subGame(this.currentName)

                    break;
                case 'g2':
                    this.rechargestatus = ''
                    this.withdrawstatus = ''
                    this.commonstatus = ''
                    this.currentName = 'jili'
                    this.subGame(this.currentName)

                    break;
                case 'g3':
                    this.rechargestatus = ''
                    this.withdrawstatus = ''
                    this.commonstatus = ''
                    this.currentName = 'jdb'
                    this.subGame(this.currentName)

                    break;
                case 'all':
                    this.rechargestatus = ''
                    this.withdrawstatus = ''
                    this.commonstatus = ''
                    this.currentName = 'ALL'
                    this.subGame(this.currentName)
                    break
                default:
                    break;
            }
        },
        onChange(date) {
            if (date[0] != null && date[1] != null) {
                this.startDate = date[0].format('YYYY-MM-DD')

                this.endDate = date[1].format('YYYY-MM-DD')


                this.initGtCaptcha()
            }

        },

        initGtCaptcha(platform) {
            let that = this
            let params = {}

            params.pageNum = 1
            this.currentPage = 1
            params.pageSize = 20
            if (platform != 'ALL') {
                params.thirdPlatform = platform
            }


            this.isReachBottom = false
            params.ccy = this.userInfo.remark
            if (this.commonstatus == '1') {
                this.endDate = dayjs().format('YYYY-MM-DD')
                this.startDate = dayjs().add(-1, 'day').format('YYYY-MM-DD')
            } else if (this.commonstatus == '2') {
                this.endDate = dayjs().format('YYYY-MM-DD')
                this.startDate = dayjs().format('YYYY-MM-DD')

            } else if (this.commonstatus == '3') {
                this.endDate = dayjs().format('YYYY-MM-DD')
                this.startDate = dayjs().add(-7, 'day').format('YYYY-MM-DD')

            } else {
                this.endDate = dayjs().format('YYYY-MM-DD')
                this.startDate = dayjs().add(-30, 'day').format('YYYY-MM-DD')

            }

            params.beginDateStr = this.startDate
            params.endDateStr = this.endDate
            if (platform == 'FB' || platform == 'FB-LIVE') {
                this.datalist = []

            } else if (platform == 'PP' || platform == 'jili' || platform == 'jdb') {
                this.datalist1 = []


            } else {
                this.datalist2 = []
            }


            this.$http.post(this.host + this.api.uc.mdboGameRecordList, params).then(response => {
                var resp = response.body;

                if (resp.code == "0000") {
                    if (platform == 'FB' || platform == "FB-LIVE") {
                        // that.datalist = resp.data.list
                        resp.data.list.forEach(element => {

                            if (element.thirdPlatform == 'FB' && element.betDetail) {
                                element.maxWinAmount = JSON.parse(element.betDetail).maxWinAmount
                            } else if (element.thirdPlatform == 'FB-LIVE' && element.sonDetail) {
                                console.log('sdfsdfd');

                            }
                            that.datalist.push(element)
                        });

                        if (that.datalist.length <= 0) {
                            that.showxxy = true
                        } else
                            that.showxxy = ''
                    } else if (platform == 'PP' || platform == 'jili' || platform == 'jdb') {

                        resp.data.list.forEach(element => {
                            if (element.thirdPlatform == 'PP') {
                                Object.keys(PP).forEach(element1 => {
                                    if (element.gameId.includes(element1)) {
                                        element.name = that.limitWords(PP[element1].gameName)
                                        element.name_cn = that.mixTextOverflow(PP[element1].gameName_cn)
                                        element.name_th = that.mixTextOverflow(PP[element1].gameName_th)
                                        element.name_vie = that.mixTextOverflow(PP[element1].gameName_vie)
                                        element.name_jp = that.mixTextOverflow(PP[element1].gameName_jp)
                                        element.name_kr = that.mixTextOverflow(PP[element1].gameName_kr)
                                        element.name_in = that.mixTextOverflow(PP[element1].gameName_id)

                                        element.gameType = this.ParseType(PP[element1].type)
                                    }
                                });

                            } else if (element.thirdPlatform == 'JDB') {
                                element.name = that.limitWords(JDB[element.gameId].gameName)
                                element.name_cn = that.mixTextOverflow(JDB[element.gameId].gameName_cn)
                                element.name_th = that.mixTextOverflow(JDB[element.gameId].gameName_th)
                                element.name_vie = that.mixTextOverflow(JDB[element.gameId].gameName_vie)
                                element.name_jp = that.mixTextOverflow(JDB[element.gameId].gameName_jp)
                                element.name_kr = that.mixTextOverflow(JDB[element.gameId].gameName_kr)
                                element.name_in = that.mixTextOverflow(JDB[element.gameId].gameName_id)

                            } else if (element.thirdPlatform == 'JILI') {
                                element.name = that.limitWords(jili[element.gameId].gameName)
                                element.name_cn = that.mixTextOverflow(jili[element.gameId].gameName_cn)
                                element.name_th = that.mixTextOverflow(jili[element.gameId].gameName_th)
                                element.name_vie = that.mixTextOverflow(jili[element.gameId].gameName_vie)
                                element.name_jp = that.mixTextOverflow(jili[element.gameId].gameName_jp)
                                element.name_kr = that.mixTextOverflow(jili[element.gameId].gameName_kr)
                                element.name_in = that.mixTextOverflow(jili[element.gameId].gameName_id)

                            }
                            that.datalist1.push(element)
                        });

                        // that.datalist1 = resp.data.list.map(item => ({
                        //     ...item,
                        //     name: PP[item.gameId].gameName,
                        //     name_cn: PP[item.gameId].gameName_cn,
                        //     // value: item.gameID,
                        //     gameType: this.ParseType(PP[item.gameId].type),
                        //   }))
                        if (that.datalist1.length <= 0) {
                            that.showxxy = true
                        } else
                            that.showxxy = ''

                    } else {
                        resp.data.list.forEach(element => {

                            if (element.thirdPlatform == 'FB' && element.betDetail) {
                                element.maxWinAmount = JSON.parse(element.betDetail).maxWinAmount
                            } else if (element.thirdPlatform == 'PP') {
                                Object.keys(PP).forEach(element1 => {
                                    if (element.gameId.includes(element1)) {
                                        element.name = that.limitWords(PP[element1].gameName)
                                        element.name_cn = that.mixTextOverflow(PP[element1].gameName_cn)
                                        element.name_th = that.mixTextOverflow(PP[element1].gameName_th)
                                        element.name_vie = that.mixTextOverflow(PP[element1].gameName_vie)
                                        element.name_jp = that.mixTextOverflow(PP[element1].gameName_jp)
                                        element.name_kr = that.mixTextOverflow(PP[element1].gameName_kr)
                                        element.name_in = that.mixTextOverflow(PP[element1].gameName_id)

                                        element.gameType = this.ParseType(PP[element1].type)
                                    }
                                });

                            } else if (element.thirdPlatform == 'JDB') {
                                element.name = that.limitWords(JDB[element.gameId].gameName)
                                element.name_cn = that.mixTextOverflow(JDB[element.gameId].gameName_cn)
                                element.name_th = that.mixTextOverflow(JDB[element.gameId].gameName_th)
                                element.name_vie = that.mixTextOverflow(JDB[element.gameId].gameName_vie)
                                element.name_jp = that.mixTextOverflow(JDB[element.gameId].gameName_jp)
                                element.name_kr = that.mixTextOverflow(JDB[element.gameId].gameName_kr)
                                element.name_in = that.mixTextOverflow(JDB[element.gameId].gameName_id)

                            } else if (element.thirdPlatform == 'JILI') {
                                element.name = that.limitWords(jili[element.gameId].gameName)
                                element.name_cn = that.mixTextOverflow(jili[element.gameId].gameName_cn)
                                element.name_th = that.mixTextOverflow(jili[element.gameId].gameName_th)
                                element.name_vie = that.mixTextOverflow(jili[element.gameId].gameName_vie)
                                element.name_jp = that.mixTextOverflow(jili[element.gameId].gameName_jp)
                                element.name_kr = that.mixTextOverflow(jili[element.gameId].gameName_kr)
                                element.name_in = that.mixTextOverflow(jili[element.gameId].gameName_id)

                            }
                            that.datalist2.push(element)
                            if (that.datalist2.length <= 0) {
                                that.showxxy = true
                            } else
                                that.showxxy = ''

                        });
                    }
                    that.getTotal(platform)

                    // that.totalbet = resp.data.totalBet
                    // that.totolwin = resp.data.totalWin
                    // that.totalValidBet = resp.data.totalValidBet
                    that.totalPage = Number(resp.data.total)
                } else
                    that.$Notice.error({
                        title: that.$t("common.tip"),
                        desc: resp.message
                    });
            });
        },

        popdata() {
            this.calendarShow = !this.calendarShow
        },

        ParseType(type) {
            let ss = ''
            switch (type) {
                case 'Video Slots':
                    ss = this.$t("uc.finance.game85")
                    break;
                case 'Live games':
                case 'Blackjack':
                    ss = this.$t("uc.finance.game81")
                    break;
                default:
                    break;
            }

            return ss
        },


        handledetail(str, ele1) {
            let arr1 = str.split("}-");
            arr1.forEach(element => {
                if (checkIsJSON(element + '}')) {
                    let js1 = JSON.parse(element + '}')
                    Object.getOwnPropertyNames(js1).forEach(function (key) {
                        ele1[key] = js1[key]
                    });

                } else {
                    let arr2 = element.split('-')

                    let aar21 = arr2[0].split(':')
                    ele1.betNo = aar21[1]
                    let aar22 = arr2[1].split(':')
                    ele1.playID = aar22[1]
                    let aar23 = arr2[2].split(':')
                    ele1.table = aar23[1]
                }

            });
            console.log("xxx" + ele1);
        },

        handleAGdetail(str, ele1) {
            let arr1 = str.split("=");
            arr1 = arr1[1]
            arr1 = arr1.split('-')
            for (let index = 0; index < arr1.length; index++) {
                const element = arr1[index];
                if (index == 0) {
                    let arr2 = element.split(',')
                    ele1.Playerbillno = arr2[0]
                    ele1.sport = arr2[1]
                } else if (index == 1) {
                    ele1.Extbillno = element
                }
            }
        },
        // "BJ Baccarat-25,20-Settled."

        handleGBdetail(str, ele1) {
            console.log(str, ele1);
        },

        handleIBdetail(str, ele1) {
            let arr1 = str.split("-");
            // MatchId -LeagueName                    -HomeIDName  -AwayIDName    -HomeScore-AwayScore-
            for (let index = 0; index < arr1.length; index++) {
                const element = arr1[index];
                if (index == 0) {
                    ele1.MatchId = element
                } else if (index == 1) {
                    ele1.LeagueName = element
                } else if (index == 2) {
                    ele1.HomeIDName = element
                } else if (index == 3) {
                    ele1.AwayIDName = element
                } else if (index == arr1.length - 3) {
                    ele1.HomeScore = element
                } else if (index == arr1.length - 2) {
                    ele1.AwayScore = element
                }
            }
        },

        gamePlatform(platform, gid) {

            let xx = ''
            switch (platform) {
                case '1085':
                    xx = 'JDB'
                    if (this.$i18n.locale == 'zh-CN') {
                        xx += '电子'
                    }
                    break;

                case '1046':
                    xx = 'IBC'

                    if (this.$i18n.locale == 'zh-CN') {
                        xx += '体育'
                    }
                    break
                case '1002':
                    xx = 'Evo'
                    if (this.$i18n.locale == 'zh-CN') {
                        xx += '真人'
                    }
                    break
                case '1003':
                    xx = 'All Bet'
                    if (this.$i18n.locale == 'zh-CN') {
                        xx += '真人'
                    }
                    break
                case '1020':
                    xx = 'WM'
                    if (this.$i18n.locale == 'zh-CN') {
                        xx += '/真人'
                    }
                    break
                case '1109':
                    xx = 'Netent'
                    if (this.$i18n.locale == 'zh-CN') {
                        xx += '电子'
                    }
                    break
                case '1006':
                    xx = 'PP'
                    if (this.$i18n.locale == 'zh-CN') {
                        // if ( !PP[gid]) {
                        //   xx += '/真人'
                        // }else
                        // xx += '电子'

                    }
                    break

                case '1007':
                    xx = 'PG'
                    if (this.$i18n.locale == 'zh-CN') {
                        xx += '电子'
                    }
                    break

                case '1001':
                    xx = 'AG/真人'
                    break

                default:
                    break;
            }
            return xx
        },


        handleWM(xxxu) {
            let xx = ''
            switch (xxxu) {
                case 'Baccarat':
                    xx = '/百家乐'
                    break;
                case 'Dragon&Tiger':
                    xx = '/龙虎'
                    break;
                case 'NiuNiu':
                    xx = '/牛牛'
                    break;
                case 'Sedie':
                    xx = '/色碟'
                    break;
                case 'fish-prawn-crab':
                    xx = '/鱼虾蟹'
                    break;
                case 'Fantan':
                    xx = '/番摊'
                    break;
                case 'AndarBahar':
                    xx = '/安達巴'
                    break;
                case 'Sicbo':
                    xx = '/骰宝'
                    break;
                default:
                    break;
            }

            return xx
        },


        handleAg(xxx) {
            let xx = ''
            switch (xxx) {
                case 'BAC':
                    xx = '/百家乐'
                    break;
                case 'ROU':
                    xx = '/轮盘'
                    break;
                case 'ZJH':
                    xx = '/扎金花'
                    break;
                case 'BJ':
                    xx = '/21点'
                    break;
                case 'NN':
                    xx = '/牛牛'
                    break;
                case 'SG':
                    xx = '/三公'
                    break;
                case 'DT':
                    xx = '/龙虎'
                    break;
                case 'SHB':
                    xx = '/筛宝'
                    break;
                default:
                    break;
            }

            return xx
        },

        billType(status) {
            let xyz = ''
            switch (status) {
                case 100:
                    xyz = this.$t('uc.finance.xx71')
                    break;
                case 101:
                    xyz = this.$t('uc.finance.xx70')
                    break;
                case 102:
                    xyz = this.$t('uc.finance.xx72')
                    break;
                default:
                    break;
            }
            return xyz
        },
        getTotal(platform) {
            let params = {};

            if (platform != 'ALL') {
                params.thirdPlatform = platform
            }
            params.beginDateStr = this.startDate
            params.endDateStr = this.endDate
            params.ccy = this.userInfo.remark
            let that = this

            this.$http
                .post(this.host + this.api.uc.gameRecordTotal, params)
                .then(response => {
                    let resp = response.body

                    if (resp.code == '0000') {
                        // let temp = JSON.parse(resp.data)

                        that.totalbet = resp.data.totalBet
                        that.totalwin = resp.data.totalWin
                        that.totalValidBet = resp.data.totalTurnover
                        // console.log(temp);
                    } else {
                        // self.$Message.error(resp.mesg);
                        this.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: resp.mesg
                        });
                        // this.$Message.error(this.$t('common.logintip'));
                    }
                });
        },
    },


}
</script>

<style lang="scss" scoped>


.login_form {
    background-color: $color-background;
    height: $full-page;
    position: relative;
    overflow: hidden;
    text-align: center;
}

.box {
    position: fixed;
    top: 0;
    display: flex;
    height: 50px;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    color: $color-text-tag;
    z-index: 200;
    width: 100%;
    background-color: $color-background;
}

.head_box {
    display: flex;
    align-items: center;
    width: 100%;
    color: #b1bad3;
    font-size: 14px;;
    height: auto;
    // padding-top: 10px;
}


.body_box {
    display: flex;
    color: #b1bad3;
    font-size: 14px;;;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    // margin-left: 5%;
    // margin-top: 20px;
    height: auto;

}

.body_box1 {
    display: flex;
    color: #b1bad3;
    font-size: 14px;;;
    width: 100%;
    align-items: center;

    height: auto;

}

/deep/ .ivu-card-body {
    padding: 10px 10px;

}

.head2 {
    color: #898989;
    display: flex;
    justify-content: space-around;
    margin-top: 60px;
    width: 100%;

}

.bodyhead {
    color: #898989;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

/deep/ .ivu-card-bordered {
    border: none;
}


/deep/ .ivu-btn {
    border: none
}

.head22 {
    display: flex;
    flex-direction: column;
    font-size: 14px;;
}

.sports {

    margin-top: -15px;
}

.gamerecords {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: baseline;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

/deep/ .ivu-tabs-bar {
    border-bottom: 0px solid #dcdee2;
    margin-bottom: 10px;
    background-color: #1B2C38;
}

.active, .notactive {
    background-color: #1A2B37;
    height: 94px;
    padding: 12px;
    border-radius: 6px;
    width: 98%;
    margin: 0 auto 10px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14px !important;
}

.active1 {
    height: 220px;
    padding: 8px 10px;
    background-color: #1A2B37;
    border-radius: 5px;
}

.notactive1 {
    height: 220px;
    padding: 8px 10px;
    background-color: transparent;
    border-radius: 5px;
}

/deep/ .ivu-tabs-nav-container {
    font-size: 14px;;
}

/deep/ .ivu-tabs-nav .ivu-tabs-tab-active {
    color: #d2b965;
}


/deep/ .ivu-tabs-tab {
    color: #b1bad3;
    padding: 12px 16px
}


/deep/ .ivu-tabs-ink-bar {
    background-color: #d2b965;
}

/deep/ .ivu-divider-horizontal {
    margin: 5px 0;
}

/deep/ .ivu-divider {
    background-color: #333b4165;
}

.xxx1 {
    display: flex;
    width: 100%;
    align-items: center;
    color: #b1bad3;
}

.xxx2,.xxx3 {
    text-align: left;
    display: flex;
    color: #b1bad3;
    justify-content: space-between;

    &>:nth-child(1){
        width: 60%;
        overflow: hidden;
    }
    &>:nth-child(2){
        display: block;
        width: 60px;
    }
    &>:nth-child(3){
        flex: 1;
        text-align: right;
    }
}

//.xxx3 {
//    display: flex;
//    color: #b1bad3;
//    justify-content: space-between;
//}

/deep/ .ivu-modal-content {
    background-color: #b1bad3;
    color: #000;
}

/deep/ .ivu-select-selection {
    height: 40px;
    background-color: transparent;
}

/deep/ .ivu-select-placeholder {
    display: block;
    height: 40px !important;
    line-height: 40px !important;
    color: #b1bad3 !important;
}

/deep/ .ivu-select-selected-value {
    color: #b1bad3;
    height: 40px !important;
    line-height: 40px !important;
}

/deep/ .ivu-tabs-bar {
    display: none;
}

.top {
    color: #b1bad3;
    font-size: 14px;
    font-weight: 900;
}
</style>



