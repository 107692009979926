<template>
    <div class="nav-rights">
        <div class="box222">
            <HeaderNav></HeaderNav>
        </div>
        <template v-if="path === '/'">
            <div class="banner-panel">
                <Banner :picList="picList"></Banner>
            </div>
            <div class="game-tab-box" :class="{'top-fixed':ifFixed}">
                <div class="tab-box">
                    <div class="tab-item">
                        <!-- 100大厅  1mago推荐 2老虎机 3 真人娱乐场 6捕鱼 7桌面游戏 4小游戏  8宾果 5新游戏 -->
                        <div class="item" :class="{tabActive:gameTab === 100}" @click="changeTab(100)">
                            <img class="icon" src="@/assets/logo/ting.svg" alt="Ting SVG"/>
                            {{ $t('new.ting') }}
                        </div>
                        <div class="item" :class="{tabActive:gameTab === 1}" @click="changeTab(1,'T1')">
                            <img class="icon" src="@/assets/logo/hot.svg" alt="Hot SVG"/>
                            {{ $t('new.magoRecommendation') }}
                        </div>
                        <div class="item" :class="{tabActive:gameTab === 2}" @click="changeTab(2,'T2')">
                            <img class="icon" src="@/assets/logo/slots.svg" alt="Hot SVG"/>
                            {{ $t('new.slots') }}
                        </div>
                        <div class="item" :class="{tabActive:gameTab === 3}" @click="changeTab(3,'T3')">
                            <img class="icon" src="@/assets/logo/live.svg" alt="Hot SVG"/>
                            {{ $t('new.liveCasino') }}
                        </div>
                        <div class="item" :class="{tabActive:gameTab === 6}" @click="changeTab(6,'T6')">
                            <img class="icon" style="width: 16px" src="@/assets/logo/fishing.png" alt="Hot SVG"/>
                            {{ $t('new.fishing') }}
                        </div>
                        <div class="item" :class="{tabActive:gameTab === 7}" @click="changeTab(7,'T7')">
                            <img class="icon" src="@/assets/logo/table.svg" alt="Hot SVG"/>
                            {{ $t('new.tableGames') }}
                        </div>
                        <div class="item" :class="{tabActive:gameTab === 4}" @click="changeTab(4,'T4')">
                            <img class="icon" src="@/assets/logo/sgame.svg" alt="Hot SVG"/>
                            {{ $t('new.gameShow') }}
                        </div>
                        <div class="item" :class="{tabActive:gameTab === 8}" @click="changeTab(8,'T8')">
                            <img class="icon" src="@/assets/logo/bingo.svg" alt="Hot SVG"/>
                            {{ $t('new.bingo') }}
                        </div>
                        <div class="item" :class="{tabActive:gameTab === 5}" @click="changeTab(5,'T5')">
                            <img class="icon" src="@/assets/logo/newRelease.svg" alt="Hot SVG"/>
                            {{ $t('new.newGame') }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="ting-box" style="display: flex;flex-direction: column;">
                <div style="height: 10px;width: 100%"></div>
                <GameAllTing :gameTab="gameTab" v-if="gameTab !== 100"></GameAllTing>
                <template v-else>
                    <GameList id="T1" :loading="loading1" :txt="$t('new.magoRecommendation')" :type="1"
                              :imgBasePath="imgBasePath"
                              :gameListT1="gameListT1"></GameList>
                    <GameList id="T2" :loading="loading2" :txt="$t('new.slots')" :type="2" :imgBasePath="imgBasePath"
                              :gameListT1="gameListT2"></GameList>
                    <GameList id="T3" :loading="loading3" :txt="$t('new.liveCasinoTxt')" :type="3"
                              :imgBasePath="imgBasePath"
                              :gameListT1="gameListT3"></GameList>
                    <GameList id="T6" :loading="loading6" :txt="$t('new.fishing')" :type="6"
                              :imgBasePath="imgBasePath"
                              :gameListT1="gameListT6"></GameList>
                    <GameList id="T7" :loading="loading7" :txt="$t('new.tableGames')" :type="7"
                              :imgBasePath="imgBasePath"
                              :gameListT1="gameListT7"></GameList>
                    <GameList id="T4" :loading="loading4" :txt="$t('new.gameShow')" :type="4"
                              :imgBasePath="imgBasePath"
                              :gameListT1="gameListT4"></GameList>
                    <GameList id="T8" :loading="loading8" :txt="$t('new.bingo')" :type="8" :imgBasePath="imgBasePath"
                              :gameListT1="gameListT8"></GameList>
                    <GameList id="T5" :loading="loading5" :txt="$t('new.newGame')" :type="5"
                              :imgBasePath="imgBasePath"
                              :gameListT1="gameListT5"></GameList>
                </template>
            </div>
            <div style="height: 68px"></div>
            <FundPwd :fundPwdPopup="fundPwdPopup"></FundPwd>
        </template>
        <template v-else>
            <router-view></router-view>
        </template>

        <FootC></FootC>
        <Main-tab-bar></Main-tab-bar>
    </div>
</template>
<script>

import MainTabBar from '../components/tabbar/MainTabBar'
import Sticky from 'vuejs-sticky-directive'
import Banner from "@/components/Banner.vue";
import HeaderNav from "@/components/HeaderNav.vue";
import GameList from "@/components/GameList.vue";
import FundPwd from "@/components/FundPwd.vue";
import FootC from "@/components/FootC.vue";
import GameAllTing from "@/views/gameAllTing.vue";
import PP from '../../public/PP.json'
import jili from '../../public/jili.json'
import jdb from '../../public/jdb.json'

export default {
    components: {
        Banner,
        MainTabBar,
        HeaderNav,
        // NavTop,
        GameList,
        FundPwd,
        FootC,
        GameAllTing
    },
    directives: {Sticky},
    data() {
        return {
            ifFixed:false,
            path: '/',
            gameTab: 100,
            userInfo: null,
            fundPwdPopup: false,  //设置资金密码
            gameCategory: 1, // 2 3 4 5 6 7 8
            imgBasePath: '',
            loading1: true,
            loading2: true,
            loading3: true,
            loading4: true,
            loading5: true,
            loading6: true,
            loading7: true,
            loading8: true,
            // loading9: true,
            gameListT1: [], //
            gameListT2: [], //
            gameListT3: [], //
            gameListT4: [], //
            gameListT5: [], //
            gameListT6: [], //
            gameListT7: [], //
            gameListT8: [], //
            // gameListT9: [], //
            picList: [
                // {
                //     t1: this.$t('new.promotionTitle'),
                //     t2: this.$t('new.eventTitle'),
                //     t3: this.$t('new.bonusTitle'),
                //     t4: this.$t('new.readMore'),
                //     t5: this.$t('new.experienceNow'),
                //     path: require("../assets/banner/banner1.jpg")
                // },
                {
                    t1: this.$t('new.promotionTitle'),
                    t2: this.$t('new.firstTelegram'),
                    t3: this.$t('new.miniProgramCasino'),
                    t4: this.$t('new.followTelegram'),
                    t5: this.$t('new.enterNow'),
                    t6: this.$t('new.trialBonusChannel'),
                    path: require("../assets/banner/banner2.jpg"),
                    bg:require("../assets/banner/12.png"),
                },
                {
                    t1: this.$t('new.announcement'),
                    t2: this.$t('new.magoJoinFB'),
                    t3: this.$t('new.exclusiveAnchor'),
                    t4: this.$t('new.t4'),
                    t5: this.$t('new.playNow'),
                    path: require("../assets/banner/banner3.jpg"),
                    bg:require("../assets/banner/13.png"),
                },
            ],
        };
    },
    created() {
        this.path = this.$route.path
        if (this.path === '/gameAllTing') return
        this.init()
    },
    watch: {
        $route(newVal, old) {
            console.log(newVal)
            this.path = this.$route.path
            if (this.path === '/') {
                this.init()
            }
        }
    },
    methods: {
        init() {
            this.getSlotGameList(1)
            this.getSlotGameList(2)
            this.getSlotGameList(3)
            this.getSlotGameList(4)
            this.getSlotGameList(5)
            this.getSlotGameList(6)
            this.getSlotGameList(7)
            this.getSlotGameList(8)
            window.addEventListener("scroll", this.handleScroll); // 添加滚动监听
        },
        // 1 mago推荐 2 老虎机 3 真人娱乐场 4 小游戏  5新游戏
        getSlotGameList(type) {
            let param = {};
            param.gameCategory = type//gamecategory 必填，且不能<1
            param.pageNum = 1
            param.pageSize = 12
            this.$http
                .post(this.host + this.api.uc.gameList, param)
                .then(response => {
                    let resp = response.body
                    if (resp.code == '0000') {
                        resp.data.list.map((item, i) => {
                            if (item.gameFactory === 'Pragmatic Play' && item.gameName === 'Sic Bo') item.gameName = 'Sic Bo_pp'
                            // if(type === 5) console.log(item.gameName);
                            // 处理特殊情况，如 The Catfather 需要特殊的图片文件名
                            // if (item.gameName.toLowerCase() === 'the catfather') {
                            //     // 特定文件名的处理
                            //     item.path = require('@/assets/gameImg/' + 'The Catfather Part II.webp');
                            // } else {
                            // 默认情况下根据 gameName 动态加载图片
                            try {
                                // 使用 require 动态加载图片路径，注意 .webp 结尾
                                if (item.gameName == "Lucky Dragons" && item.gameFactory == 'JDB') {
                                    item.path = require(`@/assets/gameImg/${item.gameName + '_jdb'}.webp`)
                                } else if (item.gameName == "Hot Chilli" && item.gameFactory == 'Pragmatic Play') {
                                    item.path = require(`@/assets/gameImg/${item.gameName + '_pp'}.webp`)
                                } else if (item.gameName == "Gold Rush" && item.gameFactory == 'JILI') {
                                    item.path = require(`@/assets/gameImg/${item.gameName + '_jili'}.webp`)
                                } else
                                    item.path = require(`@/assets/gameImg/${item.gameName}.webp`);
                                if (item.gameFactory == 'JILI') {
                                    item.gameName_cn = jili[item.gameId]['gameName_cn']
                                    item.gameName_vie = jili[item.gameId]['gameName_vie']
                                    item.gameName_th = jili[item.gameId]['gameName_th']
                                    item.gameName_jp = jili[item.gameId]['gameName_jp']
                                    item.gameName_kr = jili[item.gameId]['gameName_kr']
                                    item.gameName_id = jili[item.gameId]['gameName_id']
                                } else if (item.gameFactory == 'Pragmatic Play') {
                                    item.gameName_cn = PP[item.gameId]['gameName_cn']
                                    item.gameName_vie = PP[item.gameId]['gameName_vie']
                                    item.gameName_th = PP[item.gameId]['gameName_th']
                                    item.gameName_jp = PP[item.gameId]['gameName_jp']
                                    item.gameName_kr = PP[item.gameId]['gameName_kr']
                                    item.gameName_id = PP[item.gameId]['gameName_id']
                                } else if (item.gameFactory == 'JDB') {
                                    item.gameName_cn = jdb[item.gameId]['gameName_cn']
                                    item.gameName_vie = jdb[item.gameId]['gameName_vie']
                                    item.gameName_th = jdb[item.gameId]['gameName_th']
                                    item.gameName_jp = jdb[item.gameId]['gameName_jp']
                                    item.gameName_kr = jdb[item.gameId]['gameName_kr']
                                    item.gameName_id = jdb[item.gameId]['gameName_id']
                                }
                            } catch (e) {
                                try {  //第一次匹配失败 转为小写
                                    // console.log('--------------------')
                                    if (item.gameName == 'lucky dragons' && item.gameFactory == 'JDB') {
                                        item.path = require(`@/assets/gameImg/${item.gameName.toLowerCase() + '_jdb'}.webp`)
                                    } else if (item.gameName == "hot chilli" && item.gameFactory == 'Pragmatic Play') {
                                        item.path = require(`@/assets/gameImg/${item.gameName.toLowerCase() + '_pp'}.webp`)
                                    } else if (item.gameName == "gold rush" && item.gameFactory == 'JILI') {
                                        item.path = require(`@/assets/gameImg/${item.gameName.toLowerCase() + '_jili'}.webp`)
                                    } else
                                        item.path = require(`@/assets/gameImg/${item.gameName.toLowerCase()}.webp`)
                                    if (item.gameFactory == 'JILI') {
                                        item.gameName_cn = jili[item.gameId]['gameName_cn']
                                        item.gameName_vie = jili[item.gameId]['gameName_vie']
                                        item.gameName_th = jili[item.gameId]['gameName_th']
                                        item.gameName_jp = jili[item.gameId]['gameName_jp']
                                        item.gameName_kr = jili[item.gameId]['gameName_kr']
                                        item.gameName_id = jili[item.gameId]['gameName_id']

                                    } else if (item.gameFactory == 'Pragmatic Play') {
                                        item.gameName_cn = PP[item.gameId]['gameName_cn']
                                        item.gameName_vie = PP[item.gameId]['gameName_vie']
                                        item.gameName_th = PP[item.gameId]['gameName_th']
                                        item.gameName_jp = PP[item.gameId]['gameName_jp']
                                        item.gameName_kr = PP[item.gameId]['gameName_kr']
                                        item.gameName_id = PP[item.gameId]['gameName_id']

                                    } else if (item.gameFactory == 'JDB') {
                                        item.gameName_cn = jdb[item.gameId]['gameName_cn']
                                        item.gameName_vie = jdb[item.gameId]['gameName_vie']
                                        item.gameName_th = jdb[item.gameId]['gameName_th']
                                        item.gameName_jp = jdb[item.gameId]['gameName_jp']
                                        item.gameName_kr = jdb[item.gameId]['gameName_kr']
                                        item.gameName_id = jdb[item.gameId]['gameName_id']

                                    }
                                } catch (err) {
                                    // console.error(`无法加载图片: ${item.gameName}.webp`);
                                    // 如果图片不存在，设置默认图片
                                    item.path = require('@/assets/gameImg/default.png');
                                }
                            }
                            // }
                        });
                        let listT = 'gameListT' + type
                        let loadT = 'loading' + type
                        this[listT] = resp.data.list
                        this[loadT] = false
                    } else {
                        this.$Notice.error({
                            title: this.$t("common.tip"),
                            desc: resp.mesg
                        });
                        this.realHeight = '50rem'
                    }
                });
        },
        handleScroll() {
            const scrollTop = window.scrollY || document.documentElement.scrollTop; // 获取滚动距离
            if (scrollTop > 212) {
                this.ifFixed = true; // 标记已触发
                // 执行你的操作逻辑
                console.log("滚动大于 60px，触发操作");
            } else {
                this.ifFixed = false; // 滚动距离恢复小于 60px
            }
        },
        changeTab(tab, anchor) {
            // const anchorElement = document.getElementById(anchor);
            // if (anchorElement) {
            //     const top = anchorElement.getBoundingClientRect().top + window.scrollY - 60;
            //     window.scrollTo({
            //         top: top,
            //         behavior: 'smooth'
            //     });
            // }
            this.gameTab = tab
            if(this.gameTab === 100) this.init()
        }
    },
    computed: {
        member() {
            return this.$store.state.member
        }
    },
    destroyed() {
        // clearInterval(this.timercode)
        // this.timercode = null
        // this.startRecPacket = false  //防止点击到其他位置 离开销毁
    },
};
</script>
<style scoped lang="scss">

.banner-panel {
    width: 96%;
    height: 190px;
    overflow: hidden;
    position: relative;
    margin: 12px auto 10px;
}

.nav-rights {
    background: #0b1a24;
    height: $full-page;
    position: relative;
    // overflow: hidden;
    text-align: center;

    .box222 {
        height: 60px;
        width: 100%;
    }
}

.gap-6 {
    gap: 1.5rem;
}

.w-full {
    width: 100%;
}

.grid {
    display: grid;
}

.m-auto {
    margin: auto;
}

.gap-3 {
    gap: .75rem;
}

.flex-col {
    flex-direction: column;
}

.flex {
    display: flex;
}

.stack.padding-none.svelte-1cd1boi {
    padding: 0;
}

.wrap.normal.svelte-1y9pixq {
    max-width: 66.95px;
}

.wrap.svelte-1y9pixq {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.image-focus.svelte-1bax0ph a svg {
    --radius-base: .25rem;
    border-radius: var(--radius-base);
}

.image-focus.svelte-1bax0ph svg {
    --transition-fast: .2s;
    --focus-outline-width: .125rem;
    transition: var(--transition-fast);
    outline-offset: var(--focus-outline-width);
}

.wrap.svelte-1y9pixq > svg {
    width: 100%;
}

svg.svelte-md2ju7 {
    --white: #fff;
    color: var(--white) !important;
}

.stack.gap-small.svelte-1cd1boi {
    --space-2: .5rem;
    gap: var(--space-2);
}

.stack.y-center.svelte-1cd1boi {
    align-items: center;
}

.stack.x-stretch.svelte-1cd1boi {
    justify-content: stretch;
}

.stack.direction-vertical.svelte-1cd1boi {
    grid-auto-flow: row;
}

.stack.svelte-1cd1boi {
    display: grid;
}

.image-focus.svelte-1bax0ph img {
    --focus-outline-width: .125rem;
    outline-offset: var(--focus-outline-width);
}

.image.svelte-hhdrmg {
    width: auto;
    height: 60px;
    border-radius: .125rem;
}

.site-badges.svelte-g3yerg {
    width: 100%;
    margin: 0 auto;
    display: grid;
    --space-6: 1.5rem;
    grid-gap: var(--space-6);
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    justify-items: center;
}

svg.svelte-dmytnz.svelte-dmytnz, a.svelte-dmytnz.svelte-dmytnz {
    max-width: 120px;
    width: 100%;
}

.parent.svelte-1p5gbm2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 3vw;
}

.image-focus.svelte-1bax0ph a {
    outline: none;
}

.image-focus.svelte-1bax0ph a svg {
    --radius-base: .25rem;
    border-radius: var(--radius-base);
}

svg.svelte-dmytnz.svelte-dmytnz, a.svelte-dmytnz.svelte-dmytnz {
    max-width: 120px;
    width: 100%;
}

svg.svelte-dmytnz.svelte-dmytnz, a.svelte-dmytnz.svelte-dmytnz {
    max-width: 120px;
    width: 100%
}

.cls-1.svelte-dmytnz.svelte-dmytnz {
    fill: #4fc3cf
}

.cls-2.svelte-dmytnz.svelte-dmytnz {
    fill: #fff
}

.cls-3.svelte-dmytnz.svelte-dmytnz {
    fill: #46c6f1
}

.dark.svelte-dmytnz .cls-1.svelte-dmytnz {
    fill: #4fc3cf
}

.dark.svelte-dmytnz .cls-2.svelte-dmytnz {
    fill: #414042
}

.dark.svelte-dmytnz .cls-3.svelte-dmytnz {
    fill: #46c6f1
}

.image-focus.svelte-1bax0ph svg {
    --transition-fast: .2s;
    --focus-outline-width: .125rem;
    transition: var(--transition-fast);
    outline-offset: var(--focus-outline-width);
}

.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}


.image-focus.svelte-1bax0ph {
    display: contents;
}

.seal.svelte-h38nvw div {
    overflow: visible !important;
}

.image-focus.svelte-1bax0ph a {
    outline: none;
}

.seal.svelte-s81bj8 img {
    width: 32px !important;
    border-radius: .125rem;
}

.image-focus.svelte-1bax0ph img {
    outline-offset: var(--focus-outline-width);
}

.seal.svelte-h38nvw img {
    --space-0-5: .125rem;
    width: 32px !important;
    margin: var(--space-0-5);
    border-radius: .125rem;
}

.image-focus.svelte-1bax0ph img {
    outline-offset: var(--focus-outline-width);
}

.seals.svelte-ty29q5 > div {
    --space-8: 2rem;
    display: flex;
    justify-content: center;
    gap: var(--space-8);

}

.contact-emails.svelte-1kv3nkx {
    margin: 0 auto
}

.text-center {
    text-align: center
}

.bg-grey-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(7, 29, 42, var(--tw-bg-opacity));
    height: 1200px;
}

.border-solid {
    border-style: solid;
}

.border-t-2 {
    border-top-width: 0px;
}

.border-t-grey-500 {
    --tw-border-opacity: 1;
    border-top-color: rgba(33, 55, 67, var(--tw-border-opacity));

}


.contact-emails.svelte-1kv3nkx {
    margin: 0 auto;
}

.text-center {
    text-align: center;
}


p.variant-subtle.svelte-17v69ua {
    --grey-200: #b1bad3;
    color: var(--grey-200);
}

p.line-height-150pct.svelte-17v69ua {
    line-height: 150%;
}

p.align-center.svelte-17v69ua {
    text-align: center;
    justify-content: center;
}

p.text-size-default.svelte-17v69ua {
    font-size: var(--text-size-default);
}

p.weight-normal.svelte-17v69ua {
    font-weight: 400;
}

p.svelte-17v69ua {
    display: block;
}


em.variant-subtle.svelte-17v69ua, span.variant-subtle.svelte-17v69ua, h1.variant-subtle.svelte-17v69ua, h2.variant-subtle.svelte-17v69ua, h3.variant-subtle.svelte-17v69ua, h4.variant-subtle.svelte-17v69ua, h5.variant-subtle.svelte-17v69ua, h6.variant-subtle.svelte-17v69ua, p.variant-subtle.svelte-17v69ua {
    --grey-200: #b1bad3;
    color: var(--grey-200);
}

em.line-height-default.svelte-17v69ua, span.line-height-default.svelte-17v69ua, h1.line-height-default.svelte-17v69ua, h2.line-height-default.svelte-17v69ua, h3.line-height-default.svelte-17v69ua, h4.line-height-default.svelte-17v69ua, h5.line-height-default.svelte-17v69ua, h6.line-height-default.svelte-17v69ua, p.line-height-default.svelte-17v69ua, em.line-height-base.svelte-17v69ua, span.line-height-base.svelte-17v69ua, h1.line-height-base.svelte-17v69ua, h2.line-height-base.svelte-17v69ua, h3.line-height-base.svelte-17v69ua, h4.line-height-base.svelte-17v69ua, h5.line-height-base.svelte-17v69ua, h6.line-height-base.svelte-17v69ua, p.line-height-base.svelte-17v69ua {
    line-height: 1.5;
}

em.align-left.svelte-17v69ua, span.align-left.svelte-17v69ua, h1.align-left.svelte-17v69ua, h2.align-left.svelte-17v69ua, h3.align-left.svelte-17v69ua, h4.align-left.svelte-17v69ua, h5.align-left.svelte-17v69ua, h6.align-left.svelte-17v69ua, p.align-left.svelte-17v69ua {
    text-align: left;
    justify-content: flex-start;
}

em.text-size-default.svelte-17v69ua, span.text-size-default.svelte-17v69ua, h1.text-size-default.svelte-17v69ua, h2.text-size-default.svelte-17v69ua, h3.text-size-default.svelte-17v69ua, h4.text-size-default.svelte-17v69ua, h5.text-size-default.svelte-17v69ua, h6.text-size-default.svelte-17v69ua, p.text-size-default.svelte-17v69ua {
    --text-size-default: .875rem;
    font-size: var(--text-size-default);
}

em.weight-normal.svelte-17v69ua, span.weight-normal.svelte-17v69ua, h1.weight-normal.svelte-17v69ua, h2.weight-normal.svelte-17v69ua, h3.weight-normal.svelte-17v69ua, h4.weight-normal.svelte-17v69ua, h5.weight-normal.svelte-17v69ua, h6.weight-normal.svelte-17v69ua, p.weight-normal.svelte-17v69ua {
    font-weight: 400;
}

em.svelte-17v69ua, span.svelte-17v69ua, h1.svelte-17v69ua, h2.svelte-17v69ua, h3.svelte-17v69ua, h4.svelte-17v69ua, h5.svelte-17v69ua, h6.svelte-17v69ua, p.svelte-17v69ua {
    line-height: 1.3;
    display: inline-flex;
    align-items: center;
}

.game-tab-box {
    width: 100%;
    margin: auto;
    height: 54px;
    background-color: #0E212E;
    overflow-x: scroll;

    .tab-box {
        //width: 340%;
        height: 100%;
        display: flex;
        justify-content: start;

        .tab-item {
            //width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #fff;
            font-weight: bold;

            .item {
                background-color: #304553;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 12px;
                padding: 0 10px;
                margin: 0 5px;
                height: 38px;
                white-space: nowrap;
                font-weight: 900;
                font-size: 14px;

                .icon {
                    margin: 0 6px;
                    width: 14px;
                }
            }

            .tabActive {
                //background-color: #304553;
                background-image: linear-gradient(90deg, #548dff, #0c5eff);
            }
        }
    }
}

.ting-box {
    flex: 1;
}

.logo-wrapper.svelte-1rvpqmm img.svelte-1rvpqmm {
    height: 3.5rem;
    margin: 0 auto;
}

.logo-wrapper.svelte-1rvpqmm.svelte-1rvpqmm {
    --space-8: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: var(--space-8);
}

.top-fixed{
    position: fixed;
    z-index: 1000;
    top: 60px;
    left: 0;
}

</style>
