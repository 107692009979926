<template>

  <div class="login_form">

    <div class="headBox">
      <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">
      <span  style="font-size:14px;">
        {{$t('uc.finance.addaddress')}}
      </span>
      <span>{{  }}</span>
      <!-- <span v-if="model1 == 'USDT'" @click="changeType">{{ $t('uc.finance.cards') }}</span>
      <span v-else @click="changeType">{{ 'USDT' }}</span> -->
    </div>

      <div class="rightarea">

        <!-- <div style="margin-top: 60px; padding: 10px;" >
          <Button type="success" long @click="gotoBank" style="background-color: #f4850d;border: none;">{{ $t('uc.finance.xx93') }}</Button>
        </div> -->
        <div style="margin-left: 2%; align-items: center;color: #fff; width: 96%;margin-top: 60px;height: auto;padding: 5px 10px;background-color:#293042 ;border-radius: 10px;display: flex;justify-content: space-between;" @click="gotoBank">
        <div style="display: flex;justify-items: center;">
          <img src="../assets/logo/hhh1.png" width="20" style="margin-right: 5px;" alt="">
        {{ $t('uc.finance.xx93') }}
        </div>
        <div style="font-size: x-large;">
          {{ '+' }}
        </div>
      </div>

      <!-- <section style="margin-top:60px;" v-if="model1 == 'USDT'">

     <div class="celllall">
      <span style="margin-left:0px">
        {{$t('uc.forget.walletaddress')}}
      </span>
      <Input v-model="inputAddress" style="width:90%;" :disabled='isdisable' clearable   :placeholder="$t('uc.forget.addresstip')" />

    </div>

      <div style="margin-top:20px" >
        <span style="font-size: 14px;color: #a2a2a1;">{{$t('uc.finance.personalassets')}}</span>
      </div>
      <div>
        <Button  style="background-color:#e5b932;border-radius: 25px; height: 40px; width: 100px; font-weight: bold; border: none;margin: 10px;margin-top: 0%;">TRC20</Button>
      </div>
      <div style="margin-top:20px;">
        <Button type="success" style="width: 90%;" :disabled='isdisable'  @click="firstModel">{{$t('uc.forget.save')}}</Button>
      </div>


      <div style="width: 100%;padding: 10px; ">
      <Row>
        <Col span="12" v-for="(item, index) in usdtCards"
        :key="index"
        :index="index"
        >
            <div class="bankcard">

            <span>{{ $t('uc.finance.xx122') }} : {{ item.walletAddress }}</span>

            </div>
        </Col>
      </Row>


        </div>
      </section> -->



        <section style="margin-top:10px;">
          <!-- <Select v-model="model11" style="width:90%" @on-change="changeCountry">
        <Option v-for="item in cityList" :value="item.value" :key="item.value" >{{ item.label }}</Option>
    </Select>

    <Input v-model="value2" style="width:90%;margin-top: 10px;"  :placeholder="$t('common.realNameInput')" />

    <Select v-model="model111" style="width:90%; margin-top: 10px;">
        <Option v-for="item in bankList" :value="item.bankCode" :key="item.value" :disabled="closure(item)">{{ item.bankName }}</Option>
    </Select>

    <Input v-model="value3" style="width:90%; margin-top: 10px;" :placeholder="$t('uc.forget.addresstip222')" />
    <Button type="success" style="width: 90%;;margin-top: 10px;" @click="submitFirst">{{ $t('uc.forget.save') }}</Button> -->

      <!-- <Row>
        <Col span="12" v-for="(item, index) in bankCards"
        :key="index"
        :index="index"
        >
            <div class="bankcard">
            <span>{{ $t('uc.finance.xx88') }} : {{ item.walletName }}</span>
            <span>{{ $t('uc.finance.xx89') }} : {{ item.walletAddress }}</span>
            <span>{{ $t('uc.finance.xx68') }} : {{ item.ccy }}</span>
            <span>{{ $t('uc.finance.xx91') }} : {{ item.bankName }}</span>
            </div>
        </Col>
      </Row> -->
      <!-- {
    "id": "52",
    "userId": "155",
    "userNo": "245750",
    "addressChannel": 2,
    "walletName": "hggj ",
    "walletAddress": "123123",
    "createTime": "2023-10-03 17:44:12",
    "updateTime": "2023-10-03 17:44:12",
    "ccy": "VND",
    "ccySymbol": null,
    "bankName": "TECHCOMBANK",
    "bankAddress": null
} -->
      <!-- <List  :split="false">
            <ListItem v-for="item in bankCards"  :key="item.id" >
              <div  class="bankcard">
                <div style="display: flex;justify-content: space-between;">
                <span>{{ item.bankName }}</span>
                <span>{{ item.ccy }}</span>
                </div>
                <div style="display: flex;justify-content: flex-start;">
                  <span>{{ item.walletName }}</span>
                </div>
                <div style="display: flex;justify-content: flex-start; margin-top: 10px;">
                  <span>{{ item.walletAddress }}</span>
                </div>

              </div>

            </ListItem>

      </List> -->

      <Scroll style="width: 95%;" height="600">
        <div dis-hover v-for="(item, index) in bankCards" :key="index" style="margin-bottom: 12px" >

          <div class="bankcard" >
            <div style="display: flex;width: 50%;align-items: center;">
              <img :src="item.img" width="30" height="30" alt="">
            <div style="display: flex;flex-direction: column;margin-left: 5px;">
              <span> {{ item.bankName  }}</span>
              <span>{{ item.walletAddress }}</span>
            </div>
            </div>
            <span>{{ item.walletName }}</span>
            </div>


          </div>
    </Scroll>



      </section>


      <!-- <div style="margin-top: 20px;">
          <span style="font-size: 12px;color:#f60;">{{$t('uc.finance.notice12')}}</span>

        </div> -->

      </div>

      <Modal
        v-model="modal2"
        :title="$t('common.tip')"
        @on-ok="submitSecond"
        @on-cancel="cancel">
        <p>{{$t('uc.finance.notice12')}}</p>

    </Modal>

     </div>

</template>

<script>

export default {
  name: 'Cart',
  components: {

  },
  data(){
    return{
            model1:'USDT',
            model11:'',
            model111: '',
            bankList: [],
            bankCards:[],
            usdtCards:[],
            modal2: false,
 value2: '',
 value3: '',
 listHeight: '400px',
 isdisable:false,
 inputAddress: "", //用户输入的地址
 cityList: [
                    {
                        value: 'VND',
                        label: 'VIENAM'
                    },
                    {
                        value: 'USD',
                        label: 'CAMBODIA'
                    },
                    {
                        value: 'CNY',
                        label: 'CHINA'
                    },]
    }
  },
  computed:{


    closure () {
   return function (data) {
        /** do something */
        return data.bankName == 'KHQR' || data.bankName == 'QR Code'

    }
 }
  },
created() {

    // this.getList();

    let xx =  this.$route.params.bankCards
    if (xx == '0') {
      console.log('');
    }else if (xx == '1') {
      this.model1 = 'bankCards'
    }
    this.getBankCards();
    this.getMemberInfo();
    // this.getAddress();
  },
  methods: {
    cancel(){
      this.modal2 = false
    },

    submitFirst(){
      this.modal2 = true
    },

    submitSecond(){
      this.addBankCard()
    },

    changeType(){
if (this.model1 == 'USDT') {
  this.model1 = 'bankCards'
}else if (this.model1 == 'bankCards') {
  this.model1 = 'USDT'
}

    },
    changeCountry(data){
      if (data == 'VND') {
        console.log();

      }else if (data == 'USD-cam') {
        data = 'USD'
      }else if (data == 'CNY') {
        console.log();
      }

      this.getBankList(data)
    },

    gotoBank(){
      this.$router.push('/cardMaker')
    },

    getBankList(data){
      let that = this
      let param = {
        'transCurrency':data,
        'language':this.$i18n.locale
      }

      this.$http.get(this.host + this.api.uc.cambodiaWithdrawBanks, {params: param}).then(response => {
          var resp = response.body;
          this.loading = false;

          if (resp.code == "SUCCESS") {
            that.bankList = resp.banks

          } else {
            that.$Message.error(resp.message);
          }
        });
    },
    getMemberInfo() {
      //获取个人安全信息
      var self = this;

      this.$http
        .post(self.host + this.api.uc.memberInfo)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
         let xx = resp.data

          // if (xx.bindStatus == '0') {
          //   self.isdisable = false
          // }else{
          //   self.inputAddress = xx.bindAddress
          // }

          // self.value2 = xx.realName

console.log(''+xx);

          } else {
            self.$Message.error(resp.mesg);
          }
        });
    },


firstModel(){
  //  this.modal10 = true;
  this.addAddress();
},


addBankCard(){
  let self = this
  let param = {}

  if (this.value3 == '' || this.model111 == '' || this.value2 == '') {
    this.$Message.error(this.$t('uc.finance.xx100'));
    return
  }

param.walletAddress = this.value3
param.bankName = this.model111
param.walletName = this.value2
param.addressChannel = 2
param.ccy = this.model11
param.userNo = this.$store.getters.member.userNo
param.language = this.$i18n.locale || 'en-US'

this.$http
        .post(self.host + this.api.uc.identification, param)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {

            self.$Message.success(resp.mesg);
            self.getBankCards()
            self.clearVars()
            self.modal2 = false
            // self.isdisable = true
          } else {
            self.$Message.error(resp.mesg);
            self.modal2 = false
            // this.$Message.error(this.$t('common.logintip'));
          }
        });

},
clearVars(){
  this.model11 = ''
  this.value2 = ''
  this.model111 = ''
  this.value3 = ''


},

getBankCards(){
  let self = this
  this.$http
        .post(self.host + this.api.uc.wallet)
        .then(response => {
          let resp = response.body
          if (response.status == 200) {
            console.log('' + resp.data);
           self.bankCards = []
           self.usdtCards = []
              resp.forEach(element => {
                if (element.ccy == 'USDT') {
                  self.usdtCards.push(element)
                }else {
                  // if (element.bankName == 'EMNYKHPPXXX') {
                  //   element.img = require('@/assets/logo/zz6.png')
                  // }
                  // else if (element.bankName == 'WINGKHPPXXX') {
                  //   element.img = require('@/assets/logo/wing11.png')
                  // }
                  // else if (element.bankName == 'ABAAKHPPXXX') {
                  //   element.img = require('@/assets/logo/zz7.png')
                  // }
                  // else if (element.bankName == 'ACLBKHPPXXX') {
                  //   element.img = require('@/assets/logo/zz2.png')

                  // }else if (element.bankName == 'VIETCOMBANK') {
                  //   element.img = require('@/assets/logo/zz1.png')
                  // }
                  // else if (element.bankName == 'MBBANK') {
                  //   element.img = require('@/assets/logo/zz4.png')
                  // } else if (element.bankName == 'TECHCOMBANK') {
                  //   element.img = require('@/assets/logo/zz3.png')
                  // }
                  // self.bankCards.push(element)
                }
              });

          }

        })
},
    addAddress() {
  //  /user/addWithdrawalAddress    入参：Integer addressChannel（0：区块链，1：汇旺，2：银行卡），
  //   String walletName(账号姓名)（选择区块链隐藏此选项），String walletAddress(提现账号)，String ccy（币种）
      var self = this;
     var params = {
          };

          params['addressChannel'] = 0
          params['walletAddress'] = this.inputAddress
          params['ccy'] = 'USDT'
          params['ccySymbol'] = 'TRC20'

      this.$http
        .post(self.host + this.api.uc.identification, params)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {

            self.$Message.success(resp.mesg);
            self.getBankCards()
            // self.isdisable = true
          } else {
            self.$Message.error(resp.mesg);
            // this.$Message.error(this.$t('common.logintip'));
          }
        });
    },

goback() {
      //获取个人安全信息
      this.$router.back(-1)

    },
  }
}
</script>

<style lang="scss" scoped>

.login_form{
    background-color: $color-background;
    height: $full-page;
  position: relative;
  overflow: hidden;
  text-align: center;
}
.box{
  position: fixed;
  top: 0;
  display: flex;
  height: 50px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  color: $color-text-tag;
  z-index: 200;
   width: 100%;
}

  .celllall{
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #a2a2a1;
  font-size: 18px;
  margin-top: 20px;
  margin-left: 20px;
}



.bankcard{
  display:flex;
  color: #fff;
  justify-content: space-between;
  background-image: url(../assets/logo/bb2.png);
  background-position: center;
  align-items: center;

  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 10px 20px;
  font-size:small;
  width: 100%;
}


  //  .rightarea {
  // width: 100%;
  // height: 400px;
  // background: url(../assets/netmage/hhss.png);
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: 95% 100%;
  // margin-top: 100px;
  // padding: 20px;

  //   }

//     .rightarea /deep/ .ivu-select-selection {

//     background-color: #e5b932;
//     border-radius: 15px;
//     color: #000;
//     font-weight: bolder;
// }

// .bill_box{
//   margin-top: 10%;
// }

// .bill_box /deep/ .ivu-select-selection {

//     background-color: #e5b932;
//     border-radius: 15px;
//     color: #000;
//     font-weight: bolder;
// }

/deep/ .ivu-list-item{
  padding: 0px;
}

</style>


<!-- <style scoped lang="less">

// class="ivu-input ivu-input-default"
  /deep/ .ivu-input{
  background: transparent;
  border-radius: 25px;

  color: #898989
}



</style> -->

